import React, { useContext } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import closeIcon from "assets/images/close.svg";
import { FormInputUncontrolled } from "components/FormInput/Uncontrolled";
import { noop } from "utilities";
import Button from "react-bootstrap/Button";
import { Spacer } from "components/Spacer";
import { useForm } from "react-hook-form";
import { resetPasswordConfirm } from "api/rest";
import { useUrlQuery } from "components/QueryProvider";
import { shopContext } from "App";
import styles from "./PasswordReset.module.scss";

const resolver = data => {
  const errors = {};
  if (data.password.length === 0) {
    errors.password = "Please provide the new password";
  } else if (data.password.length <= 6) {
    errors.password = "The password is too short";
  }
  if (data.newPassword !== data.password) {
    errors.newPassword = "Provided passwords should be the same";
  }
  const hasError = Object.keys(errors).length > 0;
  return {
    values: hasError ? {} : data,
    errors,
  };
};

export const PasswordReset = ({ close }) => {
  const { register, handleSubmit, errors, formState, setError } = useForm({
    resolver,
    defaultValues: { password: "", newPassword: "" },
    mode: "onBlur",
  });
  const { query } = useUrlQuery();
  const { login } = useContext(shopContext);
  const onSubmit = async values => {
    const [tokens, error] = await resetPasswordConfirm({
      newPassword: values.password,
      code: query.code,
      uid: query.uid,
      token: query.token,
    });
    if (error) {
      setError("general", {
        message:
          error.message ||
          error.nonFieldErrors ||
          "An error occurred. Probably you have used the same link again. Please reset password one more time.",
      });
    } else {
      login({ tokens });
      close();
    }
  };
  return (
    <DialogOverlay isOpen={true} onDismiss={noop} style={{ zIndex: 3 }}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <DialogContent aria-label="Password reset" className={styles.dialogContent}>
            <div className={styles.content} style={{ backgroundColor: "#fff" }}>
              <div className={styles.header}>
                <div className={styles.headline}>Password reset</div>
                <button className={styles.closeModalBtn} type="button" onClick={close}>
                  <img src={closeIcon} alt="" />
                </button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInputUncontrolled.Error text={errors.general?.message} />
                {errors.general && <Spacer space={30} />}
                <FormInputUncontrolled
                  label="New password"
                  ref={register}
                  name="password"
                  type="password"
                />
                <FormInputUncontrolled.Error text={errors.password} />

                <Spacer space={30} />
                <FormInputUncontrolled
                  label="Confirm password"
                  ref={register}
                  name="newPassword"
                  type="password"
                />
                <FormInputUncontrolled.Error text={errors.newPassword} />
                <Spacer space={30} />
                <Button
                  align="center"
                  padding="large"
                  className="w-100"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  RESET PASSWORD
                </Button>
              </form>
            </div>
          </DialogContent>
        </div>
      </div>
    </DialogOverlay>
  );
};
