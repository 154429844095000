import React, { useContext, useEffect, useState } from "react";
import renderHtml from "htmr";
import Select from "components/Select";
import cx from "classnames";
import { shopContext } from "App";
import { useUrlQuery } from "components/QueryProvider";
import { getContrastedColor } from "utilities";
import { tagColors } from "constants/tagColors";
import { QuantityCounter } from "components/QuantityCounter";
import { Link } from "react-router-dom";
import { Spacer } from "components/Spacer";
// import Button from "react-bootstrap/Button";
import { CartAddBtn } from "components/CartAddBtn/CartAddBtn";
import styles from "./ProductDetails.module.scss";

const defaultOptions = {
  interface: "4 pin",
};

function variantForOptions(product, options) {
  const variant = product.variants.find(variant =>
    variant.selectedOptions.every(option => {
      return options[option.name.toLowerCase()] === option.value;
    }),
  );
  if (!variant) return product.variants[0];
  return variant;
}

function initializeOptions(product, query) {
  return product.options.reduce((acc, opt) => {
    const name = opt.name.toLowerCase();
    acc[name] = query[name] ?? defaultOptions[name] ?? "";
    return acc;
  }, {});
}

export const ProductDetails = ({ product }) => {
  const { query } = useUrlQuery();
  const [quantity, setQuantity] = useState(1);
  const increment = () => setQuantity(q => q + 1);
  const decrement = () => setQuantity(q => q - 1);
  const change = val => setQuantity(Number(val));
  const { addVariantToCart, shop } = useContext(shopContext);
  const [options, setOptions] = useState(() => initializeOptions(product, query));
  const productId = product?.id;

  /**
   * Reset quantity on product change
   */
  useEffect(() => {
    setQuantity(1);
  }, [productId]);

  const setOption = opt => {
    setOptions(opts => ({
      ...opts,
      ...opt,
    }));
  };

  const selectedVariant = variantForOptions(product, options);

  const handleAddToCart = async helpers => {
    async function handleAdd(id) {
      helpers.setStatus("");
      helpers.startFetching();
      const { status } = await addVariantToCart(id, quantity);
      helpers.stopFetching();
      helpers.setStatus(status);
    }
    if (selectedVariant) {
      handleAdd(selectedVariant.id);
    } else if (product.productType === "Devkit") {
      handleAdd(product.variants[0].id, quantity);
    }
  };
  return (
    <div className={styles.detailsSection}>
      <div className={styles.tags}>
        {product.tags.map(tag => {
          const color = tagColors[tag] ?? "#5048e2";
          return (
            <div
              key={tag}
              className={styles.tag}
              style={{
                backgroundColor: color,
                color: getContrastedColor(color),
              }}
            >
              {tag}
            </div>
          );
        })}
      </div>
      <h2>{product.title}</h2>
      <h3 className={styles.serialNumber}>
        {product.metafields.find(mf => mf.key === "serialNumber")?.value ?? ""}
      </h3>
      {product.productType === "Shield" && (
        <span>
          Size:{" "}
          <strong>
            {product.width / 2} x {product.height / 2}
          </strong>
        </span>
      )}
      <Spacer space={10} />
      <div className={styles.price}>{product.price}</div>
      <div className={styles.selectWrapper}>
        {product.options
          .filter(option => option.name !== "Title") // title is a weird default option which exists event if product has no variants, we don't want to display it
          .map(option => (
            <Select
              key={option.name}
              label={option.name}
              selectedLabel={options[option.name.toLowerCase()]}
              placeholder="Choose variant"
              classNames={{ formGroup: "d-flex align-items-center", label: "mb-0 mr-3" }}
            >
              {option.values.map(value => {
                return (
                  <Select.Option
                    key={`${option.name}-${value}`}
                    onSelect={() => setOption({ [option.name.toLowerCase()]: value })}
                  >
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          ))}
      </div>
      <Spacer space={10} orientation="vertical" />
      <div className="d-flex">
        <span className={styles.price}>
          {shop.currencyCode} {selectedVariant?.priceV2.amount}
        </span>
        <Spacer space={20} orientation="horizontal" />
        <QuantityCounter
          increment={increment}
          decrement={decrement}
          value={quantity}
          change={change}
        />
      </div>
      <Spacer space={26} orientation="vertical" />
      <CartAddBtn addToCart={handleAddToCart} />
      <Spacer space={26} orientation="vertical" />
      <div className={cx(styles.description, "mb-2")}>
        {renderHtml("<div>" + product.descriptionHtml + "</div>")}
      </div>
      {/* <Button variant="outline-primary" type="button" className={styles.secondaryBtn}>
        <span className={cx(styles.btnText, "pt-1")}>SHOW LIBRARY</span>
      </Button> */}
      <Spacer space={28} orientation="vertical" />
      <p className={cx(styles.textGray, "hind font-weight-light")}>
        Getting started with Electron Square? See{" "}
        <Link className="link-green ml-1" to="/how-it-works">
          How it works
        </Link>
        .
      </p>
    </div>
  );
};
