import { useEffect, useRef, useContext } from "react";
import { postLoginSocial } from "api/rest";
import { useUrlQuery } from "components/QueryProvider";
import { useToastr } from "components/Toastr/useToastr";
import { shopContext } from "App";
import { useRouteMatch } from "react-router";

export const SocialLogin = () => {
  const { query } = useUrlQuery();
  const initialMount = useRef(true);
  const toastr = useToastr();
  const { login } = useContext(shopContext);
  const match = useRouteMatch();
  const { provider } = match.params;

  useEffect(() => {
    async function authorize() {
      const [tokens, , { status }] = await postLoginSocial({
        code: query.code,
        provider,
        redirect_uri: `https://shop.ide.electronsquare.com/social-login/${provider}`,
      });
      if (status !== 200) {
        toastr.open({ type: "failure", text: "Unable to login with chosen service" });
      } else {
        login({ tokens });
      }
    }
    if (initialMount.current) {
      authorize();
    }
  }, [query.code, query.state, login, toastr, provider]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  return null;
};
