import React, { useEffect, useRef, useState } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import closeIcon from "assets/images/close.svg";
import { noop } from "utilities";
import { postUserActivate } from "api/rest";
import { useUrlQuery } from "components/QueryProvider";
import checkIcon from "assets/images/icon_check-green-56px.svg";
import styles from "./UserActivation.module.scss";

export const UserActivation = ({ close }) => {
  const initialMount = useRef(true);
  const { query } = useUrlQuery();

  const [inProgress, setInProgress] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function submit() {
      const [, error] = await postUserActivate({
        code: query.code,
        uid: query.uid,
        token: query.token,
      });
      setInProgress(false);
      if (error) {
        setError(error.message || "Account has not been activated.");
      }
    }
    if (initialMount.current) {
      submit();
    }
  }, [query]);

  const success = !inProgress && !error;

  return (
    <DialogOverlay isOpen={true} onDismiss={noop} style={{ zIndex: 3, padding: "10vh 0" }}>
      <DialogContent aria-label="Password reset" className={styles.dialogContent}>
        <div className={styles.content} style={{ backgroundColor: "#fff" }}>
          <div className={styles.header}>
            <div className={styles.headline}>
              {success ? (
                <span className="d-flex align-items-end">
                  <img src={checkIcon} alt="" />
                  Done!
                </span>
              ) : (
                <span className="d-flex align-items-end">
                  Activating your account{!error && "..."}
                </span>
              )}
            </div>
            <button className={styles.closeModalBtn} type="button" onClick={close}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
          <span className="error-text">{error}</span>
          <span style={{ opacity: success ? 1 : 0, transition: "opacity 200ms" }}>
            Your account is now <span className={styles.activeText}>active</span>. Enjoy E²!
          </span>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};
