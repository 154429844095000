import React from "react";
import { QueryProvider } from "components/QueryProvider";
import { ApolloProvider } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastrController } from "components/Toastr/ToastrController";
import App from "./App";

export const AppProviderWrapper = ({ client, apolloClient }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <QueryProvider>
          <ToastrController>
            <App client={client} apolloClient={apolloClient} />
          </ToastrController>
        </QueryProvider>
      </Router>
    </ApolloProvider>
  );
};
AppProviderWrapper.propTypes = {
  client: PropTypes.shape().isRequired,
  apolloClient: PropTypes.shape().isRequired,
};
