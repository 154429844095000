import React, { createContext, useMemo, memo, useContext, forwardRef } from "react";
import cuid from "cuid";
import cx from "classnames";
import styles from "./Radio.module.scss";

interface Props {
  className?: string;
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  label?: string;
  name: string;
}

const context = createContext({ onChange: () => {}, disabled: false, name: "" });

export const RadioGroup = ({ onChange, disabled, name, children, value }) => {
  const contextValue = useMemo(() => ({ onChange, disabled, name, value }), [
    disabled,
    name,
    onChange,
    value,
  ]);
  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const Radio = memo(({ className, label, value }: Props) => {
  const id = React.useMemo(() => cuid(), []);
  const { onChange, name, disabled, value: selectedValue } = useContext(context);

  const checked = (() => {
    const getValue = toCheck => (typeof toCheck === "object" ? toCheck.id : toCheck);
    return getValue(value) === getValue(selectedValue);
  })();

  return (
    <div className={cx(styles.container, className ?? "")}>
      <label
        htmlFor={id}
        className={cx({
          [styles.checkMark]: true,
          [styles.checkMarkActive]: checked,
        })}
      >
        <input
          id={id}
          name={name}
          onChange={() => onChange(value)}
          type="radio"
          className={styles.input}
          value={value}
          checked={checked}
          disabled={disabled}
        />
      </label>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
});

export const FormRadio = memo(
  forwardRef(({ className, label, value, disabled, name }: Props, ref) => {
    const id = React.useMemo(() => cuid(), []);
    return (
      <div className={cx(styles.container, className ?? "")}>
        <input
          ref={ref}
          id={id}
          name={name}
          type="radio"
          className={styles.input}
          value={value}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          className={cx({
            [styles.checkMark]: true,
          })}
        />
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    );
  }),
);
