import React from "react";
import PT from "prop-types";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import { tagColors } from "constants/tagColors";
import fourPinsImg from "assets/images/icon_4pin_19px.svg";
import twentyOnePinsImg from "assets/images/icon_21pin_19px.svg";
import { CartAddBtn } from "components/CartAddBtn/CartAddBtn";
import cx from "classnames";
import styles from "./ModuleTile.module.scss";

function trimSpace(str) {
  if (!str) return "";
  return str.replace(/ /g, "");
}

const Tile = ({ mod, addVariantToCart, shop, tags, selectedVariant = "4 pin", style = {} }) => {
  const { title } = mod;
  const history = useHistory();

  const img = { "4pin": mod.images[0]?.src, "21pin": mod.images?.[2]?.src }[
    trimSpace(selectedVariant)
  ];

  const navigate = () => {
    if (mod.productType === "Devkit") {
      history.push({
        pathname: "/devkit/" + mod.handle,
      });
    } else {
      history.push({
        pathname: "/products/" + mod.handle,
        search: `?interface=${selectedVariant}`,
      });
    }
  };

  async function handleAddVariantToCart(variantId, variantQuantity, helpers) {
    helpers.setStatus("");
    helpers.startFetching();
    const { status } = await addVariantToCart(variantId, variantQuantity);
    helpers.setStatus(status);
    helpers.stopFetching();
  }
  const variant =
    mod.productType === "Devkit"
      ? mod.variants[0]
      : mod.variants.find(variant => trimSpace(variant.title) === trimSpace(selectedVariant));
  const variantQuantity = 1;

  const pinImg = { "4pin": fourPinsImg, "21pin": twentyOnePinsImg }[trimSpace(selectedVariant)];
  return (
    <div
      className={`${styles.tileXs} ${styles.tileSm} ${styles.tileMd} ${styles.tileLg} ${styles.tile}`}
      style={style}
    >
      <div className={styles.labels}>
        {tags?.map(tag => {
          const color = tagColors[tag] ?? "#5048e2";
          return (
            <div
              key={tag}
              className={styles.label}
              style={{
                backgroundColor: color,
              }}
            >
              {tag}
            </div>
          );
        })}
      </div>
      <div className={`${styles.imageBox} w-100 d-flex justify-content-center`}>
        <button
          className="btn w-100 h-100 d-flex justify-content-center align-items-center p-0"
          type="button"
          onClick={navigate}
        >
          <img
            src={mod.productType === "Devkit" ? mod.images[0]?.src : img}
            alt=""
            className={styles.image}
          />
        </button>
      </div>
      <div
        className="d-flex flex-column flex-grow-1 justify-content-between"
        style={{ paddingRight: 15, paddingBottom: 15, paddingLeft: 15 }}
      >
        <div className={styles.contentStretcher}>
          <div>
            <div className={styles.subtitle}>{mod.productType}</div>
            <button className="p-0 pt-2 btn w-100 text-left" type="button" onClick={navigate}>
              <div className={styles.title}>{title}</div>
            </button>
          </div>
          <div>
            <span className={styles.greyText}>
              {mod.metafields.find(mf => mf.key === "serialNumber")?.value ?? ""}
            </span>

            {variant && (
              <div className={styles.price}>
                {shop.currencyCode} {variant.priceV2.amount}
              </div>
            )}
            {
              <div
                className={cx(styles.hidden, {
                  [styles.visible]:
                    mod.productType === "Shield" || mod.options.some(el => el.name === "Interface"),
                })}
              >
                <div className={styles.typeLabel}>Interface:</div>
                <div
                  className={`${styles.typeWrapper} d-flex align-items-center justify-content-between`}
                >
                  <span className={styles.type}>
                    {{ "4pin": "4 pins", "21pin": "21 pins" }[trimSpace(selectedVariant)]}
                  </span>
                  <img src={pinImg} alt="" />
                </div>
              </div>
            }
          </div>
        </div>
        <Form>
          {mod.productType === "Shield" ? (
            <CartAddBtn
              variant="outline-primary"
              addToCart={helpers => handleAddVariantToCart(variant.id, variantQuantity, helpers)}
              disabled={!variant}
              overwrites={{ button: { className: styles.cartBtn } }}
            />
          ) : (
            <CartAddBtn
              variant="outline-primary"
              addToCart={helpers =>
                handleAddVariantToCart(mod.variants[0].id, variantQuantity, helpers)
              }
              disabled={!mod.variants[0]}
              overwrites={{ button: { className: styles.cartBtn } }}
            />
          )}
        </Form>
      </div>
    </div>
  );
};

Tile.propTypes = {
  tags: PT.arrayOf(PT.string),
  mod: PT.shape({
    title: PT.string.isRequired,
    handle: PT.string.isRequired,
    subtitle: PT.string,
    options: PT.arrayOf(PT.shape()).isRequired,
    variants: PT.arrayOf(PT.shape({ id: PT.string, price: PT.string })).isRequired,
    images: PT.arrayOf(PT.shape({ src: PT.string })).isRequired,
    id: PT.string.isRequired,
  }).isRequired,
  addVariantToCart: PT.func.isRequired,
  selectedVariant: PT.oneOf(["4 pin", "21 pin"]),
  shop: PT.shape({ currencyCode: PT.string }).isRequired,
};

const ModuleTilePlaceholder = ({ style }) => {
  return (
    <div
      className={`${styles.tileXs} ${styles.tileSm} ${styles.tileMd} ${styles.tileLg} ${styles.tile}`}
      style={style}
    />
  );
};
Tile.Placeholder = ModuleTilePlaceholder;

export default Tile;
