import React from "react";
import cx from "classnames";
import infoImg from "assets/images/toast-icon_INFO_24px.svg";
import succeedImg from "assets/images/toast-icon_SUCCESS_24px.svg";
import warningImg from "assets/images/toast-icon_WARNING_24px.svg";
import failureImg from "assets/images/toast-icon_ERROR_24px.svg";
import closeIcon from "assets/images/close.svg";
import { Spacer } from "components/Spacer";
import styles from "./Toastr.module.scss";

export const Toastr = ({
  type,
  text,
  lazyUnsubscribe,
  clearHideTimeout,
  close,
  className = "",
}) => {
  const variant = {
    success: { style: "", img: succeedImg },
    info: { style: "", img: infoImg },
    failure: { style: styles.failure, img: failureImg },
    warning: { style: "", img: warningImg },
  }[type];
  return (
    <div
      className={cx(styles.snackbar, styles.toast, styles.snackbarOpened, variant.style, className)}
      onMouseEnter={clearHideTimeout}
      onMouseLeave={lazyUnsubscribe}
    >
      <div className={styles.eventIcon}>
        <img src={variant.img} alt="" />
      </div>
      <Spacer space={10} orientation="horizontal" />
      <span className={styles.snackbarContent}>
        <span>{text}</span>
      </span>
      <button className={cx(styles.closeBtn, "btn p-0")} type="button" onClick={close}>
        <img src={closeIcon} alt="" />
      </button>
    </div>
  );
};
