import React, { createContext, memo, useState, useCallback, useMemo, useRef } from "react";
import cuid from "cuid";
import { Toastr } from "./Toastr";
import styles from "./Toastr.module.scss";

export const context = createContext({
  open: () => {},
});

export const ToastrController = memo(({ children }) => {
  const [subscriptions, setSubscriptions] = useState({});
  const timeouts = useRef({});

  const unsubscribe = useCallback(
    id =>
      setSubscriptions(s => {
        const newState = { ...s };
        delete newState[id];
        return newState;
      }),
    [],
  );

  const subscribe = useCallback(
    subscription => {
      const id = cuid();
      timeouts.current[id] = setTimeout(() => {
        unsubscribe(id);
      }, 10000);
      setSubscriptions(s => {
        return {
          ...s,
          [id]: { ...subscription, id },
        };
      });
    },
    [unsubscribe],
  );

  const clearHideTimeout = useCallback(id => {
    clearTimeout(timeouts.current[id]);
  }, []);

  const lazyUnsubscribe = useCallback(
    id => {
      clearTimeout(timeouts.current[id]);
      timeouts.current[id] = setTimeout(() => {
        unsubscribe(id);
      }, 2000);
    },
    [unsubscribe],
  );

  const close = useCallback(
    id => {
      clearTimeout(timeouts.current[id]);
      unsubscribe(id);
    },
    [unsubscribe],
  );

  const contextValue = useMemo(() => ({ open: subscribe }), [subscribe]);
  return (
    <>
      <context.Provider value={contextValue}>{children}</context.Provider>
      <div className={styles.container}>
        {Object.values(subscriptions).map(subscription => (
          <Toastr
            className="mb-2"
            key={subscription.id}
            type={subscription.type}
            title={subscription.title}
            text={subscription.text}
            clearHideTimeout={() => clearHideTimeout(subscription.id)}
            lazyUnsubscribe={() => lazyUnsubscribe(subscription.id)}
            close={() => close(subscription.id)}
          />
        ))}
      </div>
    </>
  );
});
