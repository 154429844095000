import React from "react";
import PT from "prop-types";
import cx from "classnames";

import { tagColors } from "constants/tagColors";
import styles from "./Tag.module.scss";

export const Tag = ({ name, style = {}, active, toggle }) => {
  const activeStyles = active
    ? {
        backgroundColor: tagColors[name],
        border: `2px solid ${tagColors[name]}`,
      }
    : {
        color: tagColors[name] ?? "",
        border: `2px solid ${tagColors[name]}`,
      };
  return (
    <button
      type="button"
      onClick={() => toggle(name)}
      className={cx(styles.tag, { [styles.active]: active })}
      style={{
        ...activeStyles,
        ...style,
      }}
    >
      {name}
    </button>
  );
};
Tag.propTypes = {
  name: PT.string.isRequired,
  style: PT.shape(),
  active: PT.bool.isRequired,
  toggle: PT.func.isRequired,
};
