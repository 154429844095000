import React from "react";
import Button from "react-bootstrap/Button";
import cx from "classnames";
import styles from "./ProductItem.module.scss";

export const ProductItem = ({ img, title, subtitle, onClick }) => {
  return (
    <Button variant="light" className={cx("d-flex mb-2", styles.btn)} onClick={onClick}>
      <div className={styles.listItem}>
        <div className={styles.listItemImg}>
          <img src={img} alt="" />
        </div>
        <div className="w-100">
          <div className={styles.contentRow}>
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.contentRow} style={{ display: subtitle ? "block" : "none" }}>
            <div className="d-flex align-items-start">
              <div className={styles.subtitle}>{subtitle}</div>
            </div>
          </div>
        </div>
      </div>
    </Button>
  );
};
