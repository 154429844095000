import React, { memo } from "react";
import { Modal } from "components/Modal/Modal";
import Button from "react-bootstrap/Button";
import { useTermsAndConditions } from "api/restApiHooks";
import renderHtml from "htmr";
import { Spacer } from "components/Spacer";

export const TermsAndConditionsModal = memo(
  ({ isRegulationsModalOpen, setRegulationsModalOpen, setValue }) => {
    const [termsAndConditions, meta] = useTermsAndConditions(undefined, undefined, {
      skip: !isRegulationsModalOpen,
    });
    return (
      <Modal
        isOpen={isRegulationsModalOpen}
        close={() => setRegulationsModalOpen(false)}
        size={{
          all: { width: "50vw" },
          md: { width: "70vw" },
          sm: { width: "calc(100vw - 20px)" },
        }}
      >
        <Modal.Body title="Terms and conditions">
          {meta.error && (
            <div className="error-text">
              An error occurred. Cannot show the terms and conditions.
            </div>
          )}
          {meta.inProgress && !termsAndConditions && "Loading..."}
          {termsAndConditions && renderHtml("<div>" + termsAndConditions.body + "</div>")}
          <Spacer space={20} />
          <div className="d-flex">
            <Button variant="secondary" onClick={() => setRegulationsModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setValue("hasAcceptedRegulations", true);
                setRegulationsModalOpen(false);
              }}
            >
              Accept
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  },
);
