import React, { useState } from "react";
import { Spacer } from "components/Spacer";
import Button from "react-bootstrap/Button";

export const ConfirmModalBody = ({ close, del }) => {
  const [deleting, setDeleting] = useState(false);
  async function handleSubmit() {
    setDeleting(true);
    await del();
    close();
  }
  return (
    <div>
      <div style={{ color: "rgba(56, 56, 56, 0.54)" }}>
        Are you sure you want to delete this project?
        <div>{deleting ? "Please wait. It may take a while." : "\u00A0"}</div>
      </div>
      <Spacer space={20} />
      <div className="text-right">
        <Button variant="primary" disabled={deleting} onClick={handleSubmit}>
          Delete
        </Button>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
      </div>
    </div>
  );
};
