import React from "react";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import exampleImg from "assets/images/e21.png";
import arrowImg from "assets/images/arrowBlue.png";
import styles from "./DevKitPage.module.scss";
//import { Gallery } from "components/Gallery";
import avatarImg from "assets/images/e21.png";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Link } from "react-router-dom";
import arrowGreenImg from "assets/images/arrowGreen.svg";
import eyeImg from "assets/images/eye_white.svg";
import cx from "classnames";
import cartImg from "assets/images/icon_19px_shopping-cart_white.svg";
import cartBlueImg from "assets/images/icon_19px_shopping-cart_blue.svg";
import ex1Img from "assets/skins/modul_buzzer.png";

const DevKitPage = () => {
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <Jumbotron className={styles.jumbotron}>
        <Container>
          <Row className={cx(styles.jumbotronContent, "pt-5 pt-lg-2")}>
            <div className="col-lg-6 pt-2">
              <div className="px-3">
                <div>
                  <Link to="/" className={`${styles.linkGreen} d-flex align-items-start mb-2`}>
                    <img src={arrowGreenImg} alt="" />
                    <span className="ml-2">Back</span>
                  </Link>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex align-items-center">
                      <div className={styles.authorAvatar}>
                        <img src={avatarImg} alt="" />
                      </div>
                      <div>Jane Doe</div>
                    </div>
                    <h1 className={styles.projectTitle}>Baby monitor</h1>
                    <div className="d-flex align-items-center my-3">
                      <div className={cx("d-flex flex-nowrap mr-3", styles.pins4)}>
                        <span>4 pins</span>
                      </div>
                      <div className={cx("d-flex flex-nowrap", styles.pinsVersion, styles.pins21)}>
                        <span>21 pins</span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-2">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Recusandae minima
                        blanditiis consectetur, unde est architecto quas vitae odit quos, illo
                        doloremque? Sit odit numquam nulla. Consequatur repudiandae fugit nulla
                        voluptatibus.
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <span className={styles.price}>$ 37.50</span>
                      </div>
                      <div className={cx("d-flex flex-nowrap mr-3")}>
                        <img src={eyeImg} alt="" className="mr-2" />
                        <span className={styles.views}>332</span>
                      </div>
                    </div>
                    <div className="d-flex pt-3">
                      <Button
                        align="center"
                        padding="large"
                        variant="primary"
                        className="d-flex align-items-center  mr-3"
                      >
                        <img src={cartImg} alt="" className="mr-2" style={{ marginTop: -4 }} />
                        <span>ADD TO CART</span>
                      </Button>
                      <Button align="center" padding="large" variant="outline-light">
                        OPEN IN APP
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-2">
              <div className={cx(styles.tagLabelsBox, "d-flex align-items-center px-3")}>
                <div className={cx(styles.tagLabel, styles.tagLabelBgBlue, "mr-2")}>Wireles</div>
                <div className={cx(styles.tagLabel, styles.tagLabelBgOrange, "mr-2")}>Input</div>
                <div className={cx(styles.tagLabel, styles.tagLabelBgGreen, "mr-2")}>Sensor</div>
              </div>
            </div>
          </Row>
        </Container>
      </Jumbotron>
      <div>
        <Container>
          <Row className="pt-5">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <div className={styles.navigation}>
                <button className={styles.activeBtn}>
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>Description</span>
                </button>
                <button>
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>Photo Gallery</span>
                </button>
                <button>
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>Components</span>
                </button>
                <button>
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>Details</span>
                </button>
                <button>
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>Video</span>
                </button>
                <button>
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>Comments</span>
                </button>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className={styles.content}>
                <h4>Description</h4>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias exercitationem
                  laborum necessitatibus nulla error similique, facilis magni omnis maiores
                  molestias? Ducimus odio magnam id aliquid repellat, placeat velit vitae excepturi?
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ad vitae, sint
                  facere, delectus aut deleniti inventore eum unde vel, aliquam accusantium placeat
                  voluptatum aliquid quam nisi sit quas saepe! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Sapiente itaque corporis quidem similique
                  molestias, possimus atque officiis nesciunt id, accusamus eius aliquam tempore
                  porro! Velit architecto placeat eaque expedita ipsum.
                </p>
                <div className={styles.imageBox}>
                  <img src={exampleImg} alt="" />
                </div>
                <h4>Photo Gallery</h4>
                {/* <Gallery images={} /> */}
                <h4>Components</h4>
                <div>
                  <ul className={styles.items}>
                    <li className="d-flex mb-2">
                      <div className={styles["Line-item"]}>
                        <div className="d-flex justyify-content-between align-items-center">
                          <div className={styles["Line-item__img"]}>
                            <img src={ex1Img} alt={cx(styles.title, "product shot")} />
                          </div>
                          <div className={styles["Line-item__content"]}>
                            <div className="d-flex justyify-content-between align-items-center mb-2">
                              <div className={styles["Line-item__title"]}>Title</div>
                              <div className={cx(styles.additionalInfo, "ml-2")}>hawhdfli??</div>
                            </div>
                            <div>
                              <div className={cx(styles.tagLabel, styles.tagLabelBgGreen, "mr-2")}>
                                Sensor
                              </div>
                              <div className={cx(styles.tagLabel, styles.tagLabelBgOrange, "mr-2")}>
                                Input
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={styles["Line-item__content-row"]}>
                            <div className="d-flex align-items-start">
                              <div className={styles["Line-item__variant-title"]}>4 pins</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className={styles["Line-item__quantity"]}>
                              <span>quantity: </span> <strong>1</strong>
                            </div>
                            <div className={styles["Line-item__price"]}>$45</div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <footer className={cx(styles.footer, "mb-3 pt-3")}>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>TOTAL</div>
                        <div className={styles.footerTaxSpan}>Including 5% in taxes</div>
                      </div>
                      <div>
                        <div className={styles.footerTotalPrice}>$ 54</div>
                        <div className={styles.footerCodeSpan}>&lt; &gt; code included</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3 align-items-center">
                      <Link
                        to="/cart"
                        className={cx(styles.openInAppBtn, "d-flex align-items-center")}
                      >
                        OPEN IN APP
                      </Link>
                      <Button
                        align="center"
                        padding="large"
                        variant="outline-primary"
                        className={cx("d-flex align-items-center", styles.addToCartBtn)}
                      >
                        <img src={cartBlueImg} alt="" className="mr-2" style={{ marginTop: -4 }} />
                        <span>ADD SHIELDS TO CART</span>
                      </Button>
                    </div>
                  </footer>
                </div>
                <h4>Details</h4>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias exercitationem
                  laborum necessitatibus nulla error similique, facilis magni omnis maiores
                  molestias? Ducimus odio magnam id aliquid repellat, placeat velit vitae excepturi?
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ad vitae, sint
                  facere, delectus aut deleniti inventore eum unde vel, aliquam accusantium placeat
                  voluptatum aliquid quam nisi sit quas saepe! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Sapiente itaque corporis quidem similique
                  molestias, possimus atque officiis nesciunt id, accusamus eius aliquam tempore
                  porro! Velit architecto placeat eaque expedita ipsum.
                </p>
                <div className={styles.imageBox}>
                  <img src={exampleImg} alt="" />
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias exercitationem
                  laborum necessitatibus nulla error similique, facilis magni omnis maiores
                  molestias? Ducimus odio magnam id aliquid repellat, placeat velit vitae excepturi?
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ad vitae, sint
                  facere, delectus aut deleniti inventore eum unde vel, aliquam accusantium placeat
                  voluptatum aliquid quam nisi sit quas saepe! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Sapiente itaque corporis quidem similique
                  molestias, possimus atque officiis nesciunt id, accusamus eius aliquam tempore
                  porro! Velit architecto placeat eaque expedita ipsum.
                </p>
                <div className={styles.imageBox}>
                  <img src={exampleImg} alt="" />
                </div>
                <h4>Video</h4>
                <div className={styles.videoBox}>
                  <video src=""></video>
                </div>
                <h4>Comments</h4>
                <div className={styles.commentsForm}>
                  <div className={styles.commentsFormHeader}>Leave a comment</div>
                  <div className="pt-3">
                    <Button
                      align="center"
                      padding="large"
                      variant="outline-secondary"
                      type="submit"
                    >
                      COMMENT
                    </Button>
                  </div>
                </div>
                <div className={styles.comments}>
                  <div className={styles.singleComment}>
                    <div className={styles.commentDetails}>
                      <div className={styles.authorAvatar}>
                        <img src={avatarImg} alt="" />
                      </div>
                      <div>
                        <strong className={styles.authorName}>John Dribble</strong>{" "}
                        <span className={styles.date}>15.12.2018, 15:12</span>
                      </div>
                    </div>
                    <div className={styles.commentText}>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia vel sunt,
                      consectetur cum, iste enim maiores architecto consequuntur.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DevKitPage;
