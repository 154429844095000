import React from "react";
import e2logoImg from "assets/images/e2-full-logo-2.svg";
import instagramImg from "assets/images/instagram.svg";
import facebookImg from "assets/images/facebook.svg";
import twitterImg from "assets/images/twitter.svg";
import linkedinImg from "assets/images/linkedin.svg";
// import pinterestImg from "assets/images/pinterest.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <>
      <Container className="mt-3 mb-5" id="footer">
        <Row className="pl-2 pr-2">
          <div className="col-sm-12">
            <div className="w-100 d-flex justify-content-between mt-5">
              <div>
                <hr className={styles.hr} />
                <p className={styles.grey}>+48 781 867 875</p>
                <p className={styles.grey}>hello@electronsquare.com</p>
                <br />
                {/* <div>
                  <Link to="/" className={styles.linkGreen}>
                    Terms and conditions
                  </Link>
                </div>
                <div>
                  <Link to="/" className={styles.linkGreen}>
                    Delivery and returns
                  </Link>
                </div> */}
                <br />
                <div className="d-flex align-items-center mt-5">
                  <a
                    href="https://www.facebook.com/Electron-Square-103672894447499/"
                    className="link-black mr-3"
                  >
                    <img src={facebookImg} alt="facebook logo" />
                  </a>
                  <a href="https://www.instagram.com/electronsquare/" className="link-black mr-3">
                    <img src={instagramImg} alt="instagram logo" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/electronsquare"
                    className="link-black mr-3"
                  >
                    <img src={linkedinImg} alt="linkedin logo" />
                  </a>
                  <a href="https://twitter.com/ElectronSquare" className="link-black mr-3">
                    <img src={twitterImg} alt="twitter logo" />
                  </a>
                </div>
              </div>
              <div>
                <img src={e2logoImg} alt="e2 logo" width="160px" className="mb-5" />
              </div>
            </div>
          </div>
          <div id="footerXX"></div>
        </Row>
      </Container>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
