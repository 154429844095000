import React, { useState, useEffect } from "react";
import cx from "classnames";
import arrowImg from "assets/images/arrow_forward_40px_active.svg";
import Button from "react-bootstrap/Button";
import { withSize } from "react-sizeme";
import { usePrevious } from "hooks";
import styles from "./Gallery.module.scss";

/**
 * @param {Object} props
 * @param {{src:string}[]} props.images array of images
 */
export const Gallery = withSize()(({ images, size, productId }) => {
  const [selectedImage, selectImage] = useState(images[0]?.src);
  const selectedIndex = images.findIndex(img => img.src === selectedImage);
  const next = () => {
    if (images[selectedIndex + 1]) {
      selectImage(images[selectedIndex + 1].src);
    } else {
      selectImage(images[0]?.src);
    }
  };
  const prev = () => {
    if (images[selectedIndex - 1]) {
      selectImage(images[selectedIndex - 1]?.src);
    } else {
      selectImage(images[images.length - 1]?.src);
    }
  };
  const previousProductId = usePrevious(productId);
  useEffect(() => {
    if (productId !== previousProductId) {
      selectImage(images[0]?.src);
    }
  }, [productId, images, previousProductId]);

  return (
    <div className={styles.imagesSection}>
      <div className={styles.largeImage} style={{ minHeight: size.width, maxHeight: size.width }}>
        <img src={selectedImage} alt="" />
        <Button
          variant="light"
          onClick={prev}
          type="Button"
          className={cx("px-2", styles.galleryBtn, styles.galleryBtnLeft)}
        >
          <img src={arrowImg} alt="" />
        </Button>
        <Button
          variant="light"
          onClick={next}
          type="Button"
          className={cx("px-2", styles.galleryBtn, styles.galleryBtnRight)}
        >
          <img src={arrowImg} alt="" />
        </Button>
      </div>
      <div className={styles.thumbnails}>
        {images.map((img, index) => (
          <button
            type="button"
            className={cx({
              [styles.thumbnail]: true,
              [styles.thumbSelected]: selectedImage === img.src,
            })}
            key={index}
            onClick={() => selectImage(img.src)}
          >
            <img src={img.src} alt="" />
          </button>
        ))}
      </div>
    </div>
  );
});

const GalleryPlaceholder = withSize()(({ size }) => {
  return (
    <div className={styles.imagesSection}>
      <div className={styles.largeImage} style={{ minHeight: size.width, maxHeight: size.width }} />
      <div className={styles.thumbnails} />
    </div>
  );
});

Gallery.Placeholder = GalleryPlaceholder;
