import React from "react";
import styles from "./PropertiesPopupTemplate.module.scss";
import xImg from "assets/images/close-white.svg";
import img1 from "assets/skins/modul_termometr.png";
import Button from "react-bootstrap/Button";
import cx from "classnames";

const PropertiesPopupTemplate = () => {
  return (
    <div className={styles.overlay}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentInnerWrapper}>
          <button className={styles.closeModalBtn}>
            <img src={xImg} alt="" />
          </button>
          <div className={styles.pictures}>
            <div className={styles.selectedPicture}>
              <img src={img1} alt="" />
            </div>
            <div className={styles.gallery}>
              <button className={styles.miniature}>
                <img src={img1} alt="" />
              </button>
              <button className={styles.miniature}>
                <img src={img1} alt="" />
              </button>
              <button className={styles.miniature}>
                <img src={img1} alt="" />
              </button>
            </div>
          </div>
          {/* Info details */}
          <div className={cx(styles.details, styles.detailsActive)}>
            <div className={styles.tabs}>
              <button className={cx(styles.tab, styles.tabActive)}>Info</button>
              <button className={styles.tab}>Usage</button>
            </div>
            <div className={styles.tabContent}>
              <div className={styles.tag}>Sensor</div>
              <div className={styles.name}>N-Mosfet</div>
              <div className={styles.number}>XRA 1201</div>
              <div className={styles.size}>
                <span>size: </span>
                <strong>1 x 1</strong>
              </div>
              <div className={styles.price}>$6.99</div>
              <Button className={styles.addBtn}>Add to Board</Button>
              <div className={styles.description}>
                Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae id
                quisquam enim autem illum asperiores architecto veniam est provident laudantium
                nulla deserunt, fugit iusto quaerat possimus, nesciunt necessitatibus ducimus sequi.
              </div>
            </div>
          </div>
          {/* Info details */}
          {/* Usage details */}
          <div className={cx(styles.details)}>
            <div className={styles.tabs}>
              <button className={styles.tab}>Info</button>
              <button className={cx(styles.tab, styles.tabActive)}>Usage</button>
            </div>
            <div className={styles.tabContent}>
              <div className={styles.name}><span>N-Mosfet: </span><strong>Basic Usage</strong></div>
              <div className={cx("mt-4",styles.description)}>
                Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae id
                quisquam enim autem illum asperiores architecto veniam est provident laudantium
                nulla deserunt, fugit iusto quaerat possimus, nesciunt necessitatibus ducimus sequi.
              </div>
            </div>
          </div>
          {/* Usage details */}
        </div>
      </div>
    </div>
  );
};

export default PropertiesPopupTemplate;
