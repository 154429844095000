import React, { forwardRef } from "react";
import cuid from "cuid";
import cx from "classnames";
import styles from "./Checkbox.module.scss";

interface Props {
  className?: string;
  checked: boolean;
  onChange: (status: boolean) => void;
  disabled?: boolean;
  label?: string;
  variant?: "fill" | "outline";
}

export const Checkbox = forwardRef(
  ({ className, checked, onChange, disabled, label, name, variant = "fill" }: Props, ref) => {
    const id = React.useMemo(cuid, []);
    return (
      <div
        className={cx(styles.container, className ?? "", {
          [styles.variantOutline]: variant === "outline",
        })}
      >
        <label
          htmlFor={id}
          className={cx({
            [styles.checkMark]: true,
            [styles.checkMarkActive]: checked,
          })}
        >
          <input
            ref={ref}
            id={id}
            name={name}
            onChange={e => onChange(e.target.checked)}
            type="checkbox"
            className={styles.input}
            checked={checked}
            disabled={disabled}
          />
        </label>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    );
  },
);
