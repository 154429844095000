export const tagColors = {
  "Input/Output": "#D3CB01",
  Actuator: "#E58D42",
  Input: "#FFC006",
  Output: "#8CC34B",
  Power: "#F54337",
  Sensor: "#019688",
  Wireless: "#04A9F5",
  Master: "#823cdc",
  Memory: "#642576",
};
