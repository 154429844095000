import DOMPurify from "dompurify";

export function productSelector(product) {
  if (!product) return null;
  return {
    ...product,
    images: product.images.edges.map(el => el.node),
    variants: product.variants.edges.map(el => el.node),
    metafields: product.metafields.edges.map(el => el.node),
    width: Number(product.metafields.edges.find(el => el.node.key === "width")?.node.value ?? 0),
    height: Number(product.metafields.edges.find(el => el.node.key === "height")?.node.value ?? 0),
  };
}

export function productsSelector(data) {
  if (!data) return [];
  return data.products.edges
    .map(el => ({
      ...el.node,
      images: el.node.images.edges.map(el => el.node),
      variants: el.node.variants.edges.map(el => el.node),
      metafields: el.node.metafields.edges.map(el => el.node),
    }))
    .filter(el => !el.title.includes(" $demo") && el.title !== "$$");
}

export function articleSelector(data) {
  if (!data) return null;
  return {
    ...data.blogByHandle.articleByHandle,
    contentHtml: DOMPurify.sanitize(data.blogByHandle.articleByHandle.contentHtml),
  };
}
