import React, { forwardRef } from "react";
import cx from "classnames";
import styles from "./SmallScreenText.module.scss";

export const SmallScreenText = forwardRef(({ refs }, ref) => {
  return (
    <>
      <div ref={refs.text} className={cx(styles.text, "d-flex")}>
        <div ref={refs.from} className={styles.idea}>
          from{" "}
        </div>
        <div ref={refs.idea} className={styles.idea}>
          idea
        </div>{" "}
        <div ref={refs.toMassProduction}>to mass production</div>
        <div ref={refs.toCreate} className={styles.toCreate}>
          : to create a
        </div>
      </div>
      <div className={styles.devkits} ref={refs.devkits}>
        <div ref={refs.devKit1} className={styles.devkit}>
          gameboy
        </div>
        <div ref={refs.devKit2} className={styles.devkit}>
          weather station
        </div>
        <div ref={refs.devKit3} className={styles.devkit}>
          UV light disinfector
        </div>
        <div ref={refs.devKit4} className={styles.devkit}>
          smart house controller
        </div>
        <div ref={refs.devKit5} className={styles.devkit}>
          any hardware device
        </div>
        <div ref={refs.devKit6} className={styles.devkit}>
          baby monitor
        </div>
      </div>
    </>
  );
});
