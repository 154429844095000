import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

export const CartCount = ({ count, theme }) => {
  const timeout = useRef();
  const initialRender = useRef(true);
  const [enlarged, setEnlarged] = useState(false);

  useEffect(() => {
    if (initialRender.current) return;
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setEnlarged(false);
    }, 500);
    setEnlarged(true);
  }, [count]);
  useEffect(() => {
    initialRender.current = false;
  }, []);

  return (
    <span
      className={cx(styles.cartCount, { [styles.cartCountEnlarged]: enlarged })}
      style={theme === "light" ? { color: "#05cf80" } : undefined}
    >
      {count || null}
    </span>
  );
};
