import React from "react";
import PT from "prop-types";
import Form from "react-bootstrap/Form";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import dropDownImg from "assets/images/baseline_arrow_drop_down_black_36dp.png";
import cuid from "cuid";
import cx from "classnames";
import styles from "./Select.module.scss";

const Select = ({
  children,
  label,
  placeholder,
  selectedLabel = placeholder,
  listStyle,
  classNames = {},
}) => {
  const id = React.useRef(cuid());
  return (
    <Form.Group controlId={`modulesForm.${id.current.toString()}`} className={classNames.formGroup}>
      <Form.Label className={classNames.label}>{label}</Form.Label>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton className={cx("form-control d-flex", styles.formControl)}>
              <span className="d-flex flex-grow-1">{selectedLabel}</span>
              <div>
                <img
                  src={dropDownImg}
                  alt=""
                  className={cx({ [styles.rotate180]: isOpen }, styles.arrow)}
                />
              </div>
            </MenuButton>
            <MenuList style={listStyle}>{children}</MenuList>
          </>
        )}
      </Menu>
    </Form.Group>
  );
};
Select.Option = MenuItem;

Select.propTypes = {
  children: PT.node.isRequired,
  label: PT.string.isRequired,
  placeholder: PT.string.isRequired,
  classNames: PT.shape({
    formGroup: PT.string,
    label: PT.string,
  }).isRequired,
  selectedLabel: PT.string,
  listStyle: PT.shape(),
};

export default Select;
