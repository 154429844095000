import { DialogOverlay, DialogContent } from "@reach/dialog";
import React, { useContext, useState, useEffect, useRef } from "react";
import closeIcon from "assets/images/close.svg";
import Button from "react-bootstrap/Button";
import { useForm, FormProvider } from "react-hook-form";
import PT from "prop-types";
import cx from "classnames";
import { shopContext } from "App";
import { isPasswordStrong } from "utilities";
import { postRegister } from "api/rest";
import Media from "react-media";
import { Captcha } from "components/Captcha";
import { Spacer } from "components/Spacer";
import { FormInputUncontrolled } from "components/FormInput/Uncontrolled";
import { Checkbox } from "components/Checkbox";
import { Greeting } from "./Greeting/Greeting";
import styles from "./RegisterModal.module.scss";
import { TermsAndConditionsModal } from "./TermsAndConditionsModal/TermsAndConditionsModal";

const resolver = data => {
  const errors = {};
  if (data.email.length === 0) {
    errors.email = "Please provide the email address";
  }
  if (data.password.length === 0) {
    errors.password = "Please provide the password";
  } else if (data.password.length <= 6) {
    errors.password = "The password is too short";
  }
  if (data.captcha.length === 0) {
    errors.captcha = "Please click captcha field for verification";
  }
  if (!data.hasAcceptedRegulations) {
    errors.hasAcceptedRegulations =
      "You have to accept our terms and conditions to create an account";
  }
  const hasError = Object.keys(errors).length > 0;
  return {
    values: hasError ? {} : data,
    errors: Object.entries(errors).reduce((acc, [key, error]) => {
      acc[key] = { message: error };
      return acc;
    }, {}),
  };
};

function useSubmitAnimation() {
  const [submitted, setSubmitted] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setShowContent(true);
      }, 500);
    } else {
      setShowContent(false);
    }
  }, [submitted]);

  return { submitted, setSubmitted, showContent };
}

export const RegisterModal = ({ isOpen, close, keycloakMode, toggleViewType }) => {
  const { loginModal, login } = useContext(shopContext);
  const { submitted, setSubmitted, showContent } = useSubmitAnimation();
  const [isRegulationsModalOpen, setRegulationsModalOpen] = useState(false);
  const dialogContent = useRef();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    formState,
    getValues,
    trigger,
    setError,
  } = useForm({
    resolver,
    defaultValues: { email: "", password: "", captcha: "", hasAcceptedRegulations: false },
    // mode: "onBlur",
  });
  const password = watch("password");
  const hasAcceptedRegulations = watch("hasAcceptedRegulations");

  const passwordIsStrong = isPasswordStrong(password);
  const onSubmit = async values => {
    const [tokens, error] = await postRegister(values);

    if (error) {
      Object.entries(error).map(([name, message]) => setError(name, { message }));
      setError("general", { message: "Form is not valid" });
    } else if (keycloakMode) {
      // TODO? It is possible that we would need to handle registering by keycloak redirect
    } else if (tokens) {
      setSubmitted(true);
      login({ tokens });
    }
  };

  function handleClose() {
    if (close) {
      close();
      setSubmitted(false);
    }
  }

  return (
    <>
      <Media query="(min-width:600px)">
        {largeScreen => (
          <DialogOverlay isOpen={isOpen} onDismiss={handleClose} style={{ zIndex: 3 }}>
            <div className={styles.container}>
              <div className={styles.wrapper}>
                <DialogContent
                  aria-label="Module details"
                  className={styles.dialogContent}
                  ref={dialogContent}
                >
                  {!submitted ? (
                    <>
                      <div
                        className={styles.content}
                        style={{ width: largeScreen ? 450 : "100vw" }}
                      >
                        <div className={styles.header}>
                          <div className={styles.headline}>Create new account</div>
                          {keycloakMode === false && (
                            <button className={styles.closeModalBtn} type="button" onClick={close}>
                              <img src={closeIcon} alt="" />
                            </button>
                          )}
                        </div>
                        <div className={styles.text}>
                          By creating a E&sup2; account you will gain access to your own Projects
                          Catalogue and Order History.
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <FormProvider setValue={setValue} getValues={getValues}>
                            <div
                              className={cx(styles.validationMessage, {
                                [styles.validationMessageVisible]: Object.keys(errors).length > 0,
                              })}
                            >
                              {errors.general && errors.general.message}
                            </div>
                            <Spacer space={25} />
                            <FormInputUncontrolled
                              focusOnMount
                              variant="large"
                              label="Email"
                              ref={register}
                              name="email"
                              type="email"
                            />
                            <FormInputUncontrolled.Error text={errors.email?.message} />
                            <Spacer space={25} />
                            {/* <div className={styles.inputGroup}>
                          <input
                            id="email"
                            name="email"
                            className={cx({
                              [styles.inputInvalid]: Boolean(errors.email),
                              [styles.notEmpty]: Boolean(email),
                            })}
                            type="email"
                            ref={register}
                            onBlur={() => trigger()}
                          />
                          <label className={styles.label} htmlFor="email">
                            Email
                          </label>
                          <button
                            className={styles.removeContentBtn}
                            type="button"
                            onClick={() => setValue("email", "")}
                          >
                            <img src={closeIcon} alt="" />
                          </button>
                        </div> */}
                            {/* <div
                          className={cx(styles.validationMessage, {
                            [styles.validationMessageVisible]: Boolean(errors.email),
                          })}
                        >
                          {errors.email?.message}
                        </div> */}
                            <FormInputUncontrolled
                              formWatched
                              variant="large"
                              label="Password"
                              ref={register}
                              name="password"
                              type="password"
                              subText={passwordIsStrong ? "Strong" : "Weak"}
                              overrides={{
                                input: {
                                  className: cx({
                                    [styles.passwordValidateWeak]: !passwordIsStrong,
                                    [styles.passwordValidateStrong]: passwordIsStrong,
                                  }),
                                },
                              }}
                            />
                            <FormInputUncontrolled.Error text={errors.password?.message} />
                            <Spacer space={25} />
                            {/* <div className={styles.inputGroup}>
                          <input
                            name="password"
                            className={cx({
                              [styles.passwordValidateWeak]: !passwordIsStrong,
                              [styles.passwordValidateStrong]: passwordIsStrong,
                              [styles.notEmpty]: Boolean(password),
                            })}
                            type="password"
                            ref={register}
                            id="password"
                          />
                          <label className={styles.label} htmlFor="password">
                            Password
                          </label>
                          <span className={styles.infoWeak}>Weak</span>
                          <span className={styles.infoStrong}>Strong</span>
                          <button
                            className={styles.removeContentBtn}
                            type="button"
                            onClick={() => setValue("password", "")}
                          >
                            <img src={closeIcon} alt="" />
                          </button>
                        </div>
                        <div
                          className={cx(styles.validationMessage, {
                            [styles.validationMessageVisible]: Boolean(errors.password),
                          })}
                        >
                          {errors.password?.message}
                        </div> */}
                            <Spacer space={15} />
                            <Captcha
                              callback={payload => {
                                setValue("captcha", payload);
                                trigger("captcha");
                              }}
                            />
                            <FormInputUncontrolled.Error text={errors.captcha?.message} />
                            <input ref={register} name="captcha" hidden readOnly />
                            <div className="d-flex mt-2">
                              <Checkbox
                                ref={register}
                                name="hasAcceptedRegulations"
                                variant="outline"
                                checked={hasAcceptedRegulations}
                                onChange={val => setValue("hasAcceptedRegulations", val)}
                                label=""
                              />
                              <div className="ml-2">
                                I accept Electronsquare{" "}
                                <button
                                  type="button"
                                  className={cx("d-inline p-0", styles.link)}
                                  onClick={() => setRegulationsModalOpen(true)}
                                >
                                  terms and conditions
                                </button>
                              </div>
                            </div>
                            <FormInputUncontrolled.Error
                              text={errors.hasAcceptedRegulations?.message}
                            />
                            <Spacer space={5} />
                            <div className="pt-3">
                              <Button
                                align="center"
                                padding="large"
                                className="w-100"
                                type="submit"
                                disabled={formState.isSubmitting}
                              >
                                REGISTER
                              </Button>
                            </div>
                          </FormProvider>
                        </form>
                      </div>
                      <div
                        className={`${styles.footer} d-flex align-items-center justify-content-between`}
                      >
                        <div>Already have an account?</div>
                        <button
                          className={styles.link}
                          onClick={() => {
                            if (keycloakMode) {
                              toggleViewType();
                            } else {
                              close();
                              loginModal.open();
                            }
                          }}
                          type="button"
                        >
                          Login
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className={styles.content} style={{ width: largeScreen ? 600 : "100vw" }}>
                      <Greeting
                        close={handleClose}
                        showContent={showContent}
                        style={{ opacity: showContent ? 1 : 0, width: largeScreen ? 520 : "100%" }}
                      />
                    </div>
                  )}
                </DialogContent>
              </div>
            </div>
          </DialogOverlay>
        )}
      </Media>
      <TermsAndConditionsModal
        isRegulationsModalOpen={isRegulationsModalOpen}
        setRegulationsModalOpen={setRegulationsModalOpen}
        setValue={(...args) => {
          setValue(...args);
          trigger(); // trigger validation
        }}
      />
    </>
  );
};

RegisterModal.propTypes = {
  keycloakMode: PT.bool.isRequired,
};
