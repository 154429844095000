import {
  restAccessTokenKey,
  restAccessRefreshTokenKey,
  restAccessTokenExpireKey,
  restRefreshTokenExpireKey,
  keycloakAccessExpireKey,
  keycloakRefreshExpireKey,
} from "App";
import { differenceInMinutes } from "date-fns";
import { refreshToken } from "api/rest";
import JwtDecode from "jwt-decode";

const required = name => console.error(`parameter "${name}" is required!`);

/**
 * function for use only in api middleware.
 * It's a helper to format api 400 response in a better way that can
 * be used directly to display errors in form
 */
function flattenErrors(error) {
  if (!error) return {};

  const errors = {};
  if (Array.isArray(error)) {
    return errors;
  }

  Object.keys(error).forEach(key => {
    if (typeof error[key] === "string") {
      errors[key] = error[key];
    } else if (error[key] instanceof Array) {
      if (error[key][0] instanceof Object) {
        errors[key] = error[key].map(err => flattenErrors(err));
      } else {
        const [firstKey] = error[key];
        errors[key] = firstKey;
      }
    }
  });

  return errors;
}

/**
 * Function refreshes access token if needed;
 * Function logs out the user if the refresh token has expired;
 */
const tokenRefresher = () => {
  /**
   * Function checks if access token has less than 5 minutes to expire
   */
  function lessThan5MinutesToExpire() {
    const restExpireDate = localStorage.getItem(restAccessTokenExpireKey);
    const keycloakExpireDate = localStorage.getItem(keycloakAccessExpireKey);
    const restMinutesLeft = differenceInMinutes(new Date(Number(restExpireDate)), new Date());
    const keycloakMinutesLeft = differenceInMinutes(
      new Date(Number(keycloakExpireDate)),
      new Date(),
    );
    // if less than 5 minutes left for token to expire
    if (restMinutesLeft <= 5 || keycloakMinutesLeft <= 5) {
      return true;
    }
    return false;
  }
  /**
   * Function checks if refresh token has expired
   */
  function refreshTokenExpired() {
    const exp1 = localStorage.getItem(restRefreshTokenExpireKey);
    const exp2 = localStorage.getItem(keycloakRefreshExpireKey);
    const hasExpired1 = differenceInMinutes(new Date(Number(exp1)), new Date()) <= 0;
    const hasExpired2 = differenceInMinutes(new Date(Number(exp2)), new Date()) <= 0;
    return hasExpired1 || hasExpired2;
  }
  /**
   * Function refreshes access token with refresh token and stores it in localStorage
   */
  async function fetchAndStoreToken() {
    return new Promise(async resolve => {
      const accessRefreshToken = localStorage.getItem(restAccessRefreshTokenKey);
      const [res] = await refreshToken({ refresh: accessRefreshToken });
      if (res) {
        const decodedRestAccess = JwtDecode(res.access);
        localStorage.setItem(restAccessTokenKey, res.access);
        localStorage.setItem(restAccessTokenExpireKey, decodedRestAccess.exp * 1000);
        resolve("success");
      } else {
        resolve("failure");
      }
    });
  }
  return new Promise(async resolve => {
    const tokenNeedsRefresh = lessThan5MinutesToExpire();

    if (tokenNeedsRefresh === false) {
      resolve("success");
    } else {
      const refreshTokenHasExpired = refreshTokenExpired();
      if (refreshTokenHasExpired) {
        // not the best solution, it would be nice to find a better way to logout the user.
        // possible solution: moving global state to mobx-state-tree or redux
        setTimeout(() => {
          if (window.logout) {
            window.logout();
          }
        }, 1000);
        resolve("failure");
      } else {
        const status = await fetchAndStoreToken();
        resolve(status);
      }
    }
  });
};

function createData(body) {
  if (body) {
    if (body instanceof FormData) return body;
    return JSON.stringify(body);
  }
  return undefined;
}

const abortControllers: { [key: string]: AbortController } = {};

export const api = ({
  method = required("method"),
  body,
  headers: customHeaders,
  url = required("url"),
  tokenMiddleware = false,
  abortToken,
}) => {
  if (method.toUpperCase() === "POST" && !body) {
    required("body");
  }

  // define abort controller for duplicate request canceling
  const abortName = abortToken || "";
  if (abortControllers[abortName]) {
    abortControllers[abortName].abort();
  }
  const signal = (() => {
    if (!abortToken) return undefined;
    abortControllers[abortName] = new window.AbortController();
    return abortControllers[abortName].signal;
  })();
  return new Promise(async resolve => {
    const token = localStorage.getItem(restAccessTokenKey);
    const headers = {
      // "content-type": "application/json",
      ...customHeaders,
    };
    if (body instanceof FormData === false) {
      headers["Content-Type"] = "application/json";
    }
    if (tokenMiddleware && token) {
      headers.Authorization = `Bearer ${token}`;
      const tokenRefreshStatus = await tokenRefresher();
      if (tokenRefreshStatus === "failure") {
        resolve([null, {}, { ok: false, status: 401 }]);
      }
    }
    const data = createData(body);
    fetch(url, {
      method,
      body: data,
      headers,
      signal,
    }).then(
      response => {
        delete abortControllers[abortName];
        const { status } = response;
        response.text().then(text => {
          const statusOk = status && String(status)[0] === "2";
          if (text.length > 0) {
            let data;
            try {
              data = JSON.parse(text);
            } catch (err) {
              // it will catch if there is a response, but it's not json. Most often it's 500
              console.error("Server error occurred");
            }
            if (statusOk) {
              resolve([data, null, { ok: statusOk, status }]);
            } else {
              resolve([null, flattenErrors(data), { ok: statusOk, status }]);
            }
          } else if (statusOk) {
            resolve([null, null, { ok: statusOk, status }]);
          } else {
            resolve([null, null, { ok: statusOk, status }]);
          }
        });
      },
      err => {
        delete abortControllers[abortName];
        const isCanceled = err.name === "AbortError";
        if (isCanceled) {
          if (process.env.NODE_ENV === "development") {
            // eslint-disable-next-line
            console.log("Duplicated request canceled");
          }
          resolve([null, {}, { ok: false, status: 0, isCanceled }]);
        } else {
          console.error(err);
          resolve([null, {}, { ok: false, status: 502 }]);
        }
      },
    );
  });
};

export const tokenRefreshMiddleware = fetchApi => params => {
  return fetchApi({ ...params, tokenMiddleware: true });
};
