import React, { Component, createContext, useContext, useMemo } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Home from "pages/Home/Home";
import ModuleDetailsModal from "components/ModuleDetailsModal";
import Products from "pages/Products";
import Footer from "components/Footer";
import { LoginModal } from "components/LoginModal";
import { CUSTOMER, CHECKOUT_STATUS, PRODUCTS } from "api/queries";
import { Product } from "pages/Product";
import { RegisterModal } from "components/RegisterModal";
import { CHECKOUT_CUSTOMER_ASSOCIATE } from "api/mutations";
import { AboutUs } from "pages/AboutUs";
import { Animation as HowItWorks } from "pages/HowItWorks/Animation";
import { MyProjects } from "pages/MyProjects";
import NewProjectModal from "pages/Templates/NewProjectModal/NewProjectModal";
import { Authorize } from "pages/Authorize";
import { AuthRoute } from "components/AuthRoute";
import { Modal } from "components/Modal/Modal";
import { context as toastrContext } from "components/Toastr/ToastrController";
import PopupTemplate from "pages/Templates/PopupTemplate/PopupTemplate";
import PropertiesPopupTemplate from "pages/Templates/PropertiesPopupTemplate/PropertiesPopupTemplate";
import { productsSelector } from "api/selectors";
import { SearchResults } from "pages/SearchResults/SearchResults";
// import { Devkit } from "pages/Devkit";
import { Profile } from "pages/Profile/Profile";
import { AlertModalController } from "components/Alert/Alert";
import { ScrollToTop } from "components/ScrollToTop";
import { cookie } from "utilities";
import { PasswordReset } from "pages/PasswordReset/PasswordReset";
import { PasswordResetEmailModal } from "pages/PasswordResetEmailModal/PasswordResetEmailModal";
import jwtDecode from "jwt-decode";
import { SocialLogin } from "pages/SocialLogin/SocialLogin";
import { DevkitForm } from "pages/DevkitForm/DevkitForm";
import { getUser } from "api/rest";
import { UserActivation } from "pages/User/Activation/UserActivation";
import { Cart } from "./components/Cart";
import { shopifyShopUrl, shopifyAccessToken } from "./index";
import { Cart as CartView } from "./pages/Cart";
import DevKitPage from "./pages/Templates/DevKitPage/DevKitPage";

export const shopContext = createContext({});
const productsContext = createContext([]);
const authContext = createContext(null);
const userContext = createContext([null, () => {}]);

export const useProducts = () => {
  const { products, fetching } = useContext(productsContext);
  return useMemo(() => [products, { fetching }], [products, fetching]);
};

export const useProduct = handle => {
  const [products, { fetching }] = useProducts();
  return useMemo(() => {
    return [products.find(product => product.handle === handle), { fetching }];
  }, [products, handle, fetching]);
};

export const useAuth = () => {
  const auth = useContext(authContext);
  return useMemo(() => auth, [auth]);
};

export const useAuthorizedUser = () => {
  const user = useContext(userContext);
  return useMemo(() => user, [user]);
};

function fetchQuery(query) {
  return new Promise(async resolve => {
    const optionsQuery = {
      method: "post",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": shopifyAccessToken,
      },
      body: query,
    };
    const res = await fetch("https://" + shopifyShopUrl + `/api/graphql`, optionsQuery);
    const response = await res.json();
    resolve(response);
  });
}

const customerKey = "e2-www-customer-key";
const customerAccessTokenKey = "e2-www-customer-access-token";
const customerAccessTokenExpireKey = "e2-www-customer-access-token-expire-date";
export const restAccessTokenKey = "e2-rest-access-token";
export const restAccessRefreshTokenKey = "e2-rest-refresh-access-token";
export const keycloakAccessTokenKey = "e2-keycloak-access-token";
const keycloakRefreshAccessTokenKey = "e2-keycloak-refresh-access-token";
export const keycloakAccessExpireKey = "e2-keycloak-access-token-expire";
export const keycloakRefreshExpireKey = "e2-keycloak-refresh-token-expire";
export const restAccessTokenExpireKey = "e2-rest-access-token-expire";
export const restRefreshTokenExpireKey = "e2-rest-refresh-token-expire";

const FatalErrorOverlay = ({ close, isOpen }) => {
  const history = useHistory();

  function handleClose() {
    history.push("/");
    close();
  }

  return (
    <>
      <Home />
      <Modal isOpen={isOpen} close={handleClose} size="auto">
        <Modal.Body title="Error occurred">Something unexpected happened!</Modal.Body>
      </Modal>
    </>
  );
};

class App extends Component {
  loginModal = {
    open: () => this.setState({ isLoginModalOpen: true }),
    close: () => this.setState({ isLoginModalOpen: false }),
  };

  registerModal = {
    open: () => this.setState({ isRegisterModalOpen: true }),
    close: () => this.setState({ isRegisterModalOpen: false }),
  };

  passwordResetEmailModal = {
    open: () => this.setState({ isPasswordResetEmailModalOpen: true, isLoginModalOpen: false }),
    close: () => this.setState({ isPasswordResetEmailModalOpen: false }),
  };

  checkoutEvents = {
    /**
     * @param {Object=} customer
     */
    associate: ({ customer, checkout, token }) => {
      const { apolloClient } = this.props;

      if (customer) {
        apolloClient.mutate({
          mutation: CHECKOUT_CUSTOMER_ASSOCIATE,
          variables: {
            checkoutId: checkout.id,
            customerAccessToken: token,
          },
        });
      }
    },
  };

  checkoutCheckInterval = {
    interval: 0,
    fetch: async checkoutId => {
      const { apolloClient, client } = this.props;
      const { auth } = this.state;
      const { data } = await apolloClient.query({
        query: CHECKOUT_STATUS,
        variables: { id: checkoutId },
        fetchPolicy: "network-only",
      });
      if (data.node.completedAt) {
        this.checkoutCheckInterval.stop();
        this.setState({ isCartOpen: false });
        const checkout = await client.checkout.create();

        if (checkout) {
          this.checkoutEvents.associate({
            customer: auth.customer,
            checkout,
            token: auth.shopifyCustomerAccessToken,
          });
        }
      }
    },
    start: async checkoutId => {
      return new Promise(resolve => {
        if (this.checkoutCheckInterval.interval) {
          return resolve();
        }
        this.checkoutCheckInterval.interval = setInterval(
          () => this.checkoutCheckInterval.fetch(checkoutId),
          5000,
        );
        this.setState({ isCheckingCheckoutStatus: true }, resolve);
      });
    },
    stop: () => {
      this.setState({ isCheckingCheckoutStatus: false });
      clearInterval(this.checkoutCheckInterval.interval);
      this.checkoutCheckInterval.interval = 0;
    },
  };

  constructor() {
    super();

    this.state = {
      internalError: false,
      checkout: { lineItems: [] },
      isCartOpen: false,
      tagsByProduct: {},
      tags: [],
      shop: {},
      shopInfo: null,
      isLoginModalOpen: false,
      isRegisterModalOpen: false,
      isPasswordResetEmailModalOpen: false,
      user: null,
      auth: {
        isAuthenticated: Boolean(localStorage.getItem(customerAccessTokenKey)),
        shopifyCustomerAccessToken: localStorage.getItem(customerAccessTokenKey),
        customer: this.dehydrateCustomer(),
        shopifyAccessExpire: localStorage.getItem(customerAccessTokenExpireKey),
        keycloakAccessToken: localStorage.getItem(keycloakAccessTokenKey),
        keycloakAccessExpire: localStorage.getItem(keycloakAccessExpireKey),
        keycloakRefreshExpire: localStorage.getItem(keycloakRefreshExpireKey),
        keycloakRefreshToken: localStorage.getItem(keycloakRefreshAccessTokenKey),
        restAccessToken: localStorage.getItem(restAccessTokenKey),
        restRefreshToken: localStorage.getItem(restAccessRefreshTokenKey),
        restAccessExpire: localStorage.getItem(restAccessTokenExpireKey),
        restRefreshExpire: localStorage.getItem(restRefreshTokenExpireKey),
        userId: localStorage.getItem("userId"),
      },
      // boughtProducts: ["Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTM0OTI5MDg5MzM2Mw=="],
      boughtProducts: [],
      isCheckingCheckoutStatus: false,
      products: [],
      fetchingProducts: false,
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    const { auth } = this.state;

    // hoist logout function to have access to it from restApiConnector
    window.logout = this.logout;

    const createAndAssociate = async customer => {
      const checkout = await client.checkout.create();
      if (checkout) {
        this.setState({ checkout });
        this.checkoutEvents.associate({
          customer,
          checkout,
          token: auth.shopifyCustomerAccessToken,
        });
      }
    };

    this.fetchAuthorizedUser();
    const [customer, shopInfo] = await Promise.all([this.fetchCustomer(), this.fetchShopInfo()]);

    this.setState({ shopInfo });
    // if there is a customer, check if it has incomplete checkout.
    // if it does -> fetch it
    // if it doesn't -> create a new one and associate it
    if (customer?.lastIncompleteCheckout?.id) {
      const checkout = await client.checkout.fetch(customer.lastIncompleteCheckout.id);
      // if saved checkout has the same currency as the shop, save it;
      // otherwise create brand new empty checkout.
      if (checkout.currencyCode === shopInfo.currencyCode) {
        this.setState({ checkout });
      } else {
        createAndAssociate(customer);
      }
    } else {
      createAndAssociate(customer);
    }

    this.setState({ fetchingProducts: true });

    client.shop.fetchInfo().then(res => {
      this.setState({
        shop: res,
      });
    });

    const fetchProductsForTagsAndTags = async () => {
      const tagsQuery = `query ProductsForTags {
        products(first:200, query: "-title:%% AND -title:$$") {
          edges {
            node {
              id
              tags
            }
          }
        }
        productTags(first:200) {
          edges {
            node
          }
        }
      }
      `;

      const response = await fetchQuery(tagsQuery);
      this.setState({
        tagsByProduct: response.data.products.edges.reduce((acc, edge) => {
          acc[edge.node.id] = edge.node.tags;
          return acc;
        }, {}),
        tags: response.data.productTags.edges.map(edge => edge.node),
      });
    };
    fetchProductsForTagsAndTags();

    const products = await this.fetchProducts();
    this.setState({ products, fetchingProducts: false });
  }

  componentDidUpdate(oldProps, oldState) {
    const { isCartOpen, auth } = this.state;
    // if opened
    if (!oldState.isCartOpen && isCartOpen) {
      document.body.classList.add("overflow-hidden");
      // if closed
    } else if (oldState.isCartOpen && !isCartOpen) {
      document.body.classList.remove("overflow-hidden");
    }

    if (auth.isAuthenticated === true && oldState.auth.isAuthenticated === false) {
      this.fetchCustomer();
      this.fetchAuthorizedUser();
    }
    if (!oldState.auth.customer && auth.customer) {
      this.mergeAndSwitchCheckouts();
    }
  }

  componentDidCatch() {
    this.setState({ internalError: true });
  }

  fetchProducts = () => {
    return new Promise(async resolve => {
      const { apolloClient } = this.props;
      const { data } = await apolloClient.query({
        query: PRODUCTS,
        variables: { search: "-title:%% AND -title:$$" },
      });
      if (data.products) {
        const products = productsSelector(data);
        resolve(products);
      } else {
        resolve([]);
      }
    });
  };

  /**
   * returns customer from localStorage
   */
  dehydrateCustomer = () => {
    let customer = null;
    const customerString = localStorage.getItem(customerKey);
    if (customerString) {
      try {
        const parsed = JSON.parse(customerString);
        customer = parsed;
      } catch (err) {
        console.error(err);
      }
    }
    return customer;
  };

  fetchAuthorizedUser = async () => {
    const userId = localStorage.getItem("userId");
    if (!userId) return;
    const [user] = await getUser(userId);
    if (user) {
      this.setState({ user });
    }
  };

  // It is not used yet
  // getKeycloakToken = async () => {
  //   const { auth } = this.state;
  //   const accessToken = auth.keycloakAccessToken;
  //   const refreshToken = auth.keycloakRefreshToken;
  //   const accessExpireDate = auth.keycloakAccessExpire;
  //   const refreshExpireDate = auth.keycloakRefreshExpire;
  //   const minutesLeftToExpire = differenceInMinutes(new Date(Number(accessExpireDate)), new Date());
  //   const minutesLeftToExpireR = differenceInMinutes(
  //     new Date(Number(refreshExpireDate)),
  //     new Date(),
  //   );
  //   console.log({ accessExpireDate, minutesLeftToExpireR, refreshExpireDate, auth });
  //   const [res] = await keycloakRefreshToken({ keycloakRefreshToken: refreshToken });
  // };

  /**
   * If a user logs in and has uncompleted checkout at his account, we want to merge current not-associated checkout into an associated one and use the second one.
   */
  mergeAndSwitchCheckouts = async () => {
    const { client } = this.props;
    const { auth, checkout } = this.state;
    if (auth.customer.lastIncompleteCheckout) {
      try {
        const customerCheckout = await client.checkout.fetch(
          auth.customer.lastIncompleteCheckout.id,
        );
        const items = checkout.lineItems.map(lineItem => ({
          variantId: lineItem.variant.id,
          quantity: lineItem.quantity,
        }));
        const callback = () => {
          // it prevents merging current checkout to itself and double items quantity
          if (checkout.id !== customerCheckout.id) {
            this.addVariantsToCart(items);
          }
        };
        this.setState({ checkout: customerCheckout }, callback);
      } catch (err) {
        console.error(err);
      }
    }
  };

  verifyTokens = tokens => {
    if (
      !tokens.keycloakAccessToken ??
      !tokens.keycloakRefreshToken ??
      !tokens.access ??
      !tokens.refresh ??
      !tokens.shopifyToken
    ) {
      throw new Error(
        `There are not enough tokens in payload! Payload should contain keycloakAccessToken, keycloakRefreshToken, access and refresh, but contains ${Object.keys(
          tokens,
        ).join(", ")}`,
      );
    }
  };

  login = ({ tokens }) => {
    const { open: toastr } = this.context;

    this.verifyTokens(tokens);
    const decodedKeycloakAccess = jwtDecode(tokens.keycloakAccessToken);
    const decodedKeycloakRefresh = jwtDecode(tokens.keycloakRefreshToken);
    const decodedRestAccess = jwtDecode(tokens.access);
    const decodedRestRefresh = jwtDecode(tokens.refresh);
    const shopifyCustomerAccessToken = tokens.shopifyToken;
    const shopifyAccessExpire = null; // artefact, it should be handled or deleted in the future
    const restRefreshToken = tokens.refresh;
    const restAccessToken = tokens.access;
    const { keycloakAccessToken } = tokens;
    const { keycloakRefreshToken } = tokens;
    // expire dates need to be multiplied by the number of milliseconds in a second -
    // backend provides with date in seconds, but browser dates work in milliseconds
    const restAccessExpire = decodedRestAccess.exp * 1000;
    const restRefreshExpire = decodedRestRefresh.exp * 1000;
    const keycloakAccessExpire = decodedKeycloakAccess.exp * 1000;
    const keycloakRefreshExpire = decodedKeycloakRefresh.exp * 1000;
    const userId = decodedRestAccess.user_id;

    localStorage.setItem(customerAccessTokenKey, shopifyCustomerAccessToken);
    localStorage.setItem(customerAccessTokenExpireKey, shopifyAccessExpire);
    localStorage.setItem(restAccessTokenKey, restAccessToken);
    localStorage.setItem(restAccessRefreshTokenKey, restRefreshToken);
    localStorage.setItem(keycloakAccessTokenKey, keycloakAccessToken);
    localStorage.setItem(keycloakRefreshAccessTokenKey, keycloakRefreshToken);
    localStorage.setItem(keycloakAccessExpireKey, keycloakAccessExpire);
    localStorage.setItem(keycloakRefreshExpireKey, keycloakRefreshExpire);
    localStorage.setItem(restAccessTokenExpireKey, restAccessExpire);
    localStorage.setItem(restRefreshTokenExpireKey, restRefreshExpire);
    localStorage.setItem("userId", userId);
    toastr({ type: "success", text: "You just got logged in" });
    this.setState(state => ({
      auth: {
        ...state.auth,
        isAuthenticated: true,
        shopifyCustomerAccessToken,
        keycloakAccessToken,
        keycloakRefreshToken,
        restAccessToken,
        restRefreshToken,
        shopifyAccessExpire,
        restAccessExpire,
        restRefreshExpire,
        userId,
      },
    }));
  };

  logout = () => {
    const { open: toastr } = this.context;
    const { client } = this.props;
    localStorage.removeItem(customerAccessTokenKey);
    localStorage.removeItem(customerAccessTokenExpireKey);
    localStorage.removeItem(restAccessTokenKey);
    localStorage.removeItem(restAccessRefreshTokenKey);
    localStorage.removeItem(keycloakAccessTokenKey);
    localStorage.removeItem(keycloakRefreshAccessTokenKey);
    localStorage.removeItem(customerKey);
    localStorage.removeItem(keycloakAccessExpireKey);
    localStorage.removeItem(keycloakRefreshExpireKey);
    localStorage.removeItem(restAccessTokenExpireKey);
    localStorage.removeItem(restRefreshTokenExpireKey);
    localStorage.removeItem("userId");
    cookie.erase("auth");
    toastr({ type: "success", text: "You have been logged out" });
    this.setState({
      auth: {
        isAuthenticated: false,
        shopifyCustomerAccessToken: null,
        shopifyAccessExpire: null,
        customer: null,
        keycloakAccessToken: null,
        keycloakRefreshToken: null,
        restAccessToken: null,
        restRefreshToken: null,
        restAccessExpire: null,
        restRefreshExpire: null,
        userId: null,
      },
    });
    function logoutFromKeycloak() {
      window.location.href =
        "https://keycloak-che.ide.electronsquare.com/auth/realms/che/protocol/openid-connect/logout?redirect_uri=https%3A%2F%2Fshop.ide.electronsquare.com%2F";
    }
    client.checkout.create().then(
      checkout => {
        if (checkout) {
          this.setState({ checkout });
          logoutFromKeycloak();
        }
      },
      () => {
        logoutFromKeycloak();
      },
    );
  };

  /**
   * @returns {Promise}
   */
  fetchCustomer = () => {
    const { auth } = this.state;
    const { apolloClient } = this.props;
    return new Promise(async resolve => {
      if (!auth.isAuthenticated) return resolve();
      const { data } = await apolloClient.query({
        query: CUSTOMER,
        variables: { customerAccessToken: auth.shopifyCustomerAccessToken },
      });
      if (data.customer) {
        this.setState(state => ({ ...state, auth: { ...state.auth, customer: data.customer } }));
        try {
          const customerString = JSON.stringify(data.customer);
          localStorage.setItem(customerKey, customerString);
          /**
           * Cookie is set across electronsquare domain to be used in editor
           */
          cookie.set(
            "auth",
            JSON.stringify({
              customer: data.customer,
              customerAccessToken: auth.shopifyCustomerAccessToken,
            }),
          );
        } catch (err) {
          console.error(err);
        }
      }
      return resolve(data.customer);
    });
  };

  fetchShopInfo = async () => {
    const res = await fetchQuery(`query ShopInfo {
      shop {
        name
        currencyCode
        productTypes(first: 100) {
          edges {
            node
          }
        }
      }
    }`);
    return {
      ...res.data.shop,
      productTypes: res.data.shop.productTypes.edges.map(edge => edge.node),
    };
  };

  // TODO, spike usage only
  clonePredefinedCheckout = async () => {
    const { client } = this.props;
    try {
      const checkout = await client.checkout.fetch(
        "Z2lkOi8vc2hvcGlmeS9DaGVja291dC8wOTU2NDZmOTUwNzY5Y2E1ZDc3YjQ5ZjNiMDQ3NTgwND9rZXk9Yjc1YjU4MTljNjg4ZWQ4MjQ0NmMxMWZiZjEzYjQ5M2M=",
      );
      const items = checkout.lineItems.map(lineItem => ({
        variantId: lineItem.variant.id,
        quantity: lineItem.quantity,
      }));
      this.addVariantsToCart(items);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @returns {Promise<{status:"success" | "failure"}>}
   */
  addVariantToCart = async (variantId, quantity) => {
    const { checkout } = this.state;
    const { client } = this.props;

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = checkout.id;

    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(
      res => {
        this.setState({
          checkout: res,
        });
        return { status: "success" };
      },
      () => {
        return { status: "failure" };
      },
    );
  };

  /**
   * Function used to programmatically add items to cart.
   * @param {{variantId: string, quantity:number}[]} lineItems
   */
  addVariantsToCart = lineItems => {
    const { checkout } = this.state;
    const { client } = this.props;

    const checkoutId = checkout.id;

    return client.checkout.addLineItems(checkoutId, lineItems).then(res => {
      this.setState({
        checkout: res,
      });
    });
  };

  handleCartOpen = () => {
    this.setState({ isCartOpen: true });
  };

  updateQuantityInCart = (lineItemId, quantity) => {
    const { checkout } = this.state;
    const { client } = this.props;

    const checkoutId = checkout.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];

    return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      this.setState({
        checkout: res,
      });
    });
  };

  removeLineItemInCart = lineItemId => {
    const { checkout } = this.state;
    const { client } = this.props;

    const checkoutId = checkout.id;

    return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.setState({
        checkout: res,
      });
    });
  };

  handleCartClose = () => {
    this.setState({
      isCartOpen: false,
    });
  };

  handleUpdateUser = toUpdate => {
    this.setState(s => ({ user: { ...s.user, ...toUpdate } }));
  };

  render() {
    const {
      isCartOpen,
      shop,
      products,
      checkout,
      tagsByProduct,
      tags,
      shopInfo,
      isLoginModalOpen,
      isRegisterModalOpen,
      auth,
      user,
      boughtProducts,
      fetchingProducts,
      isPasswordResetEmailModalOpen,
      internalError,
    } = this.state;
    const { client } = this.props;
    return (
      <>
        <ScrollToTop />
        <shopContext.Provider
          value={{
            addVariantToCart: this.addVariantToCart,
            handleCartOpen: this.handleCartOpen,
            tagsByProduct,
            isCartOpen,
            checkout,
            products,
            client,
            tags,
            shop,
            shopInfo,
            fetchProducts: this.fetchProducts,
            loginModal: this.loginModal,
            registerModal: this.registerModal,
            auth,
            user,
            login: this.login,
            logout: this.logout,
            updateQuantityInCart: this.updateQuantityInCart,
            removeLineItemInCart: this.removeLineItemInCart,
            closeCart: this.handleCartClose,
            boughtProducts,
            startCheckoutInterval: this.checkoutCheckInterval.start,
          }}
        >
          <productsContext.Provider value={{ products, fetching: fetchingProducts }}>
            <authContext.Provider value={auth}>
              <userContext.Provider value={[user, this.handleUpdateUser]}>
                <AlertModalController>
                  {internalError ? (
                    <FatalErrorOverlay
                      isOpen={internalError}
                      close={() => this.setState({ internalError: false })}
                    />
                  ) : (
                    <Switch>
                      <Route path="/" exact={true} component={Home} />
                      <Route path="/products" exact={true} component={Products} />
                      <Route path="/products/:handle" exact={true} component={Product} />
                      <AuthRoute
                        isAuthenticated={auth.isAuthenticated}
                        path="/devkit/create/:projectId/:draftDevkitId/:devkitId"
                        exact={true}
                        component={DevkitForm}
                      />
                      <Route path="/devkit/:handle" exact={true} component={Product} />
                      {/* <Route path="/devkit/:handle" exact={true} component={Devkit} /> */}
                      {/* <Route path="/how-it-works" exact={true} component={HowItWorks} /> */}
                      <Route path="/how-it-works" exact={true} component={HowItWorks} />
                      <AuthRoute
                        isAuthenticated={auth.isAuthenticated}
                        path="/my-projects"
                        exact={true}
                        component={MyProjects}
                      />
                      <Route path="/about-us" exact={true} component={AboutUs} />
                      <Route path="/search-results" exact={true} component={SearchResults} />
                      <AuthRoute
                        isAuthenticated={auth.isAuthenticated}
                        path="/profile"
                        exact={true}
                        component={Profile}
                      />
                      <Route
                        path="/templates/add-project"
                        exact={true}
                        component={NewProjectModal}
                      />
                      <Route path="/templates/popup" exact={true} component={PopupTemplate} />
                      <Route
                        path="/templates/properties-popup"
                        exact={true}
                        component={PropertiesPopupTemplate}
                      />
                      <Route path="/templates/dev-kit" exact={true} component={DevKitPage} />
                      {/* <Route
                    path="/templates/how-it-works"
                    exact={true}
                    component={HowItWorksTemplate}
                  /> */}
                      {/* <Route path="/templates/login" exact={true} component={LoginTemplate} /> */}
                      {/* <Route path="/templates/register" exact={true} component={RegisterTemplate} /> */}
                      {/* <Route
                    path="/templates/register-confirmation"
                    exact={true}
                    component={RegisterConfirmation}
                  />
                  <Route
                    path="/templates/password-reset/email"
                    exact={true}
                    component={PasswordResetEmail}
                  />
                  <Route
                    path="/templates/password-reset/new-password"
                    exact={true}
                    component={PasswordResetNewPassword}
                  />
                  <Route
                    path="/templates/password-reset/email-send"
                    exact={true}
                    component={EmailConfirmation}
                  />

                  <Route
                    path="/templates/my-projects"
                    exact={true}
                    component={MyProjectsTemplate}
                  /> */}
                      {/* <Route path="/templates/checkout" exact={true} component={CheckoutTemplate} /> */}
                      <Route path="/cart" exact={true} component={CartView} />
                      <Route path="/authorize" exact={true} component={Authorize} />
                      <Route
                        path="/user/activation"
                        exact={true}
                        render={({ history }) => (
                          <>
                            <Home />
                            <UserActivation close={() => history.replace({ pathname: "/" })} />
                          </>
                        )}
                      />
                      <Route
                        path="/password/reset"
                        exact={true}
                        render={({ history }) => (
                          <>
                            <Home />
                            <PasswordReset close={() => history.replace({ pathname: "/" })} />
                          </>
                        )}
                      />
                      <Route
                        path="/social-login/:provider"
                        exact={true}
                        render={({ history }) => (
                          <>
                            <Home />
                            <SocialLogin close={() => history.replace({ pathname: "/" })} />
                          </>
                        )}
                      />
                      <Route render={() => <h1 className="text-center">404</h1>} />
                    </Switch>
                  )}

                  <Footer />
                  {isCartOpen && (
                    <Cart.Overlay
                      onClick={this.handleCartClose}
                      onKeyDown={this.handleCartClose}
                      isOpen={isCartOpen}
                    />
                  )}
                  <Cart
                    checkout={checkout}
                    isCartOpen={isCartOpen}
                    handleCartClose={this.handleCartClose}
                    updateQuantityInCart={this.updateQuantityInCart}
                    removeLineItemInCart={this.removeLineItemInCart}
                    boughtProducts={boughtProducts}
                    startCheckoutInterval={this.checkoutCheckInterval.start}
                  />
                  <ModuleDetailsModal />
                  <LoginModal
                    isOpen={isLoginModalOpen}
                    close={this.loginModal.close}
                    keycloakMode={false}
                    openPasswordResetEmailModal={this.passwordResetEmailModal.open}
                  />
                  <RegisterModal
                    isOpen={isRegisterModalOpen}
                    keycloakMode={false}
                    close={this.registerModal.close}
                  />
                  <PasswordResetEmailModal
                    isOpen={isPasswordResetEmailModalOpen}
                    close={this.passwordResetEmailModal.close}
                  />
                  {/* <SpikeMutations /> */}
                </AlertModalController>
              </userContext.Provider>
            </authContext.Provider>
          </productsContext.Provider>
        </shopContext.Provider>
        {/* <iframe
          id="iframe"
          title="Inline Frame Example"
          width="1280"
          height="800"
          src="https://che-che.ide.electronsquare.com/e2editor/"
        /> */}
      </>
    );
  }
}

App.propTypes = {
  client: PropTypes.shape().isRequired,
  apolloClient: PropTypes.shape().isRequired,
};
App.contextType = toastrContext;

export default App;

window.addEventListener("message", event => {
  console.log("message between frames: ", event.data);
});
