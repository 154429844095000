import { useContext, useMemo } from "react";
import { context, ToastSubscription } from "./ToastrController";

export const useToastr = () => {
  const toastr = useContext(context);
  return useMemo(
    () => ({
      open: ({ text, title, type }: Omit<ToastSubscription, "id">) => {
        toastr.open({ text, title, type });
      },
    }),
    [toastr],
  );
};
