import Media from "react-media";
import React, { createContext, useContext } from "react";
import closeIcon from "assets/images/close.svg";
import cx from "classnames";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { animated, useTransition } from "react-spring";
import styles from "./Modal.module.scss";

interface Props {
  children: JSX.Element;
  isOpen: boolean;
  close: () => void;
  size?:
    | {
        all?: { width: string },
        xlg?: { width: string },
        lg?: { width: string },
        md?: { width: string },
        sm?: { width: string },
      }
    | "auto";
}

function getWidth(matches, size) {
  if (size === "auto") return "auto";
  if (size) {
    if (matches.xlg) return size.xlg?.width || size.all?.width || "50vw";
    if (matches.lg) return size.lg?.width || size.all?.width || "60vw";
    if (matches.md) return size.md?.width || size.all?.width || "70vw";
    if (matches.sm) return size.sm?.width || size.all?.width || "80vw";
  }
  return "50vw";
}

const mediaQueries = {
  sm: "(max-width: 599px)",
  md: "(min-width: 600px) and (max-width: 1199px)",
  lg: "(min-width: 1200px) and (max-width: 1399px)",
  xlg: "(min-width: 1400px)",
};

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

const context = createContext({});

export function Modal({ isOpen, close, children, size }: Props) {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, top: -10 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: 10 },
    config: { duration: 100 },
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props: tStyles }: any) =>
          item && (
            <AnimatedDialogOverlay
              style={{
                opacity: tStyles.opacity,
                zIndex: 4,
                display: size === "auto" ? "flex" : undefined,
              }}
              onDismiss={close}
              key={key}
            >
              <Media queries={mediaQueries}>
                {matches => (
                  <AnimatedDialogContent
                    aria-label="Wybieranie lokalizacji zamówienia"
                    style={{
                      width: getWidth(matches, size),
                      ...(size === "auto" ? { margin: "auto" } : {}),
                      transform: tStyles.top.interpolate(
                        (value: number) => `translate3d(0px, ${value}px, 0px)`,
                      ),
                    }}
                    className={styles.animatedDialogContent}
                  >
                    <context.Provider value={{ close }}>{children}</context.Provider>
                  </AnimatedDialogContent>
                )}
              </Media>
            </AnimatedDialogOverlay>
          ),
      )}
    </div>
  );
}
const Body = ({ title, children, overwrites = {} }) => {
  const { close } = useContext(context);
  return (
    <div
      className={cx(styles.content, overwrites?.content?.className)}
      style={{ backgroundColor: "#fff" }}
    >
      <div className={styles.header}>
        <div className={styles.headline}>{title}</div>
        <button className={styles.closeModalBtn} type="button" onClick={close}>
          <img src={closeIcon} alt="" />
        </button>
      </div>
      {children}
    </div>
  );
};

Modal.Body = Body;
