import React, { useContext, useEffect } from "react";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import arrowImg from "assets/images/arrowGreen.svg";
// import { Checkbox } from "components/Checkbox";
import Button from "react-bootstrap/Button";
// import exampleImg from "assets/skins/modul_pulsoksymetr_2.png";
// import greenBinImg from "assets/images/binG.svg";
// import binImg from "assets/images/binB.svg";
import { shopContext } from "App";
import LineItem from "components/Cart/LineItem/LineItem";
import styles from "./Cart.module.scss";

export const Cart = () => {
  const {
    updateQuantityInCart,
    removeLineItemInCart,
    checkout,
    closeCart,
    boughtProducts,
    startCheckoutInterval,
  } = useContext(shopContext);
  useEffect(() => {
    closeCart();
  }, [closeCart]);

  const openCheckout = async () => {
    await startCheckoutInterval(checkout.id);
    window.open(checkout.webUrl);
  };
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div>
        <Container>
          <Row>
            <div className="col-sm-12 pt-3">
              <Link to="/" className={`${styles.linkGreen} d-flex align-items-start mb-2`}>
                <img src={arrowImg} alt="" />
                <span className="ml-2">Back</span>
              </Link>
              <div className="d-flex align-items-center justify-content-between">
                <h1 className={styles.heading}>Your Cart</h1>
              </div>
            </div>
          </Row>
          <Row className="mt-3">
            <div className="col-lg-7 col-xl-8">
              {/* <div
                className={`${styles.tableHead} d-flex align-items-center justify-content-between pt-3 pb-3`}
              >
                <div className="d-flex align-items-center">
                  <Checkbox checked={false} onChange={console.log} className="mr-3" />
                  <span>whole cart</span>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="link"
                    className={`${styles.deleteBtn} d-flex align-items-center`}
                  >
                    <img src={greenBinImg} alt="" />
                    <span>Delete All</span>
                  </Button>
                  <Button variant="link">
                    <span>Delete Selected</span>
                  </Button>
                </div>
              </div> */}
              <div className="pb-3 pt-3" />
              <div className={`${styles.tableSection}`}>
                {/* <div className="d-flex align-items-center pt-3">
                  <div>
                    <Checkbox checked={false} onChange={console.log} className="mr-3" />
                  </div>
                  <div className={styles.projectName}>Store</div>
                </div> */}
                {checkout.lineItems.map(lineItem => {
                  return (
                    <LineItem
                      currencyCode={checkout.currencyCode}
                      key={lineItem.id.toString()}
                      line_item={lineItem}
                      removeLineItemInCart={removeLineItemInCart}
                      updateQuantityInCart={updateQuantityInCart}
                      isBought={boughtProducts.some(el => el === lineItem.variant.id)}
                    />
                  );
                })}
              </div>

              {/* <div className={`${styles.tableSection} ${styles.tableSectionDisabled}`}>
                <div className="d-flex align-items-center pt-3">
                  <div>
                    <Checkbox checked={false} onChange={console.log} className="mr-3" />
                  </div>
                  <div className={`${styles.projectName} ${styles.projectUntitled}`}>
                    Project: Untitled project
                  </div>
                </div>
                <div
                  className={`${styles.tableRow} d-flex align-items-center justify-content-between`}
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <Checkbox checked={false} onChange={console.log} className="mr-1" />
                    </div>
                    <div className={styles.image}>
                      <img src={exampleImg} alt="" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className={styles.elementName}>Microphone</div>
                        <button className={styles.deleteBtn}>
                          <img src={binImg} alt="" />
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-1">
                        <div className="d-flex align-items-start justify-content-between">
                          <span className={styles.type}>4 pins</span>
                          <div className={styles["Line-item__content-row"]}>
                            <div className={styles["Line-item__quantity-container"]}>
                              <button
                                type="button"
                                className={styles["Line-item__quantity-update"]}
                                onClick={() => this.decrementQuantity(line_item.id)}
                              >
                                -
                              </button>
                              <span className={styles["Line-item__quantity"]}>1</span>
                              <button
                                type="button"
                                className={styles["Line-item__quantity-update"]}
                                onClick={() => this.incrementQuantity(line_item.id)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <div className={styles.price}>$ 8.40</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.tableRow} d-flex align-items-center justify-content-between`}
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <Checkbox checked={false} onChange={console.log} className="mr-1" />
                    </div>
                    <div className={styles.image}>
                      <img src={exampleImg} alt="" />
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className={styles.elementName}>
                          Microphone with very very long name
                        </div>
                        <button className={styles.deleteBtn}>
                          <img src={binImg} alt="" />
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-1">
                        <div className="d-flex align-items-start justify-content-between">
                          <span className={styles.type}>4 pins</span>
                          <div className={styles["Line-item__content-row"]}>
                            <div className={styles["Line-item__quantity-container"]}>
                              <button
                                type="button"
                                className={styles["Line-item__quantity-update"]}
                                onClick={() => this.decrementQuantity(line_item.id)}
                              >
                                -
                              </button>
                              <span className={styles["Line-item__quantity"]}>1</span>
                              <button
                                type="button"
                                className={styles["Line-item__quantity-update"]}
                                onClick={() => this.incrementQuantity(line_item.id)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <div className={styles.price}>$ 5.49</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className={`${styles.summary} p-4`}>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5>Total amount to pay</h5>
                    {/* <p>
                      Including {checkout.currencyCode} {checkout.totalTax} in taxes
                    </p> */}
                    <p>Including taxes</p>
                  </div>
                  <div className={styles.totalPrice}>
                    {checkout.currencyCode} {checkout.totalPrice}
                  </div>
                </div>
                <Button
                  align="center"
                  padding="large"
                  className="w-100 mt-2"
                  onClick={openCheckout}
                >
                  CHECKOUT
                </Button>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
