import React, { forwardRef } from "react";
import { required } from "utilities";

/**
 * @param {Object} props
 * @param {"green" | "blue"} props.color
 */
export const MotionSensor = forwardRef(({ color = required("color"), style, className }, ref) => {
  const chosenColor = {
    green: "#00cf80",
    blue: "#5048e2",
  }[color];
  return (
    <svg
      ref={ref}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 124.046 78.174"
    >
      <g id="shield_motion-sensor-green" transform="translate(-334.918 -115.413)">
        <path
          id="Path_7349"
          data-name="Path 7349"
          d="M335.918,157.274l61.163,35.158L457.964,157.5V152.9l-61.6-36.483L335.918,153.04Z"
          fill="#f2f2f2"
        />
        <g id="Group_1575" data-name="Group 1575">
          <g id="Group_1574" data-name="Group 1574">
            <path
              id="Path_7350"
              data-name="Path 7350"
              d="M335.918,153.04v4.234l61.163,35.158,60.883-35.3V152.9"
              fill="none"
              stroke={chosenColor}
              strokeMiterlimit={10}
              strokeWidth={2}
            />
            <path
              id="Path_7351"
              data-name="Path 7351"
              d="M457.964,152.9l-61.6-36.483L335.918,153.04,397.082,188.2Z"
              fill="none"
              stroke={chosenColor}
              strokeLinejoin="round"
              strokeWidth={2}
            />
            <g id="Group_1567" data-name="Group 1567">
              <path
                id="Path_7352"
                data-name="Path 7352"
                d="M340.939,155.566l3.5-1.862L350,156.672l-3.448,2.136-1.1-.637s2.151-1.3.229-2.289a2.6,2.6,0,0,0-3.241.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7353"
                data-name="Path 7353"
                d="M349.97,160.854l3.5-1.863,5.553,2.968L355.58,164.1l-1.1-.637s2.151-1.3.23-2.289a2.6,2.6,0,0,0-3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7354"
                data-name="Path 7354"
                d="M359.2,166.206l3.5-1.862,5.553,2.968-3.447,2.136-1.1-.638s2.151-1.3.23-2.288a2.6,2.6,0,0,0-3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7355"
                data-name="Path 7355"
                d="M368.689,171.54l3.5-1.862,5.553,2.968-3.448,2.135-1.1-.637s2.151-1.3.23-2.288a2.6,2.6,0,0,0-3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7356"
                data-name="Path 7356"
                d="M377.746,176.9l3.5-1.862L386.8,178l-3.447,2.135-1.1-.637s2.151-1.3.23-2.288a2.6,2.6,0,0,0-3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7357"
                data-name="Path 7357"
                d="M386.981,182.1l3.5-1.862,5.553,2.968-3.447,2.136-1.1-.637s2.151-1.3.23-2.289a2.6,2.6,0,0,0-3.242.548Z"
                fill={chosenColor}
              />
            </g>
            <g id="Group_1568" data-name="Group 1568">
              <path
                id="Path_7358"
                data-name="Path 7358"
                d="M452.976,155.744l-3.5-1.862-5.553,2.968,3.447,2.135,1.1-.637s-2.151-1.3-.23-2.288a2.6,2.6,0,0,1,3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7359"
                data-name="Path 7359"
                d="M443.945,161.031l-3.5-1.862-5.553,2.968,3.447,2.135,1.1-.637s-2.151-1.3-.23-2.288a2.6,2.6,0,0,1,3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7360"
                data-name="Path 7360"
                d="M434.716,166.384l-3.5-1.862-5.554,2.967,3.448,2.136,1.1-.637s-2.151-1.3-.23-2.288a2.6,2.6,0,0,1,3.242.547Z"
                fill={chosenColor}
              />
              <path
                id="Path_7361"
                data-name="Path 7361"
                d="M425.226,171.718l-3.5-1.863-5.553,2.968,3.447,2.136,1.1-.637s-2.151-1.3-.23-2.289a2.6,2.6,0,0,1,3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7362"
                data-name="Path 7362"
                d="M416.169,177.072l-3.5-1.862-5.553,2.968,3.447,2.136,1.1-.637s-2.151-1.3-.23-2.289a2.6,2.6,0,0,1,3.242.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7363"
                data-name="Path 7363"
                d="M406.934,182.277l-3.5-1.862-5.553,2.968,3.447,2.135,1.1-.637s-2.151-1.3-.23-2.288a2.6,2.6,0,0,1,3.242.548Z"
                fill={chosenColor}
              />
            </g>
            <g id="Group_1569" data-name="Group 1569">
              <path
                id="Path_7364"
                data-name="Path 7364"
                d="M452.415,149.548l-3.5,1.862-5.554-2.968,3.448-2.135,1.1.637s-2.151,1.3-.23,2.288a2.6,2.6,0,0,0,3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7365"
                data-name="Path 7365"
                d="M443.357,144.134l-3.5,1.863-5.553-2.968,3.448-2.136,1.1.637s-2.151,1.3-.23,2.289a2.6,2.6,0,0,0,3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7366"
                data-name="Path 7366"
                d="M434.234,138.733l-3.5,1.862-5.553-2.968,3.447-2.136,1.1.638s-2.151,1.3-.23,2.288a2.6,2.6,0,0,0,3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7367"
                data-name="Path 7367"
                d="M424.816,133.412l-3.5,1.862-5.554-2.968,3.448-2.136,1.1.637s-2.151,1.3-.23,2.289a2.6,2.6,0,0,0,3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7368"
                data-name="Path 7368"
                d="M415.8,128.01l-3.5,1.863-5.554-2.968,3.448-2.136,1.1.637s-2.151,1.3-.23,2.289a2.6,2.6,0,0,0,3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7369"
                data-name="Path 7369"
                d="M406.745,122.5l-3.5,1.862-5.553-2.968,3.448-2.136,1.1.638s-2.15,1.3-.229,2.288a2.6,2.6,0,0,0,3.242-.548Z"
                fill={chosenColor}
              />
            </g>
            <g id="Group_1570" data-name="Group 1570">
              <path
                id="Path_7370"
                data-name="Path 7370"
                d="M340.395,150.247l3.5,1.862,5.553-2.968L346,147.005l-1.1.637s2.151,1.3.23,2.289a2.6,2.6,0,0,1-3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7371"
                data-name="Path 7371"
                d="M349.426,144.959l3.5,1.863,5.553-2.968-3.447-2.136-1.1.637s2.151,1.3.23,2.288a2.6,2.6,0,0,1-3.242-.547Z"
                fill={chosenColor}
              />
              <path
                id="Path_7372"
                data-name="Path 7372"
                d="M358.493,139.479l3.5,1.862,5.553-2.968-3.447-2.136-1.1.637s2.151,1.3.23,2.289a2.6,2.6,0,0,1-3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7373"
                data-name="Path 7373"
                d="M367.55,134.049l3.5,1.862,5.553-2.968-3.447-2.136-1.1.637s2.151,1.3.23,2.289a2.6,2.6,0,0,1-3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7374"
                data-name="Path 7374"
                d="M376.463,128.619l3.5,1.862,5.553-2.968-3.448-2.136-1.1.638s2.15,1.3.229,2.288a2.6,2.6,0,0,1-3.242-.548Z"
                fill={chosenColor}
              />
              <path
                id="Path_7375"
                data-name="Path 7375"
                d="M385.576,123.05l3.5,1.862,5.553-2.968-3.447-2.136-1.1.637s2.151,1.3.23,2.289a2.6,2.6,0,0,1-3.242-.548Z"
                fill={chosenColor}
              />
            </g>
            <g id="Group_1571" data-name="Group 1571">
              <path
                id="Path_7376"
                data-name="Path 7376"
                d="M380.991,151.986l13.7,8.113,15.985-10.036v-4.928l-13.7-7.752-15.985,9.855Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth={2}
              />
              <path
                id="Path_7377"
                data-name="Path 7377"
                d="M380.991,147.239l13.7,7.669,15.985-9.773"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth={2}
              />
              <line
                id="Line_373"
                data-name="Line 373"
                y1="5.191"
                transform="translate(394.692 154.908)"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth={2}
              />
              <ellipse
                id="Ellipse_1336"
                data-name="Ellipse 1336"
                cx="1.172"
                cy="0.642"
                rx="1.172"
                ry="0.642"
                transform="translate(384.627 146.253)"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7378"
                data-name="Path 7378"
                d="M368.882,143.333l7,4.026,3.185-2.013V142.7l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7379"
                data-name="Path 7379"
                d="M374.69,139.765l7,4.026,3.185-2.013v-2.644l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7380"
                data-name="Path 7380"
                d="M359.414,155.613l7,4.026,3.185-2.013v-2.644l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7381"
                data-name="Path 7381"
                d="M403.531,132.742l-7,4.026-3.185-2.013v-2.644l6.821-3.876,3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7382"
                data-name="Path 7382"
                d="M397.719,167.956l-7,4.026-3.185-2.013v-2.644l6.821-3.876,3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7383"
                data-name="Path 7383"
                d="M424.866,159.893l-7,4.026-3.185-2.013v-2.644l6.821-3.876,3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7384"
                data-name="Path 7384"
                d="M365.221,152.045l7,4.026,3.185-2.013v-2.644l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7385"
                data-name="Path 7385"
                d="M400.581,164.566l7,4.026,3.185-2.013v-2.644l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7386"
                data-name="Path 7386"
                d="M419.724,152.548l7,4.026,3.185-2.013v-2.644l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
              <path
                id="Path_7387"
                data-name="Path 7387"
                d="M425.777,149.19l7,4.026,3.185-2.013v-2.644l-6.821-3.876-3.365,2.013Z"
                fill="none"
                stroke={chosenColor}
                strokeMiterlimit={10}
                strokeWidth="0.553"
              />
            </g>
            <g id="Group_1572" data-name="Group 1572">
              <path
                id="Path_7388"
                data-name="Path 7388"
                d="M401.613,185.906v3.828l1.236-.646V185.2Z"
                fill={chosenColor}
              />
              <path
                id="Path_7389"
                data-name="Path 7389"
                d="M405.7,183.4v3.828l1.236-.646V182.7Z"
                fill={chosenColor}
              />
              <path
                id="Path_7390"
                data-name="Path 7390"
                d="M410.767,180.489v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7391"
                data-name="Path 7391"
                d="M414.851,177.985v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7392"
                data-name="Path 7392"
                d="M419.773,175.358v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7393"
                data-name="Path 7393"
                d="M423.857,172.854v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7394"
                data-name="Path 7394"
                d="M429.058,169.86v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7395"
                data-name="Path 7395"
                d="M433.142,167.356v3.828l1.236-.646V166.65Z"
                fill={chosenColor}
              />
              <path
                id="Path_7396"
                data-name="Path 7396"
                d="M438.37,164.566v3.828l1.236-.646V163.86Z"
                fill={chosenColor}
              />
              <path
                id="Path_7397"
                data-name="Path 7397"
                d="M442.454,162.062v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7398"
                data-name="Path 7398"
                d="M447.574,159.34v3.828l1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7399"
                data-name="Path 7399"
                d="M451.658,156.836v3.828l1.236-.646V156.13Z"
                fill={chosenColor}
              />
            </g>
            <g id="Group_1573" data-name="Group 1573">
              <path
                id="Path_7400"
                data-name="Path 7400"
                d="M392.006,185.688v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7401"
                data-name="Path 7401"
                d="M387.922,183.184v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7402"
                data-name="Path 7402"
                d="M382.853,180.272V184.1l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7403"
                data-name="Path 7403"
                d="M378.769,177.768V181.6l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7404"
                data-name="Path 7404"
                d="M373.847,175.141v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7405"
                data-name="Path 7405"
                d="M369.763,172.636v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7406"
                data-name="Path 7406"
                d="M364.562,169.643v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7407"
                data-name="Path 7407"
                d="M360.478,167.139v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7408"
                data-name="Path 7408"
                d="M355.25,164.349v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7409"
                data-name="Path 7409"
                d="M351.166,161.845v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7410"
                data-name="Path 7410"
                d="M346.046,159.123v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
              <path
                id="Path_7411"
                data-name="Path 7411"
                d="M341.962,156.618v3.828l-1.236-.646v-3.888Z"
                fill={chosenColor}
              />
            </g>
          </g>
          <path
            id="Path_7412"
            data-name="Path 7412"
            d="M335.918,157.274V153.7L397.082,188.2l60.331-34.981.552,4.287-60.883,34.929Z"
            fill={chosenColor}
            opacity="0.4"
          />
        </g>
      </g>
    </svg>
  );
});
