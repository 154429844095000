import React from "react";
import styles from "./DisabledOpacity.module.scss";

export const DisabledOpacity = ({
  disabled = true,
  children,
  className,
  bgColor = "ffffff",
  transparency = "50",
  title,
  kind = "clear",
}: Props) => {
  if (disabled) {
    const kindStyles = {
      clear: "",
      stripes: styles.stripes,
    }[kind];

    return (
      <div
        className={`${styles.disabled} ${kindStyles} ${className} position-relative`}
        title={title}
      >
        {children}
        <div style={{ background: `#${bgColor}${transparency}` }} />
      </div>
    );
  }
  return <div className={className}>{children}</div>;
};
