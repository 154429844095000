import React from "react";
import Media from "react-media";

const withMedia = options => Component => {
  return React.forwardRef((props, ref) => {
    return (
      <Media query={options.query} queries={options.queries}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {matches => <Component ref={ref} {...props} matches={matches} />}
      </Media>
    );
  });
};

export default withMedia;
