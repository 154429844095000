import React from "react";
import PT from "prop-types";

export const Spacer = ({ space, orientation = "vertical" }) => {
  if (orientation === "vertical") {
    return <div style={{ marginBottom: space - 1, width: "100%", height: 1 }} />;
  }
  if (orientation === "horizontal") {
    return <div style={{ marginRight: space, height: "100%" }} />;
  }
  throw new Error('orientation must be one of ["vertical", "horizontal"]');
};
Spacer.propTypes = {
  space: PT.number.isRequired,
};
