import React from "react";
import styles from "./PopupTemplate.module.scss";
import { Link } from "react-router-dom";
import illustration from "assets/images/ilustracja_screen2small.svg";
import cx from "classnames";

const PopupTemplate = () => {
  return (
    <div className={styles.popupWrapper}>
      <div className={styles.contentWrapper}>
        <div className="mb-3">
          <div className={styles.headline}>This app can't run on screen that small</div>
          <p className={styles.text}>Give it a try on your desktop</p>
        </div>
        <div>
          <img src={illustration} alt=""  className={styles.illustration} />
        </div>
        <Link to="/" className={cx("mt-3", styles.linkGreen)}>
          Go back home
        </Link>
      </div>
    </div>
  );
};

export default PopupTemplate;
