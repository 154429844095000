import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { deleteMyHistory } from "api/rest";
import styles from "./CLearHistoryBtn.module.scss";

export const ClearHistoryBtn = ({ deleteCallback, nothingToDelete }) => {
  const [isFetching, setFetching] = useState(false);

  async function deleteHistory() {
    if (isFetching) return;
    setFetching(true);
    const [, error] = await deleteMyHistory();
    setFetching(false);
    if (!error) {
      deleteCallback();
    }
  }

  return (
    <Button
      variant="light"
      className={styles.backLink}
      onClick={deleteHistory}
      disabled={isFetching || nothingToDelete}
    >
      <span className={styles.backLinkText}>Clear history</span>
    </Button>
  );
};
