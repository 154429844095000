import { gql } from "apollo-boost";

export const PRODUCTS = gql`
  query Products($search: String!) {
    products(first: 100, query: $search) {
      edges {
        node {
          id
          title
          tags
          handle
          productType
          images(first: 5) {
            edges {
              node {
                id
                src
              }
            }
          }
          options {
            values
            name
          }
          variants(first: 10) {
            edges {
              node {
                title
                id
                priceV2 {
                  amount
                }
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
          # metafield(namespace: "global" key: "serialNumber") {
          metafields(first: 10) {
            edges {
              node {
                value
                description
                key
                namespace
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT = gql`
  query Product($handle: String!, $skip: Boolean!) {
    productByHandle(handle: $handle) @skip(if: $skip) {
      id
      descriptionHtml
      title
      tags
      productType
      handle
      images(first: 5) {
        edges {
          node {
            id
            src
          }
        }
      }
      options {
        values
        name
      }
      variants(first: 10) {
        edges {
          node {
            image {
              originalSrc
            }
            title
            id
            priceV2 {
              amount
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
      metafields(first: 10) {
        edges {
          node {
            value
            description
            key
            namespace
          }
        }
      }
    }
  }
`;

export const CUSTOMER = gql`
  query Customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      displayName
      email
      lastIncompleteCheckout {
        id
      }
      orders(first: 100) {
        edges {
          node {
            id
            lineItems(first: 100) {
              edges {
                node {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CHECKOUT_STATUS = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        id
        email
        completedAt
      }
    }
  }
`;

export const ABOUT_US = gql`
  query AboutUs {
    blogByHandle(handle: "news") {
      articleByHandle(handle: "about-us") {
        contentHtml
      }
    }
  }
`;
