import React, { useContext } from "react";
import { useUrlQuery } from "components/QueryProvider";
import { useMainSearch } from "api/restApiHooks";
import ModuleTile from "components/ModuleTile";
import { useProducts, shopContext } from "App";
import cx from "classnames";
import Navbar from "components/Navbar";
import { BackArrow } from "components/BackArrow/BackArrow";
import styles from "./SearchResults.module.scss";

function makeShopifyProductId(externalId) {
  return btoa("gid://shopify/Product/" + externalId);
}

export const SearchResults = ({ location }) => {
  const { query } = useUrlQuery();
  const [shopifyProducts, { fetching }] = useProducts();

  const preloadedResults = location.state;
  const [mainSearchResults, { inProgress }] = useMainSearch(query.phrase, null, {
    skip: Boolean(preloadedResults),
  });
  const results = preloadedResults ?? mainSearchResults ?? { users: [], devkits: [], products: [] };
  const richProducts = results.products
    .map(product => {
      const id = makeShopifyProductId(product.externalId);
      const shopifyProduct = shopifyProducts.find(prod => id === prod.id);
      return shopifyProduct;
    })
    .filter(Boolean);

  const richDevkits = results.devkits
    .map(product => {
      const id = makeShopifyProductId(product.externalId);
      const shopifyProduct = shopifyProducts.find(prod => id === prod.id);
      return shopifyProduct;
    })
    .filter(Boolean);

  const { client, addVariantToCart, shop, tagsByProduct } = useContext(shopContext);

  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div style={{ paddingTop: 120 }} className={cx({ "d-hide": inProgress || fetching })}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <BackArrow />
              <h1 className={styles.h1}>Search results for "{query.phrase}"</h1>
              <span className={cx("ginora", styles.title, { "d-none": !richProducts.length })}>
                Shields/Kits
              </span>
              <div className={styles.tileRow}>
                {richProducts.map(mod => (
                  <ModuleTile
                    key={mod.id}
                    mod={mod}
                    tags={tagsByProduct[mod.id]}
                    client={client}
                    addVariantToCart={addVariantToCart}
                    shop={shop}
                    currentUrl={location.pathname + location.search}
                    selectedVariant={query.interface}
                  />
                ))}
              </div>
              <span className={cx("ginora", styles.title, { "d-none": !richDevkits.length })}>
                Kits
              </span>
              <div className={styles.tileRow}>
                {richDevkits.map(mod => (
                  <ModuleTile
                    key={mod.id}
                    mod={mod}
                    tags={tagsByProduct[mod.id]}
                    client={client}
                    addVariantToCart={addVariantToCart}
                    shop={shop}
                    currentUrl={location.pathname + location.search}
                    selectedVariant={query.interface}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
