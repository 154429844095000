import React from "react";
import cx from "classnames";
import styles from "./Timeline.module.scss";

const noop = () => {};

/**
 *
 * @param {Object} props
 * @param {"idea" | "prototype" | "mvp"  |"massProduction"} props.stage
 */
export const Timeline = ({ stage, scroll = noop }) => {
  return (
    <div className={styles.timeline}>
      <div
        className={cx(styles.timelineDot, {
          [styles.active]: stage === "idea" || stage === undefined,
        })}
      >
        &nbsp;
        <span className={styles.dotTitle}>Idea</span>{" "}
        <button
          type="button"
          className={cx(styles.dot)}
          onClick={() => scroll(50)}
          aria-label="idea"
        />
        &nbsp;
      </div>
      <div className={cx(styles.timelineDot, { [styles.active]: stage === "prototype" })}>
        <span>1 - 9 units - $$$</span>
        <span className={styles.dotTitle}>Prototype</span>
        <button
          type="button"
          className={cx(styles.dot)}
          onClick={() => scroll(7800)}
          aria-label="Prototype"
        />
        2 weeks
      </div>
      <div className={cx(styles.timelineDot, { [styles.active]: stage === "mvp" })}>
        <span>10 - 100 units - $$</span>
        <span className={styles.dotTitle}>MVP</span>
        <button
          type="button"
          className={cx(styles.dot)}
          onClick={() => scroll(19650)}
          aria-label="MVP"
        />
        4 weeks
      </div>
      <div className={cx(styles.timelineDot, { [styles.active]: stage === "massProduction" })}>
        <span>100 - 1000 units - $</span>
        <span className={styles.dotTitle}>Mass Production</span>
        <button
          type="button"
          className={cx(styles.dot)}
          onClick={() => scroll(22160)}
          aria-label="Mass Production"
        />
        10 weeks
      </div>
      <div className={styles.timelineArrow} />
    </div>
  );
};
