import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Jumbotron from "react-bootstrap/Jumbotron";
import Navbar from "components/Navbar";
import { Spacer } from "components/Spacer";
import cx from "classnames";
import { useDomRect } from "hooks";
import { Link } from "react-router-dom";
import ModulesCarousel from "./ModulesCarousel";
import FooterBanner from "./FooterBanner";
import styles from "./styles.module.scss";

/**
 * @param {number} distanceFromTop
 */
function getNavbarTheme(distanceFromTop) {
  const headerAlignedWithTheJumbotronTextLine = Math.ceil(Math.abs(distanceFromTop) / 4) > 35;

  if (headerAlignedWithTheJumbotronTextLine) {
    return "light";
  }
  return "dark";
}
const Home = () => {
  const domRect = useDomRect();
  return (
    <div>
      <Navbar theme={getNavbarTheme(domRect.y)} />
      <Jumbotron className={styles.jumbotron}>
        <Container>
          <Row>
            <div className="col-sm-12 align-items-center flex-column d-flex">
              <div className={styles.jumbotronText}>
                Modular electronics
                <div className={styles.greenSpan}>with integrated</div>
                <div className={styles.greenSpan}>code editor</div>
              </div>
              <div className="mt-4" />
              <Link to="/how-it-works" className={cx("btn btn-secondary", styles.mainBtn)}>
                SEE HOW IT WORKS
              </Link>
            </div>
          </Row>
        </Container>
      </Jumbotron>
      <Spacer space={-95} />
      <Container>
        <Row>
          <div className="col-sm-12">
            <ModulesCarousel title="Shields" search="product_type:'Shield'" />
          </div>
        </Row>
      </Container>
      <FooterBanner />
    </div>
  );
};

Home.propTypes = {};
export default Home;
