import React, { Component } from "react";
import PT from "prop-types";
import binImg from "assets/images/binB.svg";
import { QuantityCounter } from "components/QuantityCounter";
// import { Checkbox } from "../../Checkbox";
import styles from "./LineItem.module.scss";

class LineItem extends Component {
  decrementQuantity = lineItemId => {
    const { line_item, updateQuantityInCart } = this.props;
    const updatedQuantity = line_item.quantity - 1;
    updateQuantityInCart(lineItemId, updatedQuantity);
  };

  incrementQuantity = lineItemId => {
    const { line_item, updateQuantityInCart } = this.props;
    const updatedQuantity = line_item.quantity + 1;
    updateQuantityInCart(lineItemId, updatedQuantity);
  };

  changeQuantity = (lineItemId, value) => {
    const { updateQuantityInCart } = this.props;
    const updatedQuantity = value;
    updateQuantityInCart(lineItemId, updatedQuantity);
  };

  render() {
    const { line_item, removeLineItemInCart, currencyCode, isBought } = this.props;
    return (
      <li className="d-flex mb-2">
        {/* <Checkbox checked={false} onChange={console.log} /> */}
        <div className={styles["Line-item"]}>
          <div className={styles["Line-item__img"]}>
            {line_item.variant?.image ? (
              <img src={line_item.variant.image.src} alt={`${line_item.title} product shot`} />
            ) : null}
            {isBought ? (
              <div style={{ position: "absolute", background: "#ffffff80" }}>already bought</div>
            ) : (
              ""
            )}
          </div>
          <div className={styles["Line-item__content"]}>
            <div className={styles["Line-item__content-row"]}>
              <span className={styles["Line-item__title"]}>{line_item.title}</span>
              <button
                type="button"
                className={styles["Line-item__remove"]}
                onClick={() => removeLineItemInCart(line_item.id)}
              >
                <img src={binImg} alt="" />
              </button>
            </div>
            <div className={styles["Line-item__content-row"]}>
              <div className="d-flex align-items-start">
                {line_item.variant?.title !== "Default Title" && (
                  <div className={styles["Line-item__variant-title"]}>
                    {line_item.variant?.title}
                  </div>
                )}
                <QuantityCounter
                  increment={() => this.incrementQuantity(line_item.id)}
                  decrement={() => this.decrementQuantity(line_item.id)}
                  change={value => this.changeQuantity(line_item.id, value)}
                  value={line_item.quantity}
                />
              </div>
              <span className={styles["Line-item__price"]}>
                {currencyCode} {(line_item.quantity * line_item.variant?.price).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

LineItem.propTypes = {
  line_item: PT.shape().isRequired,
  updateQuantityInCart: PT.func.isRequired,
  removeLineItemInCart: PT.func.isRequired,
};
export default LineItem;
