import React, { useContext, useMemo } from "react";
import { Checkbox } from "components/Checkbox";
import { shopContext } from "App";
import { useUrlQuery } from "components/QueryProvider";
import { Spacer } from "components/Spacer";
import Button from "react-bootstrap/Button";
import { Radio, RadioGroup } from "components/Radio";
import styles from "./FilterAside.module.scss";
import { Tag } from "./Tag";

// UTILS
function stringParamToArray(param) {
  return (param ?? "").split(",").filter(Boolean);
}
// function formatToShopifyQueryFilter(arr) {
//   return arr.map(el => `'${el}'`).join(" OR ");
// }

// MAIN COMPONENT

export const FilterAside = () => {
  const { tags, shopInfo } = useContext(shopContext);
  const { query, updateQuery } = useUrlQuery();
  const queryTags = useMemo(() => stringParamToArray(query.tags), [query.tags]);
  const queryProductTypes = useMemo(() => {
    const arr = stringParamToArray(query.productTypes);
    if (arr.length) return arr;
    return shopInfo?.productTypes ?? [];
  }, [query.productTypes, shopInfo]);

  const toggleTag = name => {
    if (queryTags.includes(name)) {
      updateQuery({ tags: queryTags.filter(el => el !== name).join(",") });
    } else {
      updateQuery({ tags: [...queryTags, name].join(",") });
    }
  };
  const toggleProductType = name => {
    if (queryProductTypes.includes(name)) {
      updateQuery({ productTypes: queryProductTypes.filter(el => el !== name).join(",") });
    } else {
      updateQuery({ productTypes: [...queryProductTypes, name].join(",") });
    }
  };
  const changeInterface = value => {
    updateQuery({ interface: value });
  };
  const clearFilter = name => {
    updateQuery({ [name]: "" });
  };

  // React.useEffect(() => {
  //   const tag = queryTags.length ? `tag:${formatToShopifyQueryFilter(queryTags)} ` : "";
  //   const productType = queryProductTypes.length
  //     ? `${formatToShopifyQueryFilter(queryProductTypes)}`
  //     : "*";
  //   fetchProducts(`${tag}product_type:${productType}`);
  // }, [fetchProducts, queryProductTypes, queryTags]);
  const a = queryProductTypes.length ? queryProductTypes : shopInfo?.productTypes ?? [];
  return (
    <aside className={`${styles.aside} `}>
      {/* ${styles.asideOpen} */}
      <h1 className={styles.heading}>Products</h1>
      <button className={styles.filtersBtn}>Filters</button>
      <span className={styles.filterTitle}>Categories</span>
      <div>
        {shopInfo &&
          shopInfo.productTypes.filter(Boolean).map(type => (
            <div key={type}>
              <Checkbox
                variant="outline"
                checked={a.includes(type)}
                onChange={() => toggleProductType(type)}
                label={type === "Shield" ? "Shields" : type}
              />
            </div>
          ))}
      </div>
      <Spacer space={30} />
      <span className={styles.filterTitle}>Functions</span>
      <Spacer space={15} />
      <div>
        {/* <Tag name="myShields" toggle={toggleTag} active={queryTags.includes("myShields")} /> */}
        {tags.map(tag => (
          <Tag key={tag} name={tag} toggle={toggleTag} active={queryTags.includes(tag)} />
        ))}
        <Button
          variant="outline-light"
          className={styles.clearBtn}
          disabled={queryTags.length === 0}
          onClick={() => clearFilter("tags")}
        >
          Clear all
        </Button>
      </div>
      <Spacer space={30} />
      <span className={styles.filterTitle}>Interface</span>
      <div>
        {shopInfo && (
          <RadioGroup
            onChange={changeInterface}
            value={query.interface ?? "4 pin"}
            name="interface"
          >
            <Radio value="4 pin" label="4 pin" />
            <Radio value="21 pin" label="21 pin" />
          </RadioGroup>
        )}
      </div>
    </aside>
  );
};
