import React from "react";
import ReactDOM from "react-dom";
// import Client from "shopify-buy";
import Client from "shopify-buy/index.unoptimized.umd";
import { AppProviderWrapper } from "AppProviderWrapper";
import "@reach/menu-button/styles.css";
import "@reach/dialog/styles.css";
import "./app.css";
import "overwrites.scss";
import "index.scss";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import version from "./VERSION";

window.appVersion = version;

export const shopifyShopUrl = "electronsquare.myshopify.com";
export const shopifyAccessToken = "6f2ba76a8a891b4f89e2a762ad33bf31";

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: "https://" + shopifyShopUrl + `/api/2020-04/graphql.json`,
  headers: {
    "Content-Type": "application/graphql",
    "X-Shopify-Storefront-Access-Token": shopifyAccessToken,
  },
});

// Shopify example shop
// const client = Client.buildClient({
//   storefrontAccessToken: "dd4d4dc146542ba7763305d71d1b3d38",
//   domain: "graphql.myshopify.com",
// });

const client = Client.buildClient({
  storefrontAccessToken: shopifyAccessToken,
  domain: shopifyShopUrl,
});

const apolloClient = new ApolloClient({
  cache,
  link,
});

ReactDOM.render(
  <AppProviderWrapper client={client} apolloClient={apolloClient} />,
  document.getElementById("root"),
);
