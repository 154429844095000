import React, { useState, useMemo } from "react";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
// import fourPinsImg from "assets/images/icon_4pin_19px.svg";
import twentyOnePinsImg from "assets/images/icon_21pin_19px.svg";
import Button from "react-bootstrap/Button";
import binImg from "assets/images/bin.png";
import plusImg from "assets/images/plus.svg";
import checkImg from "assets/images/checkS.svg";
import deniedImg from "assets/images/refuse.svg";
// import eyeImg from "assets/images/eye.svg";
import { useProjects } from "api/restApiHooks";
import { DisabledOpacity } from "components/DisabledOpacity";
import { deleteProject, patchProject, patchDraftDevkit } from "api/rest";
import { useAuth } from "App";
import { useAlertModal } from "components/Alert/Alert";
import { useToastr } from "components/Toastr/useToastr";
import immer from "immer";
import { BackArrow } from "components/BackArrow/BackArrow";
import { Form } from "./Form";
import { ConfirmModalBody } from "./ConfirmModalBody";
import styles from "./MyProjects.module.scss";

function reducer(state, action) {
  switch (action.type) {
    case "DELETE": {
      return state.filter(el => el.id !== action.payload);
    }
    case "STOP_SHARING": {
      return immer(state, draft => {
        const draftProject = draft.find(project => project.id === action.payload);
        if (draftProject) {
          draftProject.moderationStatus = "stopped";
        }
      });
    }
    case "SHARE_AGAIN": {
      return immer(state, draft => {
        const draftProject = draft.find(project => project.id === action.payload);
        if (draftProject) {
          draftProject.status = "in-moderation";
        }
      });
    }
    default:
      return state;
  }
}

function sortProjects(projects) {
  return projects.reduce(
    (acc, project) => {
      switch (project.status) {
        case "working":
          acc.working.push(project);
          break;
        case "in-moderation":
          acc.inModeration.push(project);
          break;
        case "shared":
          acc.shared.push(project);
          break;
        default:
          acc.unknown.push(project);
      }
      return acc;
    },
    { inModeration: [], working: [], shared: [], unknown: [] },
  );
}

export const MyProjects = () => {
  const [projects, { triggerReload, dispatch }] = useProjects(undefined, reducer);
  const [isFormOpen, setFormOpen] = useState(false);
  const auth = useAuth();
  const alert = useAlertModal();
  const toastr = useToastr();

  function handleDeleteProject(id) {
    function del() {
      return new Promise(async resolve => {
        const [, error] = await deleteProject(id, {
          keycloakAccessToken: auth.keycloakAccessToken,
          keycloakRefreshToken: auth.keycloakRefreshToken,
        });
        resolve();
        if (error) {
          toastr.open({ type: "failure", text: error.message || "Failed to delete project." });
        } else {
          dispatch({ type: "DELETE", payload: id });
          toastr.open({ type: "success", text: "Project was deleted successfully." });
        }
      });
    }
    alert.open({
      body: ({ close }) => <ConfirmModalBody close={close} del={del} />,
      heading: "Delete project",
    });
  }

  async function stopSharingProject(project, { setLoading }) {
    setLoading(true);
    const [, error] = await patchProject({ moderationStatus: "stopped" }, project.id);
    setLoading(false);
    if (!error) {
      dispatch({ type: "STOP_SHARING", payload: project.id });
    } else {
      toastr.open({ type: "failure", text: "Failed to stop the project" });
    }
  }

  async function shareProjectAgain(project, { setLoading }) {
    setLoading(true);
    const [, error] = await patchDraftDevkit(project.draftDevkit, {
      status: "in-moderation",
      moderationVisible: "visible",
    });
    setLoading(false);
    if (!error) {
      dispatch({ type: "SHARE_AGAIN", payload: project.id });
    } else {
      toastr.open({ type: "failure", text: "Failed to share project" });
    }
  }

  const sortedProjects = useMemo(() => sortProjects(projects), [projects]);
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div style={{ paddingTop: 120 }}>
        <Container>
          <Row className="pl-3 pr-3">
            <div className="col-sm-12 pt-3">
              <BackArrow />
              <div className="d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
                <h1 className={styles.heading}>My Projects</h1>
                {/* <div className={styles.text}>
                  Your project can be shared public when it fits our Quality Standards. You can find
                  out more about it{" "}
                  <Link to="/" className={styles.linkGreen}>
                    here
                  </Link>
                </div> */}
              </div>
            </div>
          </Row>

          <Row className="pl-3 pr-3 mt-3">
            <div className="col-12">
              <h2 className={styles.subHeading}>Working projects</h2>
            </div>
            <div className="col-12 ml-n2 mr-n2 d-flex flex-wrap">
              <div
                className={`${styles.tileXs} ${styles.tileSm} ${styles.tileMd} ${styles.tileLg} ${styles.tile}`}
              >
                <button
                  className={styles.addProjectBtn}
                  type="button"
                  onClick={() => setFormOpen(true)}
                >
                  <img src={plusImg} alt="" />
                  <span>New Project</span>
                </button>
              </div>
              {sortedProjects.working.map(project => (
                <ProjectTile
                  key={project.id}
                  project={project}
                  handleDeleteProject={handleDeleteProject}
                />
              ))}
            </div>
          </Row>
          {/* <Row className="pl-3 pr-3 mt-3">
            <div className="col-12">
              <h2 className={styles.subHeading}>Projects in moderation</h2>
            </div>
            <div className="col-12 ml-n2 mr-n2 d-flex flex-wrap">
              {sortedProjects.inModeration.map(project => (
                <ProjectTile
                  key={project.id}
                  project={project}
                  handleDeleteProject={handleDeleteProject}
                  stopSharing={stopSharingProject}
                />
              ))}
            </div>
          </Row> */}
          {/* <Row className="pl-3 pr-3 mt-3">
            <div className="col-12">
              <h2 className={styles.subHeading}>Shared projects</h2>
            </div>
            <div className="col-12 ml-n2 mr-n2 d-flex flex-wrap">
              {sortedProjects.shared.map(project => (
                <ProjectTile
                  key={project.id}
                  project={project}
                  handleDeleteProject={handleDeleteProject}
                  shareAgain={shareProjectAgain}
                  stopSharing={stopSharingProject}
                />
              ))}
            </div>
          </Row> */}
          {sortedProjects.unknown.length > 0 && (
            <Row className="pl-3 pr-3 mt-3">
              <div className="col-12">
                <h2 className={styles.subHeading}>Unknown / broken projects</h2>
              </div>
              <div className="col-12 ml-n2 mr-n2 d-flex flex-wrap">
                {sortedProjects.unknown.map(project => (
                  <ProjectTile
                    key={project.id}
                    project={project}
                    handleDeleteProject={handleDeleteProject}
                    shareAgain={shareProjectAgain}
                    stopSharing={stopSharingProject}
                  />
                ))}
              </div>
            </Row>
          )}
        </Container>
        <Form close={() => setFormOpen(false)} isOpen={isFormOpen} submitCallback={triggerReload} />
      </div>
    </>
  );
};

const ProjectTile = ({ project, handleDeleteProject, stopSharing, shareAgain }) => {
  const [isLoading, setLoading] = useState(false);
  const asyncStatusActions = { setLoading };
  const { user } = project;
  return (
    <div
      key={project.id}
      className={`${styles.tileXs} ${styles.tileSm} ${styles.tileMd} ${styles.tileLg} ${styles.tile}`}
    >
      <div className={styles.imageBox}>
        <img src={twentyOnePinsImg} alt="" className={styles.image} />
        {/* {project.status === "shared" && (
          <div className={styles.views}>
            <span>567</span>
            <img src={eyeImg} alt="" />
          </div>
        )} */}
      </div>
      <button
        className={styles.deleteBtn}
        type="button"
        onClick={() => handleDeleteProject(project.id)}
      >
        <img src={binImg} alt="" />
      </button>
      <div className={`${styles.body} d-flex flex-column flex-grow-1 justify-content-between`}>
        {user && (
          <div className="mt-n3 pb-2 d-flex align-items-end">
            <div className={styles.userAvatar}>
              <img src={user.avatar || ""} alt="" />
            </div>
            <div className={styles.userName} title={`${user.firstName} ${user.lastName}`}>
              {user.firstName} {user.lastName}
            </div>
          </div>
        )}
        <div className={styles.title}>{project.name}</div>
        <DisabledOpacity>
          {project.status === "working" && (
            <div className={`${styles.text} ${styles.hintText}`}>
              To share{" "}
              <Link to="/" className={styles.linkGreen}>
                test your code
              </Link>{" "}
              on the device
            </div>
          )}
          {project.status === "shared" && (
            <div className={`${styles.text} ${styles.hintText}`}>
              <div
                className={`${styles.detailsWrapper} d-flex align-items-center justify-content-between`}
              >
                <div />
                {/* <span className={styles.price}>$ 56.90</span>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>Under verification</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <img className="mr-2" src={deniedImg} alt="" />
                        <span>Sharing denied</span>
                      </div>
                      */}
                {project.moderationStatus === "accepted" && (
                  <div className="d-flex align-items-center justify-content-between">
                    <img className="mr-2" src={checkImg} alt="" />
                    <span>Shared</span>
                  </div>
                )}
                {project.moderationStatus === "denied" && (
                  <div className="d-flex align-items-center justify-content-between">
                    <img className="mr-2" src={deniedImg} alt="" />
                    <span>Sharing denied</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </DisabledOpacity>
        {/* <div>
          <div className={styles.label}>Interface:</div>
          <div
            className={`${styles.typeWrapper} d-flex align-items-center justify-content-between`}
          >
            <span className={styles.type}>{project.interface} pins</span>
            <img src={{ 4: fourPinsImg, 21: twentyOnePinsImg }[project.interface]} alt="" />
          </div>
        </div> */}
        <div
          className="d-flex align-items-center justify-content-between"
          style={project.status === "shared" ? { margin: "0 -10px" } : undefined}
        >
          {project.status !== "in-moderation" && (
            <Button
              href={project.workspaceUrl}
              target="_blank"
              rel="noopener noreferrer"
              align="stretch"
              className={`${styles.cartBtn} w-100 ${project.status === "shared" ? "" : "mr-2"}`}
              as="a"
            >
              Edit
            </Button>
          )}
          {/* {project.status === "working" && (
            <Button
              as={Link}
              className={styles.cartBtn}
              variant="outline-primary"
              to={{
                pathname: `/devkit/create/${project.id}/${project.draftDevkit}/${project.devkit}`,
              }}
              disabled={isLoading}
            >
              <div className={styles.btnImg} />
              <span>Share</span>
            </Button>
          )} */}

          {project.status === "shared" && (
            <>
              {/* <Spacer orientation="horizontal" space={10} /> */}
              <Button
                as={Link}
                className={styles.cartBtn}
                variant="outline-primary"
                to={{
                  pathname: `/devkit/create/${project.id}/${project.draftDevkit}/${project.devkit}`,
                }}
              >
                <div className={styles.btnImg} />
                <span>Update</span>
              </Button>
            </>
          )}
          {project.moderationStatus !== "stopped" && project.status === "shared" && (
            <Button
              className={styles.cartBtn}
              variant="outline-primary"
              onClick={() => stopSharing(project, asyncStatusActions)}
              disabled={isLoading}
            >
              <div className={styles.btnImg} />
              <span>Stop sharing</span>
            </Button>
          )}
          {project.moderationStatus === "stopped" && project.status === "shared" && (
            <Button
              className={styles.cartBtn}
              variant="outline-primary"
              onClick={() => shareAgain(project, asyncStatusActions)}
              disabled={isLoading}
            >
              <div className={styles.btnImg} />
              <span>Share again</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
