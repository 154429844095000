import React, { forwardRef } from "react";
import pinMaskImg from "assets/images/howItWorks/pin_mask.png";
import pinMask2Img from "assets/images/howItWorks/pin_mask2.png";

export const PinMask2 = forwardRef((_, ref) => {
  return (
    <div
      ref={ref}
      className="position-absolute"
      style={{
        top: -43,
        left: 107,
        zIndex: 2,
        width: 63.115,
        height: 50,
      }}
    >
      <img src={pinMaskImg} alt="" style={{ position: "absolute" }} />
      <img src={pinMask2Img} alt="" style={{ position: "absolute", top: 0, left: 85 }} />
      <img src={pinMaskImg} alt="" style={{ position: "absolute", top: 14, left: 24 }} />
      <img src={pinMask2Img} alt="" style={{ position: "absolute", top: 14, left: 61 }} />
    </div>
  );
});
