import { useMemo } from "react";

export function useProductsSearch(query) {
  function stringParamToArray(param) {
    return (param ?? "").split(",").filter(Boolean);
  }
  function formatToShopifyQueryFilter(arr) {
    return arr.map(el => `'${el}'`).join(" OR ");
  }

  const queryTags = useMemo(() => stringParamToArray(query.tags), [query.tags]);
  const queryProductTypes = useMemo(() => stringParamToArray(query.productTypes), [
    query.productTypes,
  ]);

  const tag = queryTags.length ? `tag:${formatToShopifyQueryFilter(queryTags)} ` : "";
  const productType = queryProductTypes.length
    ? `${formatToShopifyQueryFilter(queryProductTypes)}`
    : "*";
  const search = `${tag}product_type:${productType}`;
  return search;
}
