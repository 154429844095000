import checkIcon from "assets/images/check.svg";
import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import cx from "classnames";
import styles from "./Greeting.module.scss";

export const Greeting = ({ style, close }) => {
  return (
    <div className={cx(styles.container, "text-center")} style={style}>
      <img src={checkIcon} alt="" className="mx-auto mb-3" height={35} />
      <h1 className={cx(styles.heading)}>
        Welcome to <br />
        Electron Square!
      </h1>
      <p>You are now a registered Electron Square user.</p>
      <p>
        There's nothing in your cart right now. You may want to have a look at our's{" "}
        <Link className="link-green" to="/products">
          Products Catalogue
        </Link>{" "}
        or start a new project with{" "}
        <a href="https://editor.ide.electronsquare.com" className="link-green">
          E² app
        </a>
        .
      </p>
      <p>
        As registered user you have now access to projects archive in{" "}
        <Link to="/my-projects" className="link-green">
          My Projects
        </Link>{" "}
        site.
      </p>
      <p>Enjoy!</p>
      <div>
        <Button onClick={close}>START EXPLORING E²</Button>
      </div>
    </div>
  );
};
