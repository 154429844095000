import React from "react";
import { DialogOverlay } from "@reach/dialog";
import closeIcon from "assets/images/close.svg";
import Button from "react-bootstrap/Button";
// import styles from "../LoginTemplate.module.scss";
import { Radio } from "components/Radio";
import styles from "./NewProjectModal.module.scss";
import cx from "classnames";

const NewProjectModal = () => {
  return (
    <DialogOverlay>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.headline}>New Project</div>
              <button className={styles.closeModalBtn}>
                <img src={closeIcon} alt="" />
              </button>
            </div>
            <form action="">
              <div className={`${styles.validationMessage}`}>Invalid credentials</div>
              <div className={cx("pb-2", styles.inputGroup)}>
                <label className={styles.label} htmlFor="">
                  Project name
                </label>
                <input className={styles.textInput} type="text" value="Untitled Project-3" />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.label} htmlFor="">
                  Interface
                </label>
                <div>
                  <Radio value="4pin" label="4 pin" />
                </div>
                <div>
                  <Radio value="21pin" label="21 pin" />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.label} htmlFor="">
                  Programming language
                </label>
                <div>
                  <Radio value="C++" label="C++" />
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <div className={styles.disabled}>
                      <Radio value="MicroPython" label="MicroPython" />
                    </div>
                    <span className={styles.info}>comming soon</span>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <Button align="center" padding="large">
                  CREATE PROJECT
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DialogOverlay>
  );
};

export default NewProjectModal;
