import React from "react";
import { useHistory } from "react-router";
import arrowImg from "assets/images/arrowGreen.png";
import styles from "./BackArrow.module.scss";

export const BackArrow = () => {
  const history = useHistory();
  return (
    <div className={styles.back}>
      <button type="button" className={styles.backLink} onClick={() => history.goBack()}>
        <img src={arrowImg} alt="Back" />
        <span className={styles.backLinkText}>Back</span>
      </button>
    </div>
  );
};
