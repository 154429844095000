import React, { useContext } from "react";
import PT from "prop-types";
import { withRouter } from "react-router-dom";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import queryString from "query-string";
import Scrollbar from "react-scrollbars-custom";
import { shopContext } from "App";
import closeImg from "assets/images/close.svg";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import darculaTheme from "react-syntax-highlighter/dist/esm/styles/hljs/darcula";
import { PRODUCT } from "api/queries";
import { useQuery } from "@apollo/react-hooks";
import { Gallery } from "components/Gallery";
import { productSelector } from "api/selectors";
// import { ProductDetails } from "components/ProductDetails";
import styles from "./ModuleDetailsModal.module.scss";

const ModalWrapper = withRouter(({ location, history }) => {
  const query = queryString.parse(location.search);
  const { client, addVariantToCart } = useContext(shopContext);
  const { data } = useQuery(PRODUCT, {
    variables: { handle: query.moduleModal, skip: !query.moduleModal },
  });

  const product = productSelector(data?.productByHandle);
  if (!product || !query.moduleModal || query.moduleModal !== product.handle) return null;
  return (
    <ModuleDetailsModal
      product={product}
      history={history}
      addVariantToCart={addVariantToCart}
      client={client}
      query={query}
    />
  );
});

const ModuleDetailsModal = ({ history, product }) => {
  // const selectVariant = ({ name, value }) => {
  //   const newSelectedOptions = {
  //     ...state.selectedOptions,
  //     [name]: value,
  //   };
  //   const newSelectedVariant = client.product.helpers.variantForOptions(
  //     product,
  //     newSelectedOptions,
  //   );

  //   setState({
  //     ...state,
  //     selectedOptions: newSelectedOptions,
  //     selectedVariant: newSelectedVariant,
  //   });
  // };

  // const { selectedVariant } = state;

  const close = () => history.replace({ moduleModal: "" });
  // const handleAddToCart = () => {
  //   addVariantToCart(selectedVariant.id, 1);
  //   close();
  // };

  if (!product) {
    return null;
  }

  return (
    <DialogOverlay isOpen={Boolean(product)} onDismiss={close} style={{ zIndex: 3 }}>
      <DialogContent aria-label="Module details">
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <button type="button" className={`${styles.btn} modal-btn-close`} onClick={close}>
                <img src={closeImg} alt="" />
              </button>
              <div className={styles.body}>
                <div className={styles.top}>
                  <Gallery images={product.images} />
                  {/* <ProductDetails product={product} /> */}
                </div>
                <div style={{ flex: 1 }}>
                  <Scrollbar className={styles.bottom}>
                    <hr />
                    <div className={styles.apiTitle}>API description</div>
                    <div className={styles.apiDescription}>
                      {/* <SyntaxHighlighter language="c" style={darculaTheme}>
                        {product.apiDescription}
                      </SyntaxHighlighter> */}
                    </div>
                  </Scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

ModuleDetailsModal.propTypes = {
  history: PT.shape().isRequired,
  product: PT.shape().isRequired,
  query: PT.shape({ Variant: PT.string.isRequired, moduleModal: PT.string.isRequired }).isRequired,
};

export default ModalWrapper;
