import React, { useContext, useMemo } from "react";
import Tile from "components/ModuleTile";
import { PRODUCTS } from "api/queries";
import { shopContext } from "App";
import { useUrlQuery } from "components/QueryProvider";
import { useQuery } from "@apollo/react-hooks";
import { productsSelector } from "api/selectors";

// UTILS
function stringParamToArray(param) {
  return (param ?? "").split(",").filter(Boolean);
}
function formatToShopifyQueryFilter(arr) {
  return arr.map(el => `'${el}'`).join(" OR ");
}

function useSearch(query) {
  const queryTags = useMemo(() => stringParamToArray(query.tags), [query.tags]);
  const queryProductTypes = useMemo(() => stringParamToArray(query.productTypes), [
    query.productTypes,
  ]);

  const tag = queryTags.length ? `tag:${formatToShopifyQueryFilter(queryTags)} ` : "";
  const productType = queryProductTypes.length
    ? `${formatToShopifyQueryFilter(queryProductTypes)}`
    : "*";
  const search = `${tag}product_type:${productType} -title:%% AND -title:$$`;
  return search;
}

const filterShieldsInterface = selectedVariant => product => {
  const hasInterface = product.options.some(el => el.name === "Interface");
  if (!hasInterface) return true;
  return product.options.some(el => el.name === "Interface" && el.values.includes(selectedVariant));
};

// COMPONENT

const ProductsGrid = () => {
  const { client, addVariantToCart, shop, tagsByProduct } = useContext(shopContext);
  const { query } = useUrlQuery();
  const selectedVariant = query.interface || "4 pin";
  const search = useSearch(query);
  const { data: productsEdges, loading } = useQuery(PRODUCTS, {
    variables: { search },
  });
  const products = productsSelector(productsEdges);

  return (
    <div className="col-lg-10 ml-n2 mr-n2 d-flex flex-wrap">
      {!products.length &&
        loading &&
        Array(12)
          .fill()
          .map((_, index) => <Tile.Placeholder key={index} />)}
      {products.filter(filterShieldsInterface(selectedVariant)).map(mod => (
        <Tile
          key={mod.id}
          mod={mod}
          tags={tagsByProduct[mod.id]}
          selectedVariant={selectedVariant}
          client={client}
          addVariantToCart={addVariantToCart}
          shop={shop}
        />
      ))}
    </div>
  );
};

ProductsGrid.propTypes = {};

export default ProductsGrid;
