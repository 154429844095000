import { createApiPaginatedHook, createApiHook } from "hooks";
import {
  getProjects,
  getMainSearch,
  getMySearches,
  getUser,
  getDevkit,
  getDraftDevkit,
  getProject,
  getTermsAndConditions,
} from "./rest";

export const useProjects = createApiPaginatedHook(getProjects);
export const useMySearch = createApiPaginatedHook(getMySearches);
export const useMainSearch = createApiHook(getMainSearch);
export const useUser = createApiHook(getUser);
export const useDevkit = createApiHook(getDevkit);
export const useDraftDevkit = createApiHook(getDraftDevkit);
export const useProject = createApiHook(getProject);
export const useTermsAndConditions = createApiHook(getTermsAndConditions);
