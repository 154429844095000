import React from "react";
import bannerImg from "assets/images/BANER v2-small.png";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import styles from "./FooterBanner.module.scss";

const Footer = () => {
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row>
          <div className="col-sm-12">
            <div className="position-relative">
              <div className={styles.titleSection}>
                <h2 className={styles.title}>
                  Design your next
                  <br />
                  product with E² App
                </h2>
                <Button
                  variant="success"
                  className="mt-4"
                  as="a"
                  href="https://che-che.ide.electronsquare.com/e2editor/"
                >
                  GO TO APP
                </Button>
              </div>
              <img src={bannerImg} className={styles.banner} alt="an example of app interface" />
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
