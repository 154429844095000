import { useState, useCallback, useMemo, useRef, useEffect } from "react";

export const StatusHandler = ({ children }) => {
  const isMounted = useRef(true);
  const [state, setState] = useState({ errors: {}, fetching: false, status: "" });

  const setErrors = useCallback(errors => setState(s => ({ ...s, errors })), []);
  const clear = () => {
    if (!isMounted.current) return;
    setErrors({});
  };
  const setStatus = useCallback(status => setState(s => ({ ...s, status })), []);
  const setFetching = useCallback(status => setState(s => ({ ...s, fetching: status })), []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function startFetching() {
    if (!isMounted.current) return;
    setState(s => ({ ...s, fetching: true, errors: {} }));
  }
  function stopFetching(err) {
    if (!isMounted.current) return;
    setState(s => ({
      ...s,
      fetching: false,
      errors: err ?? {},
    }));
  }
  const memoStartFetching = useMemo(() => startFetching, []);
  const memoStopFetching = useMemo(() => stopFetching, []);
  const memoClear = useCallback(clear, [setErrors]);
  const memoStatus = useCallback(setStatus, [setStatus]);
  return children({
    isFetching: state.fetching,
    setFetching,
    clear: memoClear,
    setErrors,
    errors: state.errors,
    startFetching: memoStartFetching,
    stopFetching: memoStopFetching,
    setStatus: memoStatus,
    status: state.status,
  });
};
