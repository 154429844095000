import React from "react";

export const AuthMessage = ({ message }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "calc(100vh - 358px)", backgroundColor: "#fff" }}
    >
      <div className="card">
        <div className="card-body">{message}</div>
      </div>
    </div>
  );
};
