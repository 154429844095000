import React, { useRef, useEffect } from "react";
import cuid from "cuid";

const productionKey = "6LciurYZAAAAAMbe_8nHHBXG8SznhGoVp1PPB-A4";
// const testKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

export const Captcha = ({ callback }) => {
  const id = useRef(cuid());
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      window.grecaptcha.render(id.current, {
        sitekey: productionKey,
        callback,
      });
    }
  }, [callback]);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  return <div id={id.current} />;
};
