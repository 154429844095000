import { api, tokenRefreshMiddleware } from "./restApiConnector";

const authApi = tokenRefreshMiddleware(api);

const DOMAIN = "https://platform.ide.electronsquare.com";

export const obtainToken = data =>
  api({
    method: "POST",
    url: DOMAIN + "/users/token/obtain",
    body: data,
  });

export const refreshToken = data =>
  api({
    method: "POST",
    url: DOMAIN + "/users/token/refresh",
    body: data,
  });

export const getProjects = (search = "") =>
  authApi({
    method: "GET",
    url: DOMAIN + "/projects/my" + search,
  });

export const getProject = id =>
  authApi({
    method: "GET",
    url: DOMAIN + "/projects/my/" + id,
  });

export const postProject = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/projects/my",
    body: data,
  });

export const patchProject = (data, id) =>
  authApi({
    method: "PATCH",
    url: DOMAIN + "/projects/my/" + id,
    body: data,
  });

export const keycloakAuthorize = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/applications/authorize/", // slash at the end is necessary for some reason
    body: data,
  });

export const keycloakRefreshToken = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/users/get-new-keycloak-access-token",
    body: data,
  });

export const getMainSearch = (phrase = "", abortToken) =>
  authApi({
    method: "GET",
    url: DOMAIN + "/searches/phrases?search=" + phrase,
    abortToken,
  });

export const getMySearches = abortToken =>
  authApi({
    method: "GET",
    url: DOMAIN + "/searches/my",
    abortToken,
  });

export const postMySearches = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/searches/my",
    body: data,
  });

export const deleteMyHistory = () =>
  authApi({
    method: "DELETE",
    url: DOMAIN + "/searches/my/clear",
  });

export const patchUser = (data, userId) =>
  authApi({
    method: "PATCH",
    url: DOMAIN + "/users/items/" + userId,
    body: data,
  });

export const getUser = userId =>
  authApi({
    method: "GET",
    url: DOMAIN + "/users/items/" + userId,
  });

export const resetPassword = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/users/reset/password",
    body: data,
  });

export const resetPasswordConfirm = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/users/password/reset/confirm",
    body: data,
  });

export const postRegister = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/users/register",
    body: data,
  });

export const deleteProject = (id, data) =>
  authApi({
    method: "POST",
    url: DOMAIN + "/projects/my/" + id + "/delete",
    body: data,
  });

export const postLoginSocial = data =>
  api({
    method: "POST",
    url: DOMAIN + "/login/social/",
    body: data,
  });

export const patchDraftDevkit = (id, data) =>
  authApi({
    method: "PATCH",
    url: DOMAIN + "/devkits/draftdevkits/" + id,
    body: data,
  });

export const getDevkit = id =>
  authApi({
    method: "GET",
    url: DOMAIN + "/devkits/items/" + id,
  });

export const getDraftDevkit = id =>
  authApi({
    method: "GET",
    url: DOMAIN + "/devkits/draftdevkits/" + id,
  });

export const postUserActivate = data =>
  authApi({
    method: "POST",
    url: DOMAIN + "/users/activation/confirm",
    body: data,
  });

export const getTermsAndConditions = () =>
  api({
    method: "GET",
    url: DOMAIN + "/regulations/terms-and-conditions",
  });
