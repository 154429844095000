import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import PT from "prop-types";

interface OutputQuery {
  [key: string]: string;
}
interface InputQuery {
  [key: string]: string | boolean | number;
}
export interface Value {
  query: OutputQuery;
  setQuery: (arg: InputQuery, stringifyOptions?: { allowEmpty?: boolean }) => void;
  updateQuery: (arg: InputQuery) => void;
  search: string;
}

export const queryContext = React.createContext({
  query: {},
  setQuery: () => {},
  updateQuery: () => {},
  search: "",
});

export const QueryProvider: React.FC<{}> = ({ children }) => {
  const history = useHistory();
  const { search } = useLocation();

  const setQuery = React.useCallback(
    (q: InputQuery, stringifyOptions) => {
      history.replace({ search: queryString.stringify(q, stringifyOptions) });
    },
    [history],
  );
  const query = React.useMemo(() => queryString.parse(search), [search]);

  /**
   * Be aware that updateQuery doesn't work well with asynchronous methods
   * like throttling or debouncing, because it has "old" version of query object
   * in its lexical scope. If you want to use it asynchronously, better use setQuery
   * and pass current query object saved in ref or class attribute.
   */
  const updateQuery = React.useCallback(
    (q: InputQuery) => {
      history.replace({ search: queryString.stringify({ ...query, ...q }) });
    },
    [history, query],
  );

  const value = React.useMemo(
    () => ({
      query,
      setQuery,
      updateQuery,
      search,
    }),
    [query, setQuery, search, updateQuery],
  );

  return <queryContext.Provider value={value}>{children}</queryContext.Provider>;
};
QueryProvider.propTypes = {
  children: PT.node.isRequired,
};

export const useUrlQuery = (options = {}) => {
  const context = React.useContext(queryContext);

  const contextMemo = React.useMemo(() => {
    return { ...context, query: { ...context.query } };
  }, [context]);

  let search = "";
  if (options.exclude) {
    options.exclude.forEach((key: string) => {
      delete contextMemo.query[key];
    });
    search = queryString.stringify(contextMemo.query);
    contextMemo.search = search;
  } else {
    search = context.search;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => contextMemo, [search]);
};
