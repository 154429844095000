import React from "react";
import finalRenderImg from "assets/images/howItWorks/baby_monitor_finalne_pcb.png";
import { noop } from "utilities";
import cx from "classnames";
import styles from "./FinalPcbRow.module.scss";

export const FinalPcbRow = ({ offset = 0, refCallback = noop, itemWidth, isSmallScreen }) => {
  function calculateNumberOfItemsToRender() {
    const howMany = Math.ceil(window.innerWidth / itemWidth);
    const isOdd = howMany % 2 > 0;
    if (isOdd) {
      return howMany + 1;
    }
    return howMany;
  }
  const numberOfItemsToRender = calculateNumberOfItemsToRender();
  const rowIsCentered = offset % 2 === 0;
  const result = [];
  const centerMultiplier = rowIsCentered ? 1 : 0;
  const smallScreenOffset = isSmallScreen ? 160 : 0;
  const top = window.innerHeight / 2 + 26 - 29 - (offset + 1) * 185 + 76 + smallScreenOffset;

  for (let x = 0; x < numberOfItemsToRender + centerMultiplier; x += 1) {
    const position = x - Math.floor(numberOfItemsToRender / 2);
    const isMainModule = offset === 0 && position === 0;
    result.push(
      <div
        className={cx(styles.imgWrapper, isMainModule ? "main" : "pcb-row pcb-row-" + offset)}
        key={x}
        style={{
          visibility: isMainModule ? "hidden" : "hidden",
          opacity: 0,
        }}
        ref={ref => refCallback(x, position, ref)}
      >
        <img src={finalRenderImg} alt="" />
        {/* <DevelopmentPositionHelper position={position} /> */}
      </div>,
    );
  }
  return (
    <div
      className={styles.modRow}
      style={{
        width: (numberOfItemsToRender + centerMultiplier) * itemWidth,
        left: -((numberOfItemsToRender + centerMultiplier) * itemWidth - window.innerWidth) / 2,
        top,
        zIndex: 1,
      }}
    >
      {result}
    </div>
  );
};

/**
 * Displays position numbers on each module. It makes development easier.
 */
// const DevelopmentPositionHelper = ({ position }) => {
//   return (
//     <div
//       style={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         margin: "auto",
//         color: "red",
//         fontWeight: 900,
//         fontSize: 28,
//         backgroundColor: "#fff",
//         padding: "10px 20px",
//         borderRadius: 30,
//       }}
//     >
//       {position}
//     </div>
//   );
// };
