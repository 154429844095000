import React, { createContext, memo, useCallback, useContext, useMemo, useState } from "react";
import closeIcon from "assets/images/close.svg";
import { Modal } from "components/Modal/Modal";
import styles from "./Alert.module.scss";

export const context = createContext({});

export const AlertModalController = memo(({ children }: any) => {
  const [state, setState] = useState({
    isOpen: false,
    options: null,
    heading: "",
    body: null,
  });
  const close = useCallback(() => setState(s => ({ ...s, isOpen: false })), []);
  const open = useCallback(
    (content, options?: Options) =>
      setState({
        options: options ?? null,
        heading: content.heading,
        body: content.body,
        isOpen: true,
      }),
    [],
  );
  const value = useMemo(() => ({ ...state, close, open }), [state, open, close]);
  return (
    <context.Provider value={value}>
      {children}
      <Modal isOpen={state.isOpen} close={close} size="auto">
        {state.heading ? (
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.headline}>{state.heading}</div>
              <button className={styles.closeModalBtn} type="button" onClick={close}>
                <img src={closeIcon} alt="" />
              </button>
            </div>
            {typeof state.body === "function"
              ? state.body({ close, isOpen: state.isOpen })
              : state.body}
          </div>
        ) : (
          <div />
        )}
      </Modal>
    </context.Provider>
  );
});

export const useAlertModal = () => {
  const modal = useContext(context);
  return {
    open: (content, options) => {
      modal.open(content, options);
    },
    close: modal.close,
  };
};
