import React, { useEffect, useRef } from "react";
import { usePrevious } from "hooks";
import cx from "classnames";
import CartSvg from "components/Navbar/Cart";
import Button from "react-bootstrap/Button";
import { StatusHandler } from "components/StatusHandler";
import checkImg from "assets/images/checkS.svg";
import styles from "./CartAddBtn.module.scss";

export const CartAddBtn = ({
  variant = "primary",
  addToCart,
  overwrites = {},
  inactive,
  disabled,
}) => {
  return (
    <StatusHandler>
      {helpers => (
        <Button
          variant={variant}
          type="button"
          onClick={() => addToCart(helpers)}
          className={cx(
            "mr-2 d-inline-flex align-items-center justify-content-center",
            styles.btn,
            overwrites.button?.className || "",
            {
              [styles.inactive]: helpers.isFetching,
            },
          )}
          disabled={helpers.isFetching || inactive || disabled}
        >
          <CartIcon
            submitted={helpers.status === "success"}
            setStatus={helpers.setStatus}
            variant={variant}
          />
          <span className={cx(styles.btnText, "pl-3")}>ADD TO CART</span>
        </Button>
      )}
    </StatusHandler>
  );
};

const CartIcon = ({ submitted, setStatus, variant }) => {
  const prevSubmitted = usePrevious(submitted);
  const timeout = useRef(0);
  useEffect(() => {
    if (submitted === true && prevSubmitted === false) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setStatus("");
      }, 2000);
    }
  }, [submitted, prevSubmitted, setStatus]);
  if (submitted)
    return (
      <img
        src={checkImg}
        alt=""
        className="d-inline-block"
        style={{ width: 19, paddingBottom: 4 }}
      />
    );
  return (
    <CartSvg
      className={variant === "primary" ? "" : styles.svgSecondary}
      variant={variant === "primary" ? "light" : "blue"}
      style={{ width: 19, paddingBottom: 4 }}
    />
  );
};
