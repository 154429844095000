import React, { useContext } from "react";
import PT from "prop-types";
import Carousel from "react-bootstrap/Carousel";
import { withSize } from "react-sizeme";
import arrowImg from "assets/images/arrow.png";
import { shopContext } from "App";
import Tile from "components/ModuleTile";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { PRODUCTS } from "api/queries";
import cx from "classnames";
import { productsSelector } from "api/selectors";
import { useUrlQuery } from "components/QueryProvider";
import styles from "./ModulesCarousel.module.scss";

/**
 * @param {number} width
 */
function getDividend(width) {
  return Math.floor(width / 240);
}

const ModulesCarousel = ({ size, search = "", title, classNames = {} }) => {
  const { client, addVariantToCart, shop, tagsByProduct } = useContext(shopContext);
  const { data: productsEdges } = useQuery(PRODUCTS, {
    variables: { search },
  });
  const { query } = useUrlQuery();
  const products = productsSelector(productsEdges);
  const dividend = getDividend(size.width);

  const pages = products.reduce((acc, mod, index) => {
    if (index % dividend === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(mod);
    return acc;
  }, []);

  const placeholderPages = Array(5)
    .fill()
    .reduce((acc, element, index) => {
      if (index % dividend === 0) {
        acc.push([]);
      }
      acc[acc.length - 1].push(element);
      return acc;
    }, [])
    .filter((_, index) => index === 0);

  return (
    <div className={styles.carousel}>
      <div className={styles.topSpan}>
        <span className={cx(classNames.title, styles.topSpanLeft)}>{title}</span>
        <Link to="/products" className="link-green mr-2">
          See more
        </Link>
      </div>
      <Carousel
        indicators={false}
        nextIcon={
          <span aria-hidden="true" className="">
            <img src={arrowImg} alt="" />
          </span>
        }
        prevIcon={
          <span aria-hidden="true" className="">
            <img src={arrowImg} alt="" style={{ transform: "rotate(180deg)" }} />
          </span>
        }
      >
        {pages.length
          ? pages.map((page, index2) => (
              <Carousel.Item key={index2}>
                <div className="d-flex">
                  {page.map((mod, index) => (
                    <Tile
                      key={index}
                      mod={mod}
                      tags={tagsByProduct[mod.id]}
                      client={client}
                      addVariantToCart={addVariantToCart}
                      shop={shop}
                      style={{ flex: 1, maxWidth: `${(1 / dividend) * 100}%` }}
                      selectedVariant={query.interface}
                    />
                  ))}
                </div>
              </Carousel.Item>
            ))
          : placeholderPages.map((page, index2) => (
              <Carousel.Item key={index2}>
                <div className="d-flex">
                  {page.map((_, index) => (
                    <Tile.Placeholder
                      key={index}
                      style={{ flex: 1, maxWidth: `${(1 / dividend) * 100}%` }}
                    />
                  ))}
                </div>
              </Carousel.Item>
            ))}
      </Carousel>
    </div>
  );
};

ModulesCarousel.propTypes = {
  size: PT.shape({ width: PT.number }).isRequired,
  classNames: PT.shape({
    label: PT.string,
  }),
};
export default withSize()(ModulesCarousel);
