import { useState } from "react";

export const useAsyncField = (fetchCall, callbacks = {}) => {
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  async function submit(...args) {
    setInProgress(true);
    const [payload, err, { ok }] = await fetchCall(...args);
    setInProgress(false);
    setError(err);
    if (ok) {
      if (callbacks.onSuccess) {
        callbacks.onSuccess(payload);
      }
    }
  }
  function displayError(name) {
    if (error) {
      return error[name] ?? Object.values(error)[0] ?? "An error occurred";
    }
    return "";
  }
  return [submit, { error, inProgress, displayError }];
};
