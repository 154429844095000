import React, { useRef, useEffect, forwardRef } from "react";
import cuid from "cuid";
import xIcon from "assets/images/icon_round-cancel-24px.svg";
import cx from "classnames";
import { useFormContext } from "react-hook-form";
import styles from "./FormInput.module.scss";

/**
 *
 * @param {"blur" | "change"} trigger
 * @param {"small" | "large"} variant
 * @param {"input" | "textarea"} as
 * @param {string} subText
 * @param {boolean} focusOnMount
 */
export const FormInputUncontrolled = forwardRef(
  (
    {
      variant = "small",
      label,
      name,
      disabled,
      overrides = {},
      type = "text",
      as: As = "input",
      subText,
      tabIndex,
      focusOnMount = false,
    },
    ref,
  ) => {
    const id = useRef(cuid()).current;
    const form = useFormContext();
    const inputRef = useRef();
    const initialRender = useRef(true);

    const clear = () => {
      if (form) {
        form.setValue(name, "");
      }
    };

    useEffect(() => {
      if (focusOnMount && initialRender.current) {
        inputRef.current.focus();
      }
    }, [focusOnMount]);

    useEffect(() => {
      initialRender.current = false;
    }, []);

    function handleRef(r) {
      inputRef.current = r;
      if (ref && typeof ref === "function") {
        ref(r);
      }
    }

    return (
      <div className={styles.container}>
        <As
          className={cx(styles.control, overrides.input?.className, {
            [styles.textarea]: As === "textarea",
            [styles.variantLarge]: variant === "large",
            [styles.variantSmall]: variant === "small",
          })}
          disabled={disabled}
          id={id}
          name={name}
          ref={handleRef}
          style={overrides.input?.style}
          type={type}
          tabIndex={tabIndex}
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {subText && <span className={styles.subText}>{subText}</span>}

        <label
          role="button"
          htmlFor={id}
          className={cx(styles.clearBtn, "btn btn-light")}
          onMouseDown={e => {
            e.preventDefault();
          }}
          onKeyPress={clear}
          onClick={clear}
        >
          <img src={xIcon} alt="" />
        </label>
      </div>
    );
  },
);

function Error({ text = "" }) {
  return (
    <div className={styles.error} style={{ display: text ? "block" : "none" }}>
      {text}
    </div>
  );
}
FormInputUncontrolled.Error = Error;
