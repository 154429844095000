import React from "react";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { PRODUCT } from "api/queries";
import { useQuery } from "@apollo/react-hooks";
import { Gallery } from "components/Gallery";
import { productSelector } from "api/selectors";
import { ProductDetails } from "components/ProductDetails";
import ModulesCarousel from "pages/Home/ModulesCarousel";
import { Spacer } from "components/Spacer";
import { useProductsSearch } from "hooks";
import { BackArrow } from "components/BackArrow/BackArrow";
import styles from "./Product.module.scss";

export const Product = ({ match }) => {
  const { data } = useQuery(PRODUCT, {
    variables: { handle: match.params.handle, skip: false },
  });

  const product = productSelector(data?.productByHandle);
  const carouselSearch = useProductsSearch({
    tags: product ? product.tags.join(",") : "",
    productTypes: product ? product.productType : "",
  });
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div style={{ paddingTop: 120 }}>
        <Container className="mt-3">
          <Row className="pl-3 pr-3">
            <div className="col-sm-12">
              <BackArrow />
            </div>
          </Row>
          <Row className="pl-3 pr-3">
            {!product && (
              <>
                <div className="col-sm-5 col-lg-5 col-xs-12">
                  <Gallery.Placeholder />
                </div>
                <Spacer space={60} />
                <div className="col-sm-12">
                  <ModulesCarousel
                    search={carouselSearch}
                    title="You may also like"
                    classNames={{ title: styles.textGray }}
                  />
                </div>
              </>
            )}
            {product && (
              <>
                <div className="col-sm-5 col-lg-5 col-xs-12">
                  <Gallery images={product.images} productId={product.id} />
                </div>
                <div className="col-sm-7 col-lg-7">
                  <ProductDetails product={product} />
                </div>
                <Spacer space={60} />
                <div className="col-sm-12">
                  <ModulesCarousel
                    search={carouselSearch}
                    title="You may also like"
                    classNames={{ title: styles.textGray }}
                  />
                </div>
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};
