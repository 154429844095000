import React from "react";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProductsGrid from "./ProductsGrid";
import { FilterAside } from "./FilterAside";
import styles from "./Products.module.scss";

const Products = () => {
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div style={{ paddingTop: 120 }}>
        <Container className="mt-3">
          <Row className="pl-3 pr-3">
            <div className="">
              <div className="d-flex flex-wrap flex-lg-nowrap">
                <div className={`${styles.wrapper} w-100 ml-n2 mr-n2`}>
                  <div className="w-100">
                    <h1 className={styles.heading}>Products</h1>
                    <div className="d-flex justify-content-end">
                      <button className={styles.filtersBtn}>Filters</button>
                    </div>
                  </div>
                  <FilterAside />
                </div>
                <ProductsGrid />
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Products;
