import React, { useState, useRef, useEffect, useMemo } from "react";
import { useMainSearch, useMySearch } from "api/restApiHooks";
import { postMySearches } from "api/rest";
import queryString from "query-string";
import { useHistory } from "react-router";
import cx from "classnames";
import { Spacer } from "components/Spacer";
import arrowIcon from "assets/images/arrow.png";
import Button from "react-bootstrap/Button";
import { useProducts, useAuth } from "App";
import { usePrevious } from "hooks";
import { DisabledOpacity } from "components/DisabledOpacity";
import styles from "./MainSearch.module.scss";
import { ProductItem } from "./ProductItem/ProductItem";
import { ClearHistoryBtn } from "./ClearHistoryBtn/ClearHistoryBtn";

function filterFirstThreeElements(_el, index) {
  return index <= 2;
}

const useEscListener = (trigger, callback) => {
  const listener = e => {
    if (e.key === "Escape") {
      callback();
    }
  };
  useEffect(() => {
    if (trigger) {
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
};

const useInput = () => {
  const [value, setValue] = useState("");
  const input = useRef();
  const onChange = e => setValue(e.target.value);
  return [{ value, onChange, ref: input }, { setValue }];
};

const useFilteredMainSearchResults = results =>
  useMemo(
    () => ({
      ...results,
      devkits: results?.devkits ?? [],
      users: results?.users ?? [],
      products: results?.products.filter(prod => !prod.name.includes(" $demo")) ?? [],
    }),
    [results],
  );

export const MainSearch = ({ style, isOpen, close }) => {
  const initialRender = useRef(true);
  const history = useHistory();
  const [inputControls, { setValue: setPhrase }] = useInput();
  const { isAuthenticated } = useAuth();
  const [mySearches, { dispatch: dispatchMySearches, clearCache }] = useMySearch(
    undefined,
    undefined,
    {
      skip: !isOpen || !isAuthenticated,
      cache: true,
    },
  );
  const [rawMainSearchResults, { inProgress, dispatch }] = useMainSearch(
    inputControls.value,
    null,
    {
      skip: inputControls.value === "",
      cache: true,
    },
  );
  const mainSearchResults = useFilteredMainSearchResults(rawMainSearchResults);

  useEscListener(isOpen, close);
  const previousValue = usePrevious(inputControls.value);

  /**
   * Clear results on clear input
   */
  useEffect(() => {
    if (inputControls.value === "" && previousValue !== "") {
      dispatch({ type: "SAVE", payload: null });
    }
  }, [inputControls.value, previousValue, dispatch]);

  /**
   * Focus input on show
   */
  useEffect(() => {
    if (isOpen) {
      inputControls.ref.current.focus();
    }
  }, [isOpen, inputControls.ref]);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  function submitOnEnter(phrase) {
    if (!phrase) return;
    close();
    if (isAuthenticated) {
      postMySearches({ phrase });
    }
    history.push({
      pathname: "/search-results",
      search: queryString.stringify({ phrase }),
      state: inProgress ? null : mainSearchResults,
    });
  }
  function submitOnClick(phrase) {
    close();
    postMySearches({ phrase });
    history.push({
      pathname: "/search-results",
      search: queryString.stringify({ phrase }),
      state: null,
    });
  }

  function clearMySearches() {
    dispatchMySearches({ type: "SAVE", payload: [] });
    clearCache();
  }

  return (
    <>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/interactive-supports-focus */}
      <div
        className={styles.overlay}
        style={{ display: isOpen ? "block" : "none" }}
        onClick={close}
        role="button"
      />
      <div className={styles.container} style={{ ...style, display: isOpen ? "block" : "none" }}>
        <div className={styles.content}>
          <form
            className={styles.form}
            onSubmit={e => {
              e.preventDefault();
              submitOnEnter(inputControls.value);
            }}
          >
            <input {...inputControls} className={styles.input} placeholder="Enter search here" />
            <Button
              variant="light"
              onClick={() => setPhrase("")}
              className={styles.clearButton}
              title="clear"
            >
              <img src={arrowIcon} alt="clear" className={styles.arrow} />
            </Button>
          </form>
          <div className={styles.recentSearches}>
            {inputControls.value === "" ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className={cx("ginora", styles.title)}>Recent searches</span>
                  <ClearHistoryBtn
                    deleteCallback={clearMySearches}
                    nothingToDelete={mySearches.length === 0}
                  />
                </div>
                <Spacer space={10} orientation="vertical" />
                <div className={styles.phrases}>
                  {mySearches.map(phrase => (
                    <button
                      key={phrase.id}
                      type="button"
                      className={cx(styles.searchPhrase, "btn")}
                      onClick={() => submitOnClick(phrase.phrase)}
                    >
                      {phrase.phrase}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <span className={cx("ginora", styles.title)}>Shields/Kits</span>
                <Products products={mainSearchResults.products ?? []} close={close} />
                <div className={cx({ "d-none": !mainSearchResults.devkits.length })}>
                  <span className={cx("ginora", styles.title)}>Kits</span>
                  {mainSearchResults.devkits.filter(filterFirstThreeElements).map(devkit => (
                    <ProductItem
                      key={devkit.id}
                      img=""
                      title={devkit.name}
                      subtitle=""
                      onClick={() => submitOnClick(devkit.name)}
                    />
                  ))}
                </div>
                {/* <div>
                  <span className={cx("ginora", styles.title)}>Users</span>
                  {mainSearchResults.users.filter(filterFirstThreeElements).map(user => (
                    <ProductItem
                      key={user.id}
                      img=""
                      title={`${user.firstName} ${user.lastName}`}
                      subtitle=""
                      // onClick={() => submitOnClick(user.customer_Addresses_FirstName)} // TODO
                    />
                  ))}
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function makeShopifyProductId(externalId) {
  return btoa("gid://shopify/Product/" + externalId);
}

const Products = ({ products, close }) => {
  const [shopifyProducts] = useProducts();
  const history = useHistory();
  return (
    <div>
      {products.filter(filterFirstThreeElements).map(product => {
        const shopifyProduct = shopifyProducts.find(
          prod => makeShopifyProductId(product.externalId) === prod.id,
        );
        return (
          <DisabledOpacity
            key={product.id}
            disabled={!shopifyProduct}
            title="This product is unavailable"
          >
            <ProductItem
              img={shopifyProduct?.images[0]?.src || ""}
              title={product.name}
              subtitle=""
              onClick={() => {
                history.push(`/products/${shopifyProduct?.handle}?interface=4 pin`);
                close();
              }}
            />
          </DisabledOpacity>
        );
      })}
    </div>
  );
};
