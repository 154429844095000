import React, { Component } from "react";
import PT from "prop-types";
import xIcon from "assets/images/icon_round-close-24px.svg";
// import { Checkbox } from "components/Checkbox";
import Button from "react-bootstrap/Button";
import cx from "classnames";
import { Link } from "react-router-dom";
import LineItem from "./LineItem/LineItem";
import styles from "./Cart.module.scss";

// SUBCOMPONENTS

const Overlay = ({ isOpen, ...props }) => (
  <div
    className={styles.overlay}
    aria-modal={isOpen}
    aria-hidden={!isOpen}
    role="dialog"
    {...props}
  />
);
Overlay.propTypes = {
  isOpen: PT.bool.isRequired,
};

// MAIN COMPONENT

export class Cart extends Component {
  static Overlay = Overlay;

  openCheckout = async () => {
    const { checkout, startCheckoutInterval } = this.props;
    await startCheckoutInterval(checkout.id);
    window.open(checkout.webUrl);
  };

  render() {
    const {
      checkout,
      updateQuantityInCart,
      removeLineItemInCart,
      isCartOpen,
      handleCartClose,
      boughtProducts,
    } = this.props;

    const lineItems = checkout.lineItems.map(lineItem => {
      return (
        <LineItem
          currencyCode={checkout.currencyCode}
          key={lineItem.id.toString()}
          line_item={lineItem}
          removeLineItemInCart={removeLineItemInCart}
          updateQuantityInCart={updateQuantityInCart}
          isBought={boughtProducts.some(el => el === lineItem.variant.id)}
        />
      );
    });

    return (
      <div className={cx(styles.cart, { [styles.open]: isCartOpen })}>
        <header className={styles.Cart__header}>
          <h2 className="font-weight-light">Your cart</h2>
          <button
            type="button"
            onClick={handleCartClose}
            className={cx(styles.Cart__close, "mt-2")}
          >
            <img src={xIcon} alt="close cart" />
          </button>
        </header>
        <div className={styles.scrollWrapper}>
          {/* <div className={styles.storeControl}>
            <Checkbox checked={false} onChange={console.log} className="mr-3" label="Store" />
            <div>
              <Button variant="outline-success" className="border border-light">
                Delete all
              </Button>
              <Button variant="outline-success" className="border border-light">
                Delete selected
              </Button>
            </div>
          </div> */}

          <ul className={styles["Cart__line-items"]}>{lineItems}</ul>
          {!lineItems.length && (
            <span className={cx(styles.cartEmpty, "pl-4")}>Your cart is currently empty</span>
          )}
        </div>

        <footer className={styles.Cart__footer}>
          <div className="d-flex justify-content-between">
            <div>
              <div>TOTAL AMOUNT TO PAY</div>
              {/* <div className={styles.footerTaxSpan}>
                Including {checkout.currencyCode} {checkout.totalTax} in taxes
              </div> */}
              <div className={styles.footerTaxSpan}>Including taxes</div>
            </div>
            <div className={styles.footerTotalPrice}>
              {checkout.currencyCode} {checkout.totalPrice}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <Button
              className={cx(styles.checkoutBtn, "w-50")}
              variant="primary"
              onClick={this.openCheckout}
            >
              Checkout
            </Button>
            <Link to="/cart" className={cx(styles.checkoutBtn, styles.cartLink, "p-0")}>
              Show cart
            </Link>
          </div>
        </footer>
      </div>
    );
  }
}

Cart.propTypes = {
  checkout: PT.shape().isRequired,
  updateQuantityInCart: PT.func.isRequired,
  removeLineItemInCart: PT.func.isRequired,
  isCartOpen: PT.bool.isRequired,
  handleCartClose: PT.func.isRequired,
};
