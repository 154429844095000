import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import cx from "classnames";
import ScrollTrigger from "gsap/ScrollTrigger";
import motherboardImg from "assets/images/howItWorks/motherboard_3x3.svg";
import motherboardSolidImg from "assets/images/howItWorks/motherboard_3x3-solid.svg";
import motherboardShadowImg from "assets/images/howItWorks/shadow-motherboard.svg";
import pulsoximeterImg from "assets/images/howItWorks/pulsoximeter.svg";
import pulsoximeterGreenImg from "assets/images/howItWorks/pulsoximeter_green.svg";
import pushButtonImg from "assets/images/howItWorks/pushbutton.svg";
import pushButtonGreenImg from "assets/images/howItWorks/pushbutton_green.svg";
import motionSensorImg from "assets/images/howItWorks/motion-sensor-9-axis.svg";
import motionSensorGreenImg from "assets/images/howItWorks/motion-sensor-9-axis_green.svg";
import thermometerImg from "assets/images/howItWorks/thermometer.svg";
import thermometerGreenImg from "assets/images/howItWorks/thermometer_green.svg";
import chargerImg from "assets/images/howItWorks/charger.svg";
import chargerGreenImg from "assets/images/howItWorks/charger_green.svg";
import microControllerImg from "assets/images/howItWorks/microcontroller.svg";
import microControllerGreenImg from "assets/images/howItWorks/microcontroller_green.svg";
import ledMatrixGreenImg from "assets/images/howItWorks/led-matrix_green.svg";
import ledMatrixImg from "assets/images/howItWorks/led-matrix.svg";
import motherboardTopSolidImg from "assets/images/howItWorks/motherboard_3x3_top_solid.svg";
import motherboardBottomImg from "assets/images/howItWorks/motherboard_bottom.svg";
import cableImg from "assets/images/howItWorks/cable.svg";
import pcb4PinBlueImg from "assets/images/howItWorks/pcb_4pin.svg";
import baseImg from "assets/images/howItWorks/baza.svg";
import shieldBottomImg from "assets/images/howItWorks/shield_bottom.svg";
// 21 pin
import shieldPulsoximeterGreenImg from "assets/images/howItWorks/shield_pulsoximeter-green.svg";
import shieldChargerGreenImg from "assets/images/howItWorks/shield_charger_green.svg";
import shieldThermometerGreenImg from "assets/images/howItWorks/shield_thermometer_green.svg";
import shieldMicrocontrollerGreenImg from "assets/images/howItWorks/shield_microcontroller_green.svg";
import shieldPushbuttonGreenImg from "assets/images/howItWorks/shield_pushbutton-green.svg";
import shieldLedMatrixGreenImg from "assets/images/howItWorks/shield_led-matrix-green.svg";
// Components
import componentPushbuttonImg from "assets/images/howItWorks/komponenty_pushbutton.svg";
import componentLedMatrixImg from "assets/images/howItWorks/komponenty_led-matrix.svg";
import componentMicrocontrollerImg from "assets/images/howItWorks/komponenty_mikrocontroller.svg";
import componentMotionSensorImg from "assets/images/howItWorks/komponenty_motion-sensor.svg";
import componentPulsoximeterImg from "assets/images/howItWorks/komponenty_pulsoksymetr.svg";
import componentThermometerImg from "assets/images/howItWorks/komponenty_termometr.svg";
import componentChargerImg from "assets/images/howItWorks/komponenty_charger.svg";
import pcbFinalImg from "assets/images/howItWorks/pcb_finalne-2-z-cieniem.svg";
import pinMask3Img from "assets/images/howItWorks/pin_mask3.png";

import Media from "react-media";
import { Spacer } from "components/Spacer";
import Navbar from "components/Navbar";
import { required } from "utilities";
import { Laptop } from "./Svg/Laptop";
import { Pcb21 } from "./Svg/Pcb21";
import { MotionSensor } from "./Svg/MotionSensor";
import { Timeline } from "../Timeline";
import { FinalScene } from "../FinalScene/FinalScene";
import styles from "./Animation.module.scss";
import { FinalPcbRow } from "./FinalPcbRow/FinalPcbRow";
import { SmallScreenText } from "./SmallScreenText/SmallScreenText";
import { PinMask2 } from "./PinMask2";
import { PinMask1 } from "./PinMask1";

gsap.registerPlugin(ScrollTrigger);

const ANIMATION_RANGE = 30000; // remember to adjust timeline breakpoints if you change this value
const BOARD_WIDTH = 500;
const Y_X_RATIO = 0.658534; // module height to width ratio
const SEGMENT_WIDTH = (BOARD_WIDTH - 12) / 2 / 3;
const MODULE_1_WIDTH = SEGMENT_WIDTH * 2;
const MODULE_2_WIDTH = SEGMENT_WIDTH * 3;
const MODULE_4_WIDTH = SEGMENT_WIDTH * 4;
const SHIELD_1_WIDTH = SEGMENT_WIDTH * 2 - 36;
const SHIELD_2_WIDTH = SEGMENT_WIDTH * 3 - 36;
const SHIELD_4_WIDTH = SEGMENT_WIDTH * 4 - 36;
const SHIELD_MARGIN = 36 / 2;

function useColor(initialColor) {
  const [color, setColor] = useState(initialColor); // "green" | "blue"
  return { color, setColor };
}

export const Animation = () => {
  const container = useRef();
  const navbar = useRef();
  const text = useRef();
  const from = useRef();
  const idea = useRef();
  const toCreate = useRef();
  const toMassProduction = useRef();

  const subtitle1 = useRef();
  const subtitle2 = useRef();
  const subtitle3 = useRef();
  const subtitle4 = useRef();
  const subtitle5 = useRef();
  const subtitle6 = useRef();
  const subtitle7 = useRef();
  const subtitle8 = useRef();
  const subtitle9 = useRef();
  const subtitle10 = useRef();

  const devKit1 = useRef();
  const devKit2 = useRef();
  const devKit3 = useRef();
  const devKit4 = useRef();
  const devKit5 = useRef();
  const devKit6 = useRef();

  const device = useRef();

  const pcb4 = { root: useRef(), title: useRef() };
  const pcb21 = { root: useRef(), title: useRef(), ...useColor("blue") };
  const pcbFinal = useRef();

  const motherboard = useRef();
  const motherboardTransparent = useRef();
  const motherboardTop = useRef();
  const motherboardBottom = useRef();
  const motherboardSolid = useRef();
  const motherboardShadow = useRef();

  const laptop = useRef();
  const cable = useRef();
  const renderMask = useRef();
  const initMask = useRef();

  const animationFooter = useRef();
  const scene1 = useRef();
  const finalScene = useRef();

  const aside = {
    root: useRef(),
    motionSensor: useRef(),
    pushbutton: useRef(),
    motherboard: useRef(),
    pulsoximeter: useRef(),
    thermometer: useRef(),
    charger: useRef(),
    microController: useRef(),
    ledMatrix: useRef(),
  };

  const mods = {
    charger: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    pulsoximeter: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    thermometer: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    ledMatrix: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    pushbutton1: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    pushbutton2: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    microController: {
      root: useRef(),
      pin4: useRef(),
      pin21: useRef(),
      component: useRef(),
    },
    motionSensor: {
      root: useRef(),
      pin4: useRef(),
      shieldBottom: useRef(),
      base: useRef(),
      pin21: useRef(),
      component: useRef(),
      shieldTitle: useRef(),
      baseTitle: useRef(),
      ...useColor("blue"),
    },
  };

  const smallScreenTextRefs = {
    text: useRef(),
    from: useRef(),
    idea: useRef(),
    toMassProduction: useRef(),
    toCreate: useRef(),
    devkits: useRef(),
    devKit1: useRef(),
    devKit2: useRef(),
    devKit3: useRef(),
    devKit4: useRef(),
    devKit5: useRef(),
    devKit6: useRef(),
  };

  const pinMasks = {
    pushbutton1: useRef(),
    pushbutton2: useRef(),
    motionSensor: useRef(),
  };

  const stage1Renders = {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
  };
  const stage2Renders = {
    0: null,
    1: null,
    2: null,
  };

  const [mode, setMode] = useState("build"); // build | complete
  const [currentLabel, setLabel] = useState(undefined);

  const [tl] = useState(() => gsap.timeline({ defaults: { duration: 4 } }));
  const [largeScreenTextTimeline] = useState(() => gsap.timeline({ defaults: { duration: 4 } }));
  const [smallScreenTextTimeline] = useState(() => gsap.timeline({ defaults: { duration: 4 } }));
  const [laptopStage, setLaptopStage] = useState(0);
  // const st = useRef();
  const [st, setSt] = useState(null);

  const prevScrollPosition = useRef(window.pageYoffset);
  useEffect(() => {
    const listener = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPosition.current > currentScrollPos) {
        navbar.current.style.top = "0";
      } else {
        navbar.current.style.top = "-80px";
      }
      prevScrollPosition.current = currentScrollPos;
    };
    if (window.innerWidth <= 600) {
      window.addEventListener("scroll", listener);
    }
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  useEffect(() => {
    document.getElementById("footer").style.display = "none";
    return () => {
      document.getElementById("footer").style.display = "block";
    };
  }, []);

  useEffect(() => {
    gsap.fromTo(
      initMask.current,
      { opacity: 1 },
      {
        opacity: 0,
        display: "none",
        duration: 2,
      },
    );
    const largeScreenTextAnimation = () =>
      largeScreenTextTimeline
        .fromTo(
          text.current,
          { x: 70 },
          {
            top: 0,
          },
          "<",
        )
        .addLabel("idea")
        .to([from.current, toMassProduction.current, toMassProduction.current], {
          opacity: 0,
        })
        .to(text.current, {
          x: 0,
        })
        .to(
          idea.current,
          {
            left: -50,
            position: "relative",
          },
          "<",
        )
        .to(
          toCreate.current,
          {
            opacity: 1,

            display: "block",
          },
          "+=2",
        )
        .fromTo(
          devKit1.current,
          {
            opacity: 0,
            top: -35,
          },
          {
            opacity: 1,
            top: 0,
          },
        )
        .fromTo(
          devKit1.current,
          {
            opacity: 1,
            top: 0,
          },
          {
            opacity: 0,
            top: 35,
          },
        )
        .fromTo(
          devKit2.current,
          {
            opacity: 0,
            top: -35,
          },
          {
            opacity: 1,
            top: 0,
          },
        )
        .fromTo(
          devKit2.current,
          {
            opacity: 1,
            top: 0,
          },
          {
            opacity: 0,
            top: 35,
          },
        )
        .fromTo(
          devKit3.current,
          {
            opacity: 0,
            top: -35,
          },
          {
            opacity: 1,
            top: 0,
          },
        )
        .fromTo(
          devKit3.current,
          {
            opacity: 1,
            top: 0,
          },
          {
            opacity: 0,
            top: 35,
          },
        )
        .fromTo(
          devKit4.current,
          {
            opacity: 0,
            top: -35,
          },
          {
            opacity: 1,
            top: 0,
          },
        )
        .fromTo(
          devKit4.current,
          {
            opacity: 1,
            top: 0,
          },
          {
            opacity: 0,
            top: 35,
          },
        )
        .fromTo(
          devKit5.current,
          {
            opacity: 0,
            top: -35,
          },
          {
            opacity: 1,
            top: 0,
          },
        )
        .fromTo(
          devKit5.current,
          {
            opacity: 1,
            top: 0,
          },
          {
            opacity: 0,
            top: 35,
          },
        )
        .fromTo(
          devKit6.current,
          {
            opacity: 0,
            top: -35,
          },
          {
            opacity: 1,
            top: 0,
          },
        );

    const smallScreenTextAnimation = () =>
      smallScreenTextTimeline
        .fromTo(
          smallScreenTextRefs.text.current,
          { x: 0, left: (window.innerWidth - 330) / 2 },
          {
            top: 0,
          },
          "<",
        )
        .addLabel("idea")
        .to(
          [
            smallScreenTextRefs.from.current,
            smallScreenTextRefs.toMassProduction.current,
            smallScreenTextRefs.toMassProduction.current,
          ],
          {
            opacity: 0,
          },
        )
        .to(smallScreenTextRefs.text.current, {
          x: 0,
        })
        .to(
          smallScreenTextRefs.idea.current,
          {
            left: 10,
            position: "relative",
          },
          "<",
        )
        .to(
          smallScreenTextRefs.toCreate.current,
          {
            opacity: 1,

            display: "block",
          },
          "+=2",
        )
        .fromTo(
          smallScreenTextRefs.devKit1.current,
          {
            opacity: 0,
            top: 100,
          },
          {
            opacity: 1,
            top: 65,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit1.current,
          {
            opacity: 1,
            top: 65,
          },
          {
            opacity: 0,
            top: 30,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit2.current,
          {
            opacity: 0,
            top: 100,
          },
          {
            opacity: 1,
            top: 65,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit2.current,
          {
            opacity: 1,
            top: 65,
          },
          {
            opacity: 0,
            top: 30,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit3.current,
          {
            opacity: 0,
            top: 100,
          },
          {
            opacity: 1,
            top: 65,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit3.current,
          {
            opacity: 1,
            top: 65,
          },
          {
            opacity: 0,
            top: 30,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit4.current,
          {
            opacity: 0,
            top: 100,
          },
          {
            opacity: 1,
            top: 65,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit4.current,
          {
            opacity: 1,
            top: 65,
          },
          {
            opacity: 0,
            top: 30,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit5.current,
          {
            opacity: 0,
            top: 100,
          },
          {
            opacity: 1,
            top: 65,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit5.current,
          {
            opacity: 1,
            top: 65,
          },
          {
            opacity: 0,
            top: 30,
          },
        )
        .fromTo(
          smallScreenTextRefs.devKit6.current,
          {
            opacity: 0,
            top: 100,
          },
          {
            opacity: 1,
            top: 65,
          },
        )
        .to(smallScreenTextRefs.devKit6.current, {
          top: 30,
        })
        .to([smallScreenTextRefs.text.current, smallScreenTextRefs.devkits.current], {
          duration: 8,
          y: -(window.innerHeight / 2 + 26 - 50),
        })
        .to(
          smallScreenTextRefs.text.current,
          {
            x: -85,
          },
          "-=2",
        )
        .to(
          smallScreenTextRefs.devkits.current,
          {
            x: 85,
            y: -(window.innerHeight / 2 + 26 - 50) - 30,
          },
          "-=2",
        );
    tl.add(window.innerWidth > 600 ? largeScreenTextAnimation() : smallScreenTextAnimation())

      .addLabel("prototype")
      .fromTo(
        text.current,
        {
          top: 0,
        },
        {
          duration: 8,
          top: -360,
        },
      )
      .fromTo(
        aside.root.current,
        {
          right: "-100%",
        },
        {
          right: 50,
          duration: 2,
        },
        "-=3",
      )
      .fromTo(
        subtitle1.current,
        {
          display: "none",
          opacity: 0,
          top: -200,
        },
        {
          display: "block",
          opacity: 1,
          top: -310,
        },
      )
      .fromTo(
        motherboard.current,
        {
          top: window.innerHeight / 2,
        },
        { display: "block", top: -147 },
      )
      .fromTo(
        motherboardShadow.current,
        {
          top: window.innerHeight / 2,
        },
        { display: "block", top: -112 },
        "-=4.03",
      )
      .to(
        aside.motherboard.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        mods.pulsoximeter.root.current,
        {
          top: window.innerHeight / 2,
          left: 168,
        },
        { display: "block", duration: 4, top: 42 },
      )
      // motherboard gray out
      .to(
        aside.motherboard.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // pulsoximeter highlight
      .to(
        aside.pulsoximeter.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        [mods.pushbutton1.root.current, mods.pushbutton2.root.current],
        {
          top: -window.innerHeight / 2,
        },
        { display: "block", duration: 4, top: -115 },
      )
      .from(
        [pinMasks.pushbutton1.current, pinMasks.pushbutton2.current, pinMasks.motionSensor.current],
        {
          display: "none",
          duration: 0,
        },
      )
      // pulsoximeter gray out
      .to(
        aside.pulsoximeter.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // pushbutton highlight
      .to(
        aside.pushbutton.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        mods.motionSensor.root.current,
        {
          left: 168,
          top: -window.innerHeight / 2,
        },
        { display: "block", duration: 4, top: -162 },
      )
      // pushbutton gray out
      .to(
        aside.pushbutton.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // motionSensor highlight
      .to(
        aside.motionSensor.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        mods.thermometer.root.current,
        {
          left: 334,
          top: window.innerHeight / 2,
        },
        { display: "block", duration: 4, top: -5 },
      )
      // motionSensor gray out
      .to(
        aside.motionSensor.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // thermometer highlight
      .to(
        aside.thermometer.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        mods.charger.root.current,
        {
          left: 0,
          top: window.innerHeight / 2,
        },
        { display: "block", duration: 4, top: -5 },
      )
      // thermometer gray out
      .to(
        aside.thermometer.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // charger highlight
      .to(
        aside.charger.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        mods.microController.root.current,
        {
          top: window.innerHeight / 2,
        },
        { display: "block", duration: 4, top: -99 },
      )
      // charger gray out
      .to(
        aside.charger.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // microController highlight
      .to(
        aside.microController.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .fromTo(
        mods.ledMatrix.root.current,
        {
          left: 87.334,
          zIndex: 2,
          y: -window.innerHeight / 2,
        },
        { display: "block", duration: 4, y: -69 },
      )
      // microController gray out
      .to(
        aside.microController.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      // ledMatrix highlight
      .to(
        aside.ledMatrix.current,
        {
          opacity: 1,
          duration: 3,
          color: "#00cf80",
        },
        "-=3",
      )
      .to(animationFooter.current, {
        onComplete: () => setMode("complete"),
        onReverseComplete: () => setMode("build"),
        duration: 0,
      })
      .to(
        [pinMasks.pushbutton1.current, pinMasks.pushbutton2.current, pinMasks.motionSensor.current],
        {
          display: "none",
          duration: 0,
        },
      )
      // ledMatrix gray out
      .to(
        aside.ledMatrix.current,
        {
          duration: 3,
          color: "#555",
        },
        "-=3",
      )
      .fromTo(
        subtitle1.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        subtitle2.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        laptop.current,
        {
          display: "none",
          opacity: 0,
          left: -window.innerWidth / 2,
        },
        {
          display: "block",
          duration: 6,
          opacity: 1,
          left: -600,
        },
      )
      .fromTo(
        subtitle2.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        subtitle3.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        cable.current,
        {
          display: "none",
          opacity: 0,
        },
        {
          display: "block",
          opacity: 1,
          duration: 4,
        },
      )
      .to(laptop.current, {
        duration: 2,
        onComplete: () => setLaptopStage(1),
        onReverseComplete: () => setLaptopStage(0),
      })
      .to(laptop.current, {
        duration: 2,
        onComplete: () => setLaptopStage(2),
        onReverseComplete: () => setLaptopStage(1),
      })
      .to(laptop.current, {
        duration: 2,
        onComplete: () => setLaptopStage(3),
        onReverseComplete: () => setLaptopStage(2),
      })
      .fromTo(
        cable.current,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 2,
        },
      )
      .fromTo(
        laptop.current,
        {
          display: "block",
          opacity: 1,
          left: -600,
        },
        {
          duration: 6,
          display: "none",
          opacity: 0,
          left: -window.innerWidth / 2,
        },
      )
      .fromTo(
        subtitle3.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        subtitle4.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        device.current,
        {
          top: 0,
        },
        {
          top: 120,
        },
      )
      .fromTo(
        subtitle4.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        subtitle5.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        [motherboardTransparent.current, motherboardSolid.current],
        { display: "block" },
        {
          duration: 0,
          display: "none",
        },
      )
      .fromTo(
        [motherboardTop.current, motherboardBottom.current],
        {
          display: "none",
        },
        {
          duration: 0,
          display: "block",
        },
      )
      .fromTo(
        motherboardTop.current,
        {},
        {
          duration: 0,
          top: -95,
        },
      )
      .fromTo(
        motherboardBottom.current,
        {},
        {
          duration: 0,
          top: -66,
        },
      )
      .fromTo(
        mods.motionSensor.pin4.current,
        { opacity: 1, duration: 0 },
        { opacity: 0, duration: 0 },
      )
      .fromTo(
        [
          motherboardTop.current,
          mods.pushbutton1.root.current,
          mods.pushbutton2.root.current,
          mods.motionSensor.shieldBottom.current,
        ],
        { y: 0 },
        {
          y: -135,
        },
      )
      .fromTo(mods.ledMatrix.root.current, { y: -69 }, { y: -204, duration: 4 }, "<")
      .fromTo(
        [pcb4.root.current, pcb4.title.current],
        {
          display: "none",
          y: 20,
        },
        {
          display: "block",
          y: -10,
        },
        "-=3.5",
      )
      .fromTo(
        mods.motionSensor.shieldBottom.current,
        { display: "none", top: -40 },
        { display: "block", top: -40 },
        "-=4.5",
      )
      .fromTo(
        mods.motionSensor.base.current,
        { display: "none", y: -35 },
        { display: "block", y: -230, duration: 4 },
        "-=4.5",
      )
      .fromTo(
        mods.motionSensor.pin21.current,
        { display: "none", y: -35 },
        { display: "block", y: -265, duration: 4 },
        "-=4.5",
      )

      .fromTo(
        subtitle5.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        subtitle6.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        mods.motionSensor.baseTitle.current,
        { display: "none", y: -200, x: 180, opacity: 0 },
        { display: "block", opacity: 1 },
        "<",
      )
      .fromTo(
        mods.motionSensor.shieldTitle.current,
        { display: "none", y: -240, x: 170, opacity: 0 },
        { display: "block", opacity: 1 },
        "<",
      )
      .fromTo(
        [pcb4.root.current, pcb4.title.current],
        {
          opacity: 1,
          x: 0,
        },
        {
          x: -window.innerWidth / 2,
          opacity: 0,
          duration: 8,
        },
      )
      .fromTo(
        [pcb21.root.current, pcb21.title.current],
        {
          opacity: 0,
          x: window.innerWidth / 2,
        },
        {
          x: 0,
          opacity: 1,
          duration: 8,
        },
        "<",
      )
      .addLabel("mvp", "-=8")
      .fromTo(
        motherboardShadow.current,
        {
          display: "block",
          opacity: 1,
        },
        { display: "none", opacity: 0 },
        "<",
      )
      .fromTo(
        [
          motherboardTop.current,
          motherboardBottom.current,
          mods.motionSensor.shieldBottom.current,
          mods.motionSensor.base.current,
          mods.motionSensor.baseTitle.current,
          mods.motionSensor.shieldTitle.current,
          mods.charger.pin4.current,
          mods.pulsoximeter.pin4.current,
          mods.thermometer.pin4.current,
          mods.ledMatrix.pin4.current,
          mods.pushbutton1.pin4.current,
          mods.pushbutton2.pin4.current,
          mods.microController.pin4.current,
        ],
        {
          opacity: 1,
          display: "block",
        },
        {
          opacity: 0,
          display: "none",
        },
        "-=4",
      )
      .fromTo(
        [
          mods.charger.pin21.current,
          mods.pulsoximeter.pin21.current,
          mods.thermometer.pin21.current,
          mods.ledMatrix.pin21.current,
          mods.pushbutton1.pin21.current,
          mods.pushbutton2.pin21.current,
          mods.microController.pin21.current,
        ],
        {
          opacity: 0,
          display: "none",
        },
        {
          opacity: 1,
          display: "block",
        },
        "-=4",
      )
      .fromTo(
        mods.motionSensor.pin21.current,
        { duration: 0 },
        {
          duration: 1,
          onComplete: () => mods.motionSensor.setColor("green"),
          onReverseComplete: () => mods.motionSensor.setColor("blue"),
        },
        "-=3",
      )
      .fromTo(
        mods.motionSensor.pin21.current,
        {
          y: -265,
        },
        {
          y: -170,
        },
        "-=2",
      )
      .fromTo(
        mods.ledMatrix.root.current,
        {
          y: -204,
        },
        {
          y: -77,
        },
      )
      .fromTo(
        [mods.pushbutton1.root.current, mods.pushbutton2.root.current],
        { y: -135 },
        { y: 0, duration: 4 },
        "<",
      )
      .fromTo(mods.motionSensor.pin21.current, { y: -170 }, { y: -35, duration: 4 }, "<")
      .fromTo(
        [mods.pulsoximeter.root.current, mods.charger.root.current, mods.thermometer.root.current],
        { y: 0 },
        { y: -63, duration: 4 },
        "<",
      )
      .fromTo(
        pcb21.title.current,
        {
          opacity: 1,
          display: "block",
        },
        {
          opacity: 0,
          display: "none",
        },
        "<",
      )
      .fromTo(
        pcb21.root.current,
        {},
        {
          onComplete: () => pcb21.setColor("green"),
          onReverseComplete: () => pcb21.setColor("blue"),
          duration: 1,
        },
        "-=1",
      )
      .fromTo(
        subtitle6.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "-=0.5",
      )
      .fromTo(
        subtitle7.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
      )
      .fromTo(
        subtitle7.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "+=2",
      )
      .fromTo(
        subtitle8.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "-=0.5",
      )
      .addLabel("massProduction")
      .fromTo(
        [
          mods.ledMatrix.component.current,
          mods.pushbutton1.component.current,
          mods.pushbutton2.component.current,
          mods.motionSensor.component.current,
          mods.pulsoximeter.component.current,
          mods.microController.component.current,
          mods.thermometer.component.current,
          mods.charger.component.current,
        ],
        {
          opacity: 0,
          display: "none",
        },
        { opacity: 1, display: "block", duration: 1 },
      )
      .fromTo(
        [
          mods.ledMatrix.component.current,
          mods.pushbutton1.component.current,
          mods.pushbutton2.component.current,
          mods.motionSensor.component.current,
        ],
        {
          y: 0,
        },
        { y: -120, duration: 4 },
        "<",
      )
      .fromTo(
        [
          mods.pulsoximeter.component.current,
          mods.microController.component.current,
          mods.thermometer.component.current,
          mods.charger.component.current,
        ],
        {
          y: 0,
        },
        { y: 120, duration: 4 },
        "<",
      )
      .fromTo(
        [
          mods.charger.pin21.current,
          mods.thermometer.pin21.current,
          mods.pulsoximeter.pin21.current,
          mods.pushbutton1.pin21.current,
          mods.pushbutton2.pin21.current,
          mods.motionSensor.pin21.current,
          mods.ledMatrix.pin21.current,
          mods.microController.pin21.current,
        ],
        { display: "block", opacity: 1 },
        { display: "none", opacity: 0, duration: 4 },
        "<",
      )
      .fromTo(
        [pcb21.root.current],
        {
          opacity: 1,
          x: 0,
        },
        {
          opacity: 0,
          x: -window.innerWidth / 2,
          duration: 8,
        },
        "<0.5",
      )
      .fromTo(
        pcbFinal.current,
        {
          opacity: 0,
          x: window.innerWidth / 2,
        },
        {
          x: 0,
          opacity: 1,
          duration: 8,
        },
      )
      .fromTo(
        [
          mods.ledMatrix.component.current,
          mods.pushbutton1.component.current,
          mods.pushbutton2.component.current,
          mods.motionSensor.component.current,
        ],
        {
          y: -120,
        },
        { y: 0, duration: 4 },
      )
      .fromTo(
        [
          mods.pulsoximeter.component.current,
          mods.microController.component.current,
          mods.thermometer.component.current,
          mods.charger.component.current,
        ],
        {
          y: 120,
        },
        { y: 0, duration: 4 },
        "<",
      )
      .fromTo(
        renderMask.current,
        {
          display: "none",
          bottom: -(window.innerHeight / 2) + 30,
          top: window.innerHeight / 2 + 26,
        },
        {
          display: "block",
          top: -(window.innerHeight / 2 + 26),
          duration: 12,
        },
      )
      .fromTo(
        subtitle8.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
        "<",
      )
      .fromTo(
        subtitle9.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
        "<2",
      )
      .fromTo(
        subtitle9.current,
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
      )
      .fromTo(
        subtitle10.current,
        {
          opacity: 0,
          display: "none",
          top: -310,
        },
        {
          opacity: 1,
          display: "block",
          duration: 2,
        },
      )
      .fromTo(
        [stage1Renders["0"], stage1Renders["1"], stage1Renders["2"], stage1Renders["3"]],
        {
          opacity: 0,
          visibility: "hidden",
        },
        { opacity: 1, visibility: "visible", duration: 4 },
      )
      .fromTo(
        [
          subtitle10.current,
          text.current,
          smallScreenTextRefs.text.current,
          smallScreenTextRefs.devkits.current,
        ],
        {
          display: "block",
        },
        {
          display: "none",
          opacity: 0,
          duration: 2,
        },
      )
      .fromTo(
        [
          ".pcb-row-0",
          ".pcb-row--1",
          ".pcb-row-1",
          stage2Renders["0"],
          stage2Renders["1"],
          stage2Renders["2"],
        ],
        {},
        { opacity: 1, visibility: "visible", duration: 4 },
      )
      .fromTo([".pcb-row"], {}, { opacity: 1, visibility: "visible", duration: 4 })
      .from(
        finalScene.current,
        {
          y: window.innerHeight,
          duration: 12,
        },
        "+=1",
      )
      .from(
        finalScene.current,
        {
          opacity: 0,
        },
        "<",
      )
      .to(animationFooter.current, { duration: 4 });

    setSt(
      ScrollTrigger.create({
        // markers: true,
        animation: tl,
        trigger: container.current,
        start: `-26 center`,
        end: String(ANIMATION_RANGE),
        scrub: true,
        pin: true,
        onUpdate: () => {
          setLabel(tl.previousLabel());
        },
      }),
    );

    return () => {
      if (st) {
        st.kill();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const images = {
    pushbutton: { complete: pushButtonGreenImg, build: pushButtonImg }[mode],
    pulsoximeter: { complete: pulsoximeterGreenImg, build: pulsoximeterImg }[mode],
    motionSensor: { complete: motionSensorGreenImg, build: motionSensorImg }[mode],
    thermometer: { complete: thermometerGreenImg, build: thermometerImg }[mode],
    charger: { complete: chargerGreenImg, build: chargerImg }[mode],
    microController: { complete: microControllerGreenImg, build: microControllerImg }[mode],
    ledMatrix: { complete: ledMatrixGreenImg, build: ledMatrixImg }[mode],
  };

  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
          transition: "top 200ms",
        }}
        ref={navbar}
      />
      <div style={{ paddingTop: 80 }}>
        <div className={cx("ginora", styles.container)} style={{ marginBottom: ANIMATION_RANGE }}>
          <Media query="(max-width: 600px)">
            {isSmallScreen => {
              const finalPcbWidth = (() => {
                const multiplier = isSmallScreen ? 0.7 : 1;
                return 580 * multiplier;
              })();
              return (
                <div ref={container}>
                  <div ref={scene1} className={styles.wrapper} style={{ width: window.innerWidth }}>
                    <aside
                      className={styles.componentsAside}
                      ref={aside.root}
                      style={{ display: isSmallScreen ? "none" : undefined }}
                    >
                      <strong>Components:</strong>
                      <Spacer space={100} />
                      <div className={styles.asideItem} ref={aside.ledMatrix}>
                        LED matrix
                      </div>
                      <div className={styles.asideItem} ref={aside.motionSensor}>
                        motion sensor
                      </div>
                      <div className={styles.asideItem} ref={aside.pushbutton}>
                        pushbutton x2
                      </div>
                      <hr />
                      <div className={styles.asideItem} ref={aside.motherboard}>
                        motherboard
                      </div>
                      <hr />
                      <div className={styles.asideItem} ref={aside.pulsoximeter}>
                        pulsoximeter
                      </div>
                      <div className={styles.asideItem} ref={aside.thermometer}>
                        thermometer
                      </div>
                      <div className={styles.asideItem} ref={aside.charger}>
                        charger
                      </div>
                      <div className={styles.asideItem} ref={aside.microController}>
                        microcontroller
                      </div>
                    </aside>
                    {isSmallScreen ? (
                      <SmallScreenText refs={smallScreenTextRefs} />
                    ) : (
                      <div ref={text} className={cx(styles.text, "d-flex")}>
                        <div ref={from} className={styles.idea}>
                          from{" "}
                        </div>
                        <div ref={idea} className={styles.idea}>
                          idea
                        </div>{" "}
                        <div ref={toMassProduction}>to mass production</div>
                        <div ref={toCreate} className={styles.toCreate}>
                          : to create a
                        </div>
                        <div ref={devKit1} className={styles.devkit}>
                          gameboy
                        </div>
                        <div ref={devKit2} className={styles.devkit}>
                          weather station
                        </div>
                        <div ref={devKit3} className={styles.devkit}>
                          UV light disinfector
                        </div>
                        <div ref={devKit4} className={styles.devkit}>
                          smart house controller
                        </div>
                        <div ref={devKit5} className={styles.devkit}>
                          any hardware device
                        </div>
                        <div ref={devKit6} className={styles.devkit}>
                          baby monitor
                        </div>
                      </div>
                    )}
                    <div ref={subtitle1} className={styles.subtitle}>
                      First choose modules you need. You can find them in store or start a project
                      in IDE
                    </div>
                    <div ref={subtitle2} className={styles.subtitle}>
                      When you're done with components, it's time to code some firmware. Use our
                      code editor
                    </div>
                    <div ref={subtitle3} className={styles.subtitle}>
                      We keep it simple. Just plug and code.
                    </div>
                    <div ref={subtitle4} className={styles.subtitle}>
                      It's working! Once you're done with coding, your prototype is ready to test.
                    </div>
                    <div ref={subtitle5} className={styles.subtitle}>
                      Let's find out what's inside
                    </div>
                    <div ref={subtitle6} className={styles.subtitle}>
                      For MVP just change PCB board from 4pin (basic) to 21pin (pro). It's a simple
                      way to increase production.
                    </div>
                    <div ref={subtitle7} className={styles.subtitle}>
                      MVP is ready. Up to 100 units in less than 4 weeks.
                    </div>
                    <div ref={subtitle8} className={styles.subtitle}>
                      From now on the way to mass production is easy.
                    </div>
                    <div ref={subtitle9} className={styles.subtitle}>
                      Final PCB is ready
                    </div>
                    <div ref={subtitle10} className={styles.subtitle}>
                      You can now produce thousands of units
                    </div>
                    <div
                      ref={device}
                      className="position-absolute"
                      style={{
                        height: BOARD_WIDTH,
                        width: BOARD_WIDTH,
                        transform: isSmallScreen ? "scale(0.7)" : undefined,
                      }}
                    >
                      <img
                        src={motherboardShadowImg}
                        ref={motherboardShadow}
                        alt=""
                        className={cx(styles.motherboardShadow)}
                      />
                      <Mod
                        refs={mods.microController}
                        image4={images.microController}
                        image21={shieldMicrocontrollerGreenImg}
                        imageComponent={componentMicrocontrollerImg}
                        variant="open"
                        style={{ left: 85 }}
                        size={4}
                        componentWidth={63.115}
                      />
                      {/* // THERMOMETER // */}
                      <Mod
                        refs={mods.thermometer}
                        image4={images.thermometer}
                        image21={shieldThermometerGreenImg}
                        imageComponent={componentThermometerImg}
                        variant="closed"
                        size={1}
                        componentWidth={40}
                      />
                      {/* // CHARGER // */}
                      <Mod
                        refs={mods.charger}
                        image4={images.charger}
                        image21={shieldChargerGreenImg}
                        imageComponent={componentChargerImg}
                        variant="closed"
                        size={2}
                        componentWidth={120}
                      />
                      {/* // PULSOXIMETER // */}
                      <Mod
                        refs={mods.pulsoximeter}
                        image4={images.pulsoximeter}
                        image21={shieldPulsoximeterGreenImg}
                        imageComponent={componentPulsoximeterImg}
                        variant="closed"
                        style={{ left: 249 }}
                        size={2}
                        componentWidth={143}
                      />
                      <div
                        ref={motherboard}
                        className={styles.motherboardContainer}
                        style={{ height: BOARD_WIDTH, width: BOARD_WIDTH }}
                      >
                        <img
                          ref={motherboardTransparent}
                          src={motherboardImg}
                          alt=""
                          className={styles.motherboard}
                          style={{ width: BOARD_WIDTH }}
                        />
                        <img
                          src={motherboardSolidImg}
                          ref={motherboardSolid}
                          alt=""
                          className={styles.motherboard}
                          style={{ width: BOARD_WIDTH }}
                        />
                        <img
                          ref={motherboardBottom}
                          src={motherboardBottomImg}
                          alt=""
                          style={{ width: BOARD_WIDTH, height: BOARD_WIDTH }}
                          className={styles.motherboardSolidBottom}
                        />
                        <img
                          src={pcb4PinBlueImg}
                          ref={pcb4.root}
                          alt=""
                          className={styles.pcb}
                          style={{
                            width: BOARD_WIDTH * 0.9,
                            left: (BOARD_WIDTH - BOARD_WIDTH * 0.9) / 2,
                          }}
                        />
                        <Pcb21
                          color={pcb21.color}
                          ref={pcb21.root}
                          className={styles.pcb}
                          style={{
                            width: BOARD_WIDTH * 0.9,
                            left: (BOARD_WIDTH - BOARD_WIDTH * 0.9) / 2,
                          }}
                        />
                        <div
                          ref={pcb4.title}
                          className={cx("position-absolute", styles.modTitle)}
                          style={{ color: "#5048e2", top: 105, right: -110 }}
                        >
                          4 pin PCB board
                        </div>
                        <img
                          src={pcbFinalImg}
                          ref={pcbFinal}
                          alt=""
                          className={styles.pcb}
                          style={{
                            width: BOARD_WIDTH * 0.9 - 50,
                            left: (BOARD_WIDTH - BOARD_WIDTH * 0.9 + 50) / 2,
                          }}
                        />
                        <div
                          ref={pcb21.title}
                          className={cx("position-absolute", styles.modTitle)}
                          style={{ color: "#5048e2", top: 115, right: -110 }}
                        >
                          21 pin PCB board
                        </div>
                        <img
                          ref={motherboardTop}
                          src={motherboardTopSolidImg}
                          alt=""
                          style={{ width: BOARD_WIDTH, height: BOARD_WIDTH }}
                          className={styles.motherboardSolidTop}
                        />
                      </div>
                      {/* // PUSHBUTTON 1 // */}
                      <Mod
                        refs={mods.pushbutton1}
                        image4={images.pushbutton}
                        image21={shieldPushbuttonGreenImg}
                        imageComponent={componentPushbuttonImg}
                        variant="open"
                        style={{ zIndex: 2, left: 249 }}
                        size={1}
                        componentWidth={63.115}
                      />
                      <PinMask1 ref={pinMasks.pushbutton1} />
                      {/* // PUSHBUTTON 2 // */}
                      <Mod
                        refs={mods.pushbutton2}
                        image4={images.pushbutton}
                        image21={shieldPushbuttonGreenImg}
                        imageComponent={componentPushbuttonImg}
                        variant="open"
                        style={{ zIndex: 2, left: 87 }}
                        size={1}
                        componentWidth={63.115}
                      />
                      <PinMask2 ref={pinMasks.pushbutton2} />
                      <Mod
                        refs={mods.motionSensor}
                        image4={images.motionSensor}
                        image21=""
                        imageComponent={componentMotionSensorImg}
                        variant="open"
                        size={1}
                        componentWidth={80}
                        style={{ zIndex: 1 }}
                      />

                      <img
                        ref={pinMasks.motionSensor}
                        className="position-absolute"
                        src={pinMask3Img}
                        alt=""
                        style={{
                          top: -61,
                          left: 236,
                          zIndex: 2,
                        }}
                      />
                      {/* // LED_MATRIX // */}
                      <Mod
                        refs={mods.ledMatrix}
                        image4={images.ledMatrix}
                        image21={shieldLedMatrixGreenImg}
                        imageComponent={componentLedMatrixImg}
                        variant="open"
                        size={4}
                        componentWidth={216.347}
                      />

                      <Laptop ref={laptop} stage={laptopStage} className={styles.laptop} />
                      <img src={cableImg} ref={cable} alt="" className={styles.cable} />
                    </div>
                    <div
                      className="position-absolute"
                      style={{
                        transform: `translate(${isSmallScreen ? -58 : -83}px, ${
                          isSmallScreen ? 110 : 0
                        }px) ${isSmallScreen ? "scale(0.7)" : ""}`,
                        zIndex: 1,
                      }}
                    >
                      <img
                        src={shieldBottomImg}
                        alt=""
                        ref={mods.motionSensor.shieldBottom}
                        className={cx(styles.img)}
                        style={{ width: MODULE_1_WIDTH, zIndex: 1 }}
                      />
                      <img
                        src={baseImg}
                        alt=""
                        ref={mods.motionSensor.base}
                        className={cx(styles.img, styles.base)}
                      />
                      <MotionSensor
                        color={mods.motionSensor.color}
                        ref={mods.motionSensor.pin21}
                        alt=""
                        className={cx(styles.motionSensor21Pin, styles.img)}
                        style={{ width: SHIELD_1_WIDTH, left: 18 }}
                      />
                      <div
                        ref={mods.motionSensor.shieldTitle}
                        className={cx("position-absolute", styles.modTitle)}
                        style={{ color: "#5048e2" }}
                      >
                        shield
                      </div>
                      <div
                        ref={mods.motionSensor.baseTitle}
                        className={cx("position-absolute", styles.modTitle)}
                        style={{ color: "#00abff" }}
                      >
                        base
                      </div>
                    </div>
                    {!isSmallScreen && <Timeline stage={currentLabel} scroll={st?.scroll} />}
                    <div ref={animationFooter} />
                  </div>
                  <div
                    ref={renderMask}
                    className={styles.renderMask}
                    style={{
                      top: window.innerHeight / 2 + 26 - 29,
                      marginBottom: window.innerHeight / 2 - 185,
                      backgroundSize: finalPcbWidth,
                    }}
                  >
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={-3}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={-2}
                      refCallback={(index, position, ref) => {
                        if (position === 0) {
                          stage2Renders["0"] = ref;
                        }
                      }}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={-1}
                      refCallback={(index, position, ref) => {
                        if (position === -1) {
                          stage1Renders["3"] = ref;
                        }
                      }}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={0}
                      refCallback={(index, position, ref) => {
                        if (position === 1) {
                          stage1Renders["2"] = ref;
                        }
                      }}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={1}
                      refCallback={(index, position, ref) => {
                        if (position === -1) {
                          stage1Renders["0"] = ref;
                        } else if (position === 0) {
                          stage1Renders["1"] = ref;
                        }
                      }}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={2}
                      refCallback={(index, position, ref) => {
                        if (position === 0) {
                          stage2Renders["1"] = ref;
                        } else if (position === 1) {
                          stage2Renders["2"] = ref;
                        }
                      }}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={3}
                    />
                    <FinalPcbRow
                      isSmallScreen={isSmallScreen}
                      itemWidth={finalPcbWidth}
                      offset={4}
                    />
                  </div>
                  <FinalScene ref={finalScene} />
                </div>
              );
            }}
          </Media>
          <div className={styles.initMask} ref={initMask} />
          {/* <DevelopmentHelpLines /> */}
        </div>
      </div>
    </>
  );
};

const Mod = ({
  refs = required("refs"),
  image4 = required("image4"),
  image21 = required("image21"),
  imageComponent,
  size = required("size"),
  variant = required("variant"),
  componentWidth,
  style,
}) => {
  const shieldSizeMap = {
    4: SHIELD_4_WIDTH,
    2: SHIELD_2_WIDTH,
    1: SHIELD_1_WIDTH,
  };
  const modSizeMap = {
    4: MODULE_4_WIDTH,
    2: MODULE_2_WIDTH,
    1: MODULE_1_WIDTH,
  };
  return (
    <div ref={refs.root} className={styles.mod} style={style}>
      <img src={image4} ref={refs.pin4} alt="" style={{ width: modSizeMap[size] }} />
      {imageComponent && (
        <img
          src={imageComponent}
          ref={refs.component}
          alt=""
          style={{
            width: componentWidth,
            left: (modSizeMap[size] - componentWidth) / 2,
            top:
              ((modSizeMap[size] - componentWidth) * Y_X_RATIO) / 2 - (variant === "open" ? 11 : 0),
            zIndex: 1,
          }}
        />
      )}
      <img
        ref={refs.pin21}
        src={image21}
        alt=""
        style={{
          width: shieldSizeMap[size],
          display: "none",
          margin: SHIELD_MARGIN,
          top: variant === "open" ? -11 : 0,
        }}
      />
    </div>
  );
};

/**
 * display module's render lines.
 */
// const DevelopmentHelpLines = () => {
//   const h = window.innerHeight;
//   return (
//     <>
//       <hr
//         style={{ position: "fixed", top: h / 2, zIndex: 9999, borderColor: "red", width: "100%" }}
//       />
//       <hr
//         style={{
//           position: "fixed",
//           top: h / 2 + 24,
//           zIndex: 9999,
//           borderColor: "blue",
//           width: "100%",
//         }}
//       />
//       <hr
//         style={{
//           position: "fixed",
//           top: h / 2 + 255 - 70 - 255,
//           zIndex: 9999,
//           borderColor: "violet",
//           width: "100%",
//         }}
//       />
//       <hr
//         style={{
//           position: "fixed",
//           top: h / 2 + 255 - 70,
//           zIndex: 9999,
//           borderColor: "purple",
//           width: "100%",
//         }}
//       />
//     </>
//   );
// };
