import React from "react";
import { BackArrow } from "components/BackArrow/BackArrow";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import cx from "classnames";
import { FileInput, FileInputTemplate } from "components/FileInput";
import { FormInput } from "components/FormInput/Controlled";
import { Spacer } from "components/Spacer";
import { patchUser, resetPassword } from "api/rest";
import { useToastr } from "components/Toastr/useToastr";
import { useAuth, useAuthorizedUser } from "App";
import { noop } from "utilities";
import { useAlertModal } from "components/Alert/Alert";
import checkIcon from "assets/images/icon_check-green-56px.svg";
import { AsyncStatus } from "components/AsyncStatus/AsyncStatus";
import { useAsyncField } from "hooks/useAsyncField";
import styles from "./Profile.module.scss";

export const Profile = () => {
  const auth = useAuth();
  const [user, updateUser] = useAuthorizedUser();
  const toastr = useToastr();

  async function handleChangeAvatar(file) {
    const formData = new FormData();
    formData.append("avatar", file);
    const [res, error] = await patchUser(formData, auth.userId);
    if (res) {
      updateUser({ avatar: res.avatar });
    } else {
      toastr.open({ type: "failure", text: error?.message || "An error occurred" });
    }
  }

  const [submitFirstName, firstNameMeta] = useAsyncField(
    value => patchUser({ firstName: value }, auth.userId),
    {
      onSuccess: payload => updateUser({ firstName: payload.firstName }),
      onFailure: error =>
        toastr.open({ type: "failure", text: error?.firstName || "An error occurred" }),
    },
  );
  const [submitLastName, lastNameMeta] = useAsyncField(
    value => patchUser({ lastName: value }, auth.userId),
    {
      onSuccess: payload => updateUser({ lastName: payload.lastName }),
      onFailure: error =>
        toastr.open({ type: "failure", text: error?.lastName || "An error occurred" }),
    },
  );

  const alert = useAlertModal();

  async function handleResetPassword(asyncStatuses) {
    asyncStatuses.setFetching(true);
    const [, error] = await resetPassword({ email: user.email });
    asyncStatuses.setFetching(false);
    if (!error) {
      alert.open({
        heading: (
          <span className="d-flex align-items-end">
            <img className={styles.doneIcon} src={checkIcon} alt="" />
            Done!
          </span>
        ),
        body: (
          <span className={styles.alertBody}>
            We've sent an email to <strong>{user.email}</strong> with further instructions.
          </span>
        ),
      });
    } else {
      toastr.open({ type: "failure", text: error.message || "An error occurred" });
    }
  }
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <Container className="mt-3" style={{ paddingTop: 120 }}>
        <Row className="pl-3 pr-3">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <BackArrow />
            <h1 className={styles.h1}>Account Settings</h1>
            <Spacer space={10} />
            {user ? (
              <div className={cx(styles.card, "px-4 py-4 d-flex flex-column")}>
                <div className="text-center">
                  <FileInput
                    fileType="image"
                    initialValue={user.avatar}
                    onError={err => toastr.open({ type: "failure", text: err.text })}
                    onChange={handleChangeAvatar}
                  >
                    {props => <FileInputTemplate {...props} name="avatar" />}
                  </FileInput>
                </div>
                <Spacer space={20} />
                <FormInput
                  initialValue={user.email}
                  label="Email"
                  onChange={noop}
                  disabled={true}
                  overrides={{ input: { style: { color: "#333" } } }}
                />
                <Spacer space={20} />
                <FormInput
                  initialValue={user.firstName}
                  label="First name"
                  onChange={submitFirstName}
                  disabled={firstNameMeta.inProgress}
                />
                <FormInput.Error text={firstNameMeta.displayError("firstName")} />
                <Spacer space={20} />
                <FormInput
                  initialValue={user.lastName}
                  label="Last name"
                  onChange={submitLastName}
                  disabled={lastNameMeta.inProgress}
                />
                <FormInput.Error text={lastNameMeta.displayError("lastName")} />
                <Spacer space={20} />
                <AsyncStatus>
                  {statuses => (
                    <button
                      type="button"
                      className={cx("btn", styles.linkGreen)}
                      onClick={() => handleResetPassword(statuses)}
                      disabled={statuses.isFetching}
                    >
                      Change password
                    </button>
                  )}
                </AsyncStatus>
              </div>
            ) : (
              <div
                className={cx(styles.card, "px-4 py-4 d-flex flex-column")}
                style={{ height: 447 }}
              />
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};

// const context = createContext({ user: null });

// const Storage = ({ children }) => {
//   const auth = useAuth();
//   const [user, setUser] = useState(null);
//   const [meta, setMeta] = useState({ isFetching: false, error: null });
//   const isBusy = useRef(false);
//   const startFetching = useCallback(() => {
//     setMeta(s => ({ ...s, isFetching: true }));
//     isBusy.current = true;
//   }, []);
//   const stopFetching = useCallback(error => {
//     setMeta(s => ({ ...s, isFetching: false, error }));
//     isBusy.current = false;
//   }, []);

//   const getData = useCallback(async () => {
//     if (isBusy.current) return;
//     if (user !== null) return;
//     const [userPayload] = await getUser(auth.userId);
//     if (userPayload) {
//       setUser(userPayload);
//     }
//   }, [auth.userId, user]);

//   const value = useMemo(
//     () => ({ user, meta, setMeta, setUser, startFetching, stopFetching, getData }),
//     [user, meta, setMeta, setUser, startFetching, stopFetching, getData],
//   );
//   return <context.Provider value={value}>{children}</context.Provider>;
// };

// function useUser2() {
//   const { meta, setMeta, setUser, user, getData } = useContext(context);

//   useEffect(() => {
//     getData();
//   }, [auth.userId, getData]);
// }
