import React, { useState, useContext, useEffect, useRef } from "react";
import { Route } from "react-router-dom";
import Home from "pages/Home/Home";
import { LoginModal } from "components/LoginModal";
import { RegisterModal } from "components/RegisterModal";
import { shopContext } from "App";
import { keycloakAuthorize } from "api/rest";
import { useUrlQuery } from "components/QueryProvider";
import { AuthMessage } from "./AuthMessage";

export const Authorize = () => {
  const { auth } = useContext(shopContext);
  const { query } = useUrlQuery();
  const { client_id, redirect_uri, scope, state, response_type, prompt, nonce } = query;
  const [status, setStatus] = useState("standby"); // standby | inProgress | error
  const [viewType, setViewType] = useState("login"); // login | register
  const toggleViewType = () =>
    setViewType(() => {
      if (viewType === "login") return "register";
      if (viewType === "register") return "login";
    });
  const initialMount = useRef(true);

  useEffect(() => {
    async function authorize() {
      setStatus("inProgress");
      const [res] = await keycloakAuthorize({
        clientId: client_id,
        redirectUri: redirect_uri,
        state,
        scope,
        responseType: response_type,
        prompt,
        nonce,
      });
      if (res) {
        window.location.href = res.redirectUri;
      } else {
        setStatus("error");
      }
    }
    if (auth.isAuthenticated && initialMount.current) {
      authorize();
    }
  }, [client_id, redirect_uri, auth.isAuthenticated, state, scope, response_type, prompt, nonce]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  if (auth.isAuthenticated && status === "inProgress")
    return <AuthMessage message="Authorizing in progress" />;
  if (auth.isAuthenticated && status === "error")
    return <AuthMessage message="An error occurred. Cannot authorize." />;
  if (!auth.isAuthenticated) {
    return (
      <>
        <Route component={Home} />
        <LoginModal
          isOpen={viewType === "login"}
          auth
          keycloakMode={true}
          toggleViewType={toggleViewType}
          keycloakData={{
            clientId: client_id,
            redirectUri: redirect_uri,
            state,
            scope,
            responseType: response_type,
            prompt,
            nonce,
          }}
          keycloakErrorCallback={() => setStatus("error")}
        />
        <RegisterModal
          isOpen={viewType === "register"}
          keycloakMode={true}
          toggleViewType={toggleViewType}
        />
      </>
    );
  }
  return null;
};
