import React, { useCallback, useEffect, useState } from "react";
import Navbar from "components/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import exampleImg from "assets/images/e21.png";
import arrowImg from "assets/images/arrowBlue.png";
// import video from "assets/videos/e2_how-it-works_animation_final.mp4";
import cx from "classnames";
import { articleSelector } from "api/selectors";
import { useQuery } from "@apollo/react-hooks";
import { ABOUT_US } from "api/queries";
import styles from "./AboutUs.module.scss";

function useActiveSection(initTrigger, setActiveElement) {
  useEffect(() => {
    if (!initTrigger) return;
    const observer = new IntersectionObserver(
      function cb(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting === true) {
            setActiveElement(entry.target.id, true);
          } else {
            setActiveElement(entry.target.id, false);
          }
        });
      },
      { threshold: [0.1] },
    );

    observer.observe(document.querySelector("#what-is-electronsquare-section"));
    observer.observe(document.querySelector("#how-to-use-shields-section"));
    observer.observe(document.querySelector("#what-can-you-build-section"));
    observer.observe(document.querySelector("#what-functions-we-offer-section"));
  }, [initTrigger, setActiveElement]);
}

export const AboutUs = () => {
  const { data } = useQuery(ABOUT_US);
  const article = articleSelector(data);
  const [activeStatus, setActiveElements] = useState({
    "what-is-electronsquare-section": false,
    "how-to-use-shields-section": false,
    "what-can-you-build-section": false,
    "what-functions-we-offer-section": false,
  });
  const setActiveElement = useCallback((el, val) => setActiveElements(s => ({ ...s, [el]: val })), [
    setActiveElements,
  ]);
  useActiveSection(Boolean(data), setActiveElement);
  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div style={{ paddingTop: 120 }}>
        <Container>
          <Row className="pt-5">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <div className={styles.navigation}>
                <a
                  className={cx({
                    [styles.activeBtn]: activeStatus["what-is-electronsquare-section"] === true,
                  })}
                  href="#what-is-electronsquare"
                >
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>What is Electron Square?</span>
                </a>
                <a
                  href="#how-to-use-shields"
                  className={cx({
                    [styles.activeBtn]: activeStatus["how-to-use-shields-section"] === true,
                  })}
                >
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>How to use shields?</span>
                </a>
                <a
                  href="#what-can-you-build"
                  className={cx({
                    [styles.activeBtn]: activeStatus["what-can-you-build-section"] === true,
                  })}
                >
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>What can you build with e2?</span>
                </a>
                <a
                  href="#what-functions-we-offer"
                  className={cx({
                    [styles.activeBtn]: activeStatus["what-functions-we-offer-section"] === true,
                  })}
                >
                  <span className={styles.arrowImg}>
                    <img src={arrowImg} alt="" />
                  </span>
                  <span>What functions we offer?</span>
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className={styles.content}>
                {/* eslint-disable */}
                {/* it's not dangerous because of sanitization inside the articleSelector */}
                <div dangerouslySetInnerHTML={{ __html: article?.contentHtml }} />
                {/* eslint-enable */}
                {/* <h4>What is Electron Square?</h4>
                <div className="position-relative">
                  <span id="what-is-electronsquare" className={styles.headingAnchor} />
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias exercitationem
                  laborum necessitatibus nulla error similique, facilis magni omnis maiores
                  molestias? Ducimus odio magnam id aliquid repellat, placeat velit vitae excepturi?
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ad vitae, sint
                  facere, delectus aut deleniti inventore eum unde vel, aliquam accusantium placeat
                  voluptatum aliquid quam nisi sit quas saepe! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Sapiente itaque corporis quidem similique
                  molestias, possimus atque officiis nesciunt id, accusamus eius aliquam tempore
                  porro! Velit architecto placeat eaque expedita ipsum.
                </p>
                <div className={styles.imageBox}>
                  <div className="d-flex justify-content-center">
                    <video controls className={styles.video}>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <h4>How to use shields?</h4>
                <div className="position-relative">
                  <span id="how-to-use-shields" className={styles.headingAnchor} />
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias exercitationem
                  laborum necessitatibus nulla error similique, facilis magni omnis maiores
                  molestias? Ducimus odio magnam id aliquid repellat, placeat velit vitae excepturi?
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ad vitae, sint
                  facere, delectus aut deleniti inventore eum unde vel, aliquam accusantium placeat
                  voluptatum aliquid quam nisi sit quas saepe! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Sapiente itaque corporis quidem similique
                  molestias, possimus atque officiis nesciunt id, accusamus eius aliquam tempore
                  porro! Velit architecto placeat eaque expedita ipsum.
                </p>
                <div className={styles.imageBox}>
                  <img src={exampleImg} alt="" />
                </div> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
