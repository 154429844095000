/**
 * Function returns color contrasted to background color.
 * It can be used to adjust font color to the background or opposite.
 */
export function getContrastedColor(toContrast: string, options = {}) {
  if (!toContrast) {
    return "#fff";
  }
  const defaultOptions = { darkColor: "#383838", lightColor: "#f0f0f0" };
  const { darkColor, lightColor } = { ...defaultOptions, ...options };

  const color = toContrast.charAt(0) === "#" ? toContrast.substring(1, 7) : toContrast;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uiColors = [r / 255, g / 255, b / 255];
  const c = uiColors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.175 ? darkColor : lightColor;
}

export function isPasswordStrong(password) {
  function hasAtLeastOneDigit(pswd) {
    return [...pswd].some(letter => isNaN(Number(letter)) === false);
  }
  function hasAtLeastOneSpecialSign(pswd) {
    const signs = ["#", "@", "$", "%", "^", "&", "*", "(", ")", "_", "~", "!"];
    return [...pswd].some(letter => signs.some(sign => sign === letter));
  }
  return (
    password.length > 0 &&
    password.toLowerCase() !== password &&
    password.toUpperCase() !== password &&
    hasAtLeastOneDigit(password) &&
    hasAtLeastOneSpecialSign(password)
  );
}

/**
 * Use as a function default argument to ensure that you have passed all required arguments
 * @param {string} name
 */
export function required(name) {
  throw new Error(`parameter ${name} is required!`);
}

export function noop() {}

export const cookie = {
  /**
   * Set cookie available in the entire domain
   * @param {string} name
   * @param {string} value
   * @param {number} days
   */
  set(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      "; path=/; domain=electronsquare.com; samesite=strict; Secure";
  },
  /**
   * @param {string} name
   */
  get(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  /**
   * @param {string} name
   */
  erase(name) {
    document.cookie =
      name + "=; domain=electronsquare.com; Max-Age=-99999999; samesite=strict; Secure";
  },
};
