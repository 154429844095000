import React from "react";
import { Modal } from "components/Modal/Modal";
import { FormInput } from "components/FormInput/Controlled";
import { Spacer } from "components/Spacer";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { resetPassword } from "api/rest";
import { useAlertModal } from "components/Alert/Alert";
import checkIcon from "assets/images/icon_check-green-56px.svg";
import styles from "./PasswordResetEmailModal.module.scss";

const resolver = data => {
  const errors = {};
  if (data.email.length === 0) {
    errors.email = "Please provide your email";
  }
  const hasError = Object.keys(errors).length > 0;
  return {
    values: hasError ? {} : data,
    errors,
  };
};

export const PasswordResetEmailModal = ({ isOpen, close }) => {
  const { register, handleSubmit, errors, formState, setError } = useForm({
    resolver,
    defaultValues: { email: "" },
    mode: "onBlur",
  });
  const alert = useAlertModal();

  const onSubmit = async values => {
    const [, error] = await resetPassword({
      email: values.email,
    });
    if (error) {
      setError("general", {
        message: error.message || error.nonFieldErrors || "An error occurred.",
      });
    } else {
      close();
      alert.open({
        heading: (
          <span className="d-flex align-items-end">
            <img className={styles.doneIcon} src={checkIcon} alt="" />
            Done!
          </span>
        ),
        body: (
          <span className={styles.alertBody}>
            We've sent an email to <strong>{values.email}</strong> with further instructions.
          </span>
        ),
      });
    }
  };

  return (
    <Modal isOpen={isOpen} close={close} size="auto">
      <Modal.Body title="Password reset" overwrites={{ content: { className: styles.content } }}>
        <p className={styles.subtitle}>
          Enter your email below and we'll send you instructions to reset your password.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput.Error text={errors.general?.message} />
          {errors.general && <Spacer space={30} />}
          <FormInput label="Email address" ref={register} name="email" />
          <FormInput.Error text={errors.email} />

          <Spacer space={30} />
          <Button
            align="center"
            padding="large"
            className="w-100"
            type="submit"
            disabled={formState.isSubmitting}
          >
            SUBMIT
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};
