import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "components/Navbar";
import { useForm, FormProvider } from "react-hook-form";
import { useAlertModal } from "components/Alert/Alert";
import { patchDraftDevkit } from "api/rest";
import { FormInput } from "components/FormInput/Controlled";
import { Spacer } from "components/Spacer";
import Button from "react-bootstrap/Button";
import checkIcon from "assets/images/icon_check-green-56px.svg";
import { BackArrow } from "components/BackArrow/BackArrow";
import { useDraftDevkit, useProject } from "api/restApiHooks";
import styles from "./DevkitForm.module.scss";

const resolver = data => {
  const errors = {};
  if (data.name.length === 0) errors.name = "Devkit must have a name";
  if (data.price.length === 0) errors.price = "Devkit must have a price";
  if (data.excerpt.length === 0) errors.excerpt = "Devkit must have an excerpt";
  if (data.description.length === 0) errors.description = "Devkit must have an description";
  const hasError = Object.keys(errors).length > 0;
  return {
    values: hasError ? {} : data,
    errors: Object.entries(errors).reduce((acc, [key, error]) => {
      acc[key] = { message: error };
      return acc;
    }, {}),
  };
};

const defaultValues = { name: "", price: "", excerpt: "", description: "" };
/**
 * @param {Object} props
 * @param {Object} props.match
 * @param {{draftDevkitId: string, projectId:string}} props.match.params
 */
export const DevkitForm = ({ match, history }) => {
  const [draftDevkit] = useDraftDevkit(match.params.draftDevkitId);
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setError,
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    resolver,
    defaultValues,
    mode: "all",
  });
  const alert = useAlertModal();

  const name = watch("name");
  const excerpt = watch("excerpt");
  const description = watch("description");
  const price = watch("price");

  const [project] = useProject(match.params.projectId);
  useEffect(() => {
    if (draftDevkit) {
      reset({
        name: draftDevkit.name,
        price: draftDevkit.price,
        excerpt: draftDevkit.excerpt,
        description: draftDevkit.description,
      });
    }
  }, [draftDevkit, reset]);

  const onSubmit = async values => {
    const [, error] = await patchDraftDevkit(match.params.draftDevkitId, {
      ...values,
      moderationVisible: "visible",
      status: project.status === "shared" ? "in-moderation" : undefined,
    });
    if (error) {
      Object.entries(error).map(([name, message]) => setError(name, { message }));
      setError("general", {
        message: error.message || error.nonFieldErrors || error.detail || "An error occurred.",
      });
    } else {
      history.push("/my-projects");
      alert.open({
        heading: (
          <span className="d-flex align-items-end">
            <img className={styles.doneIcon} src={checkIcon} alt="" />
            Done!
          </span>
        ),
        body: (
          <span className={styles.alertBody}>
            Devkit has been sent to moderation. It will be shared public if it fits our Quality
            Standards.
          </span>
        ),
      });
    }
  };

  return (
    <>
      <Navbar
        theme="light"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      />
      <div style={{ paddingTop: 120 }}>
        <Container>
          <Row className="pl-3 pr-3">
            <div className="col-sm-12 col-md-7 col-lg-5 pt-3">
              <BackArrow />

              <div className="d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
                <h1 className={styles.heading}>Share your project</h1>
              </div>
              <Spacer space={10} />
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider setValue={setValue} getValues={getValues}>
                      <FormInput.Error text={errors.general?.message} />
                      {errors.general && <Spacer space={30} />}
                      <FormInput label="Name" ref={register} name="name" formWatched value={name} />
                      <FormInput.Error text={errors.name?.message} />
                      <Spacer space={20} />
                      <FormInput
                        label="Price"
                        ref={register}
                        name="price"
                        formWatched
                        value={price}
                      />
                      <FormInput.Error text={errors.price?.message} />
                      <Spacer space={20} />
                      <FormInput
                        as="textarea"
                        label="Excerpt"
                        ref={register}
                        name="excerpt"
                        formWatched
                        value={excerpt}
                      />
                      <FormInput.Error text={errors.excerpt?.message} />
                      <Spacer space={20} />
                      <FormInput
                        as="textarea"
                        label="Description"
                        ref={register}
                        name="description"
                        formWatched
                        value={description}
                      />
                      <FormInput.Error text={errors.description?.message} />

                      <Spacer space={30} />
                      <Button
                        align="center"
                        padding="large"
                        className="w-100"
                        type="submit"
                        disabled={formState.isSubmitting}
                      >
                        SHARE PROJECT
                      </Button>
                    </FormProvider>
                  </form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
