import React from "react";
import PT from "prop-types";

const CartSvg = ({ variant, style, className }) => {
  const color = {
    dark: "#383838",
    light: "#ffffff",
    blue: "rgb(80,72,226)",
  }[variant];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      style={{ isolation: "isolate", ...style }}
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="24" height="24" fill="none" />
        </clipPath>
      </defs>
      <g id="icon_shopping-cart_dark-bgn_24px" clipPath="url(#clip-path)">
        <path
          id="Path_317"
          data-name="Path 317"
          d="M23.514,15.7H36.65a2.71,2.71,0,0,0,2.7-2.7V7.407a.062.062,0,0,0,0-.029c0-.01,0-.024,0-.034s0-.02,0-.029a.311.311,0,0,1-.01-.034c0-.01,0-.02-.01-.029s0-.02-.01-.029a.239.239,0,0,1-.015-.034.107.107,0,0,0-.015-.024l-.015-.029c0-.01-.01-.015-.015-.024s-.015-.02-.02-.029a.086.086,0,0,0-.02-.024c0-.01-.015-.015-.02-.024s-.015-.015-.02-.024-.015-.015-.02-.02l-.025-.024c-.01,0-.015-.015-.024-.02s-.02-.015-.029-.02l-.024-.015a.1.1,0,0,1-.029-.02l-.029-.015-.029-.015-.029-.015c-.01,0-.02,0-.029-.01a.091.091,0,0,0-.034-.01.212.212,0,0,1-.024,0,.108.108,0,0,0-.039,0s-.01,0-.02,0l-16.648-2.3V2.189a.3.3,0,0,0,0-.069.037.037,0,0,0,0-.02c0-.015,0-.029,0-.044s0-.024-.01-.039,0-.015,0-.024l-.015-.044s0-.015,0-.02a.147.147,0,0,0-.02-.039s0-.015-.01-.02a.1.1,0,0,0-.02-.029c0-.01-.01-.015-.015-.024s-.01-.015-.015-.024-.015-.02-.02-.029l-.015-.015-.029-.029L21.932,1.7a.213.213,0,0,0-.034-.029s-.015-.01-.02-.015-.02-.015-.029-.024a.452.452,0,0,0-.039-.024s-.01,0-.015-.01l-.064-.029L18.12.053a.662.662,0,1,0-.514,1.22l3.2,1.352V17.117a2.708,2.708,0,0,0,2.357,2.68,2.669,2.669,0,1,0,4.958,1.372,2.62,2.62,0,0,0-.367-1.347h5.948a2.633,2.633,0,0,0-.367,1.347,2.67,2.67,0,1,0,2.67-2.67H23.514a1.383,1.383,0,0,1-1.382-1.382V15.324A2.735,2.735,0,0,0,23.514,15.7Zm3.292,5.468a1.347,1.347,0,1,1-1.347-1.347A1.35,1.35,0,0,1,26.807,21.164Zm10.553,0a1.347,1.347,0,1,1-1.347-1.347A1.35,1.35,0,0,1,37.36,21.164Zm-.71-6.791H23.514a1.383,1.383,0,0,1-1.382-1.382V5.844l15.9,2.19v4.953A1.384,1.384,0,0,1,36.65,14.374Z"
          transform="translate(-16.277 0)"
          fill={color}
        />
        <rect id="Rectangle_11" data-name="Rectangle 11" width="24" height="24" fill="none" />
      </g>
    </svg>
  );
};

CartSvg.defaultProps = {
  style: {},
};
CartSvg.propTypes = {
  variant: PT.oneOf(["dark", "light", "blue"]).isRequired,
  style: PT.shape(),
};

export default CartSvg;
