import React, { Component, forwardRef } from "react";
import cx from "classnames";
import styles from "./FileInput.module.scss";

const previewIcon = "";

type Error = {
  text: string,
  type: "size",
};

interface Props {
  initialValue?: string | File;
  value?: string | File;
  onChange: (arg: File | null) => void;
  onClick?: () => void;
  onError?: (err: Error) => void | Promise<void>;
  fileType: "image" | "any";
  name: string;
  /**
   * Toggles the X button to clear image
   */
  clearable?: boolean;
}

interface State {
  file?: File | null;
  imagePreviewUrl?: string | null;
}

const fileSizeLimit = 307_200; // 300kB

export class FileInput extends Component<Props, State> {
  inputFileRef = React.createRef();

  constructor(props) {
    super();
    const initializeState = () => {
      const { value, initialValue } = props;
      if (typeof value === "string") return value;
      if (typeof initialValue === "string") return initialValue;
      return undefined;
    };
    this.state = {
      file: undefined,
      imagePreviewUrl: initializeState(),
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;
    if (initialValue instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          file: initialValue,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(initialValue);
    }
  }

  componentDidUpdate(oldProps: Props) {
    const { value } = this.props;
    if (oldProps.value !== value) {
      this.setState({ imagePreviewUrl: value });
    }
  }

  handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, onError } = this.props;
    e.preventDefault();
    const reader = new FileReader();

    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > fileSizeLimit) {
        if (onError) {
          onError({
            text: `File size cannot be larger than ${fileSizeLimit / 1024}kB`,
            type: "size",
          });
        }
        return;
      }
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      if (onChange) {
        onChange(file);
      }
    }
  };

  checkIsImage = fileName => {
    if (typeof fileName !== "string") return false;
    const imageTypes = ["jpg", "jpeg", "png", "image"];
    return imageTypes.some(type => fileName.includes(type));
  };

  handleFileType = () => {
    const { file, imagePreviewUrl } = this.state;
    const { fileType } = this.props;
    if (
      fileType === "image" ||
      (file && this.checkIsImage(file.type)) ||
      this.checkIsImage(imagePreviewUrl)
    ) {
      return true;
    }
    return false;
  };

  handleDeleteFile = () => {
    this.setState({ file: null, imagePreviewUrl: null });
    const { onChange } = this.props;
    if (onChange) {
      onChange(null);
    }
    // the only way to clear input file
    if (this.inputFileRef.current) {
      this.inputFileRef.current.type = "";
      this.inputFileRef.current.type = "file";
    }
  };

  render() {
    const { onClick, clearable = true, children } = this.props;
    const { file, imagePreviewUrl } = this.state;
    const isImage = this.handleFileType();
    return children({
      imagePreviewUrl,
      fileType: isImage ? "image" : "file",
      clearable,
      file,
      deleteFile: this.handleDeleteFile,
      onClick,
      onChange: this.handleImageChange,
      ref: this.inputFileRef,
    });
  }
}

export const FileInputTemplate = forwardRef(
  ({ imagePreviewUrl, fileType, onClick, onChange, name }, ref) => {
    return (
      <div className={cx(styles.container, "d-inline-flex")}>
        {imagePreviewUrl ? (
          <div className={styles.chip}>
            <div className={styles.chipContent}>
              {fileType === "image" && (
                <div className={styles.chipImage}>
                  <img className={styles.avatarThumb} src={imagePreviewUrl} alt="" />
                </div>
              )}
              {/* <div className={styles.chipName}>
                <span>{file ? file.name : imagePreviewUrl}</span>
              </div> */}
            </div>
            {/* {clearable && (
              <button className={styles.deleteBtn} type="button" onClick={deleteFile}>
                <img src={xIcon} alt="" />
              </button>
            )} */}
          </div>
        ) : (
          <div className={styles.previewBox}>
            <img src={previewIcon} alt="" />
          </div>
        )}
        <div className={styles.inputField} onClick={onClick}>
          <input name={name} type="file" onChange={onChange} ref={ref} />
          <div className={styles.uploadBtn}>
            <span>add picture</span>
          </div>
        </div>
      </div>
    );
  },
);
