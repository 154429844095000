import { DialogOverlay, DialogContent } from "@reach/dialog";
import React, { useContext } from "react";
import closeIcon from "assets/images/close.svg";
import Button from "react-bootstrap/Button";
import PT from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { shopContext } from "App";
import { obtainToken, keycloakAuthorize } from "api/rest";
import {
  GithubLoginButton,
  GoogleLoginButton,
  // FacebookLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";
import { Spacer } from "components/Spacer";
import { FormInput } from "components/FormInput/Controlled";
import { FormInputUncontrolled } from "components/FormInput/Uncontrolled";
import { noop } from "utilities";
import styles from "./LoginModal.module.scss";

const resolver = data => {
  const errors = {};
  if (data.email.length === 0) {
    errors.email = "Please provide the email address";
  }
  if (data.password.length === 0) {
    errors.password = "Please provide the password";
  } else if (data.password.length <= 6) {
    errors.password = "The password is too short";
  }
  const hasError = Object.keys(errors).length > 0;
  return {
    values: hasError ? {} : data,
    errors: Object.entries(errors).reduce((acc, [key, error]) => {
      acc[key] = { message: error };
      return acc;
    }, {}),
  };
};

export const LoginModal = ({
  isOpen,
  close = noop,
  keycloakMode,
  keycloakData,
  keycloakErrorCallback,
  toggleViewType,
  openPasswordResetEmailModal,
}) => {
  const { login, registerModal } = useContext(shopContext);
  // const [createAccessToken, { data }] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE);
  // const accessTokenErrors = data?.customerAccessTokenCreate.customerUserErrors ?? [];
  const { register, handleSubmit, errors, setValue, formState, setError } = useForm({
    resolver,
    defaultValues: { email: "", password: "" },
    // mode: "onBlur",
  });

  const onSubmit = async values => {
    const restPromise = obtainToken({ email: values.email, password: values.password });
    const [tokens] = await restPromise;
    if (!tokens) {
      setError("credentials", { message: "Invalid credentials" });
    } else {
      login({ tokens });
      if (keycloakMode) {
        const [res] = await keycloakAuthorize(keycloakData);
        if (res) {
          window.location.href = res.redirectUri;
        } else {
          keycloakErrorCallback();
        }
      } else {
        close();
      }
    }
  };
  return (
    <DialogOverlay isOpen={isOpen} onDismiss={close} style={{ zIndex: 3 }}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <DialogContent aria-label="Module details" className={styles.dialogContent}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.headline}>Login</div>
                {keycloakMode === false && (
                  <button className={styles.closeModalBtn} type="button" onClick={close}>
                    <img src={closeIcon} alt="" />
                  </button>
                )}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider setValue={setValue}>
                  <FormInput.Error text={errors.credentials?.message} />
                  <Spacer space={25} />
                  <FormInputUncontrolled
                    focusOnMount
                    variant="large"
                    label="Email"
                    ref={register}
                    name="email"
                    type="email"
                  />
                  <FormInput.Error text={errors.email?.message} />
                  <Spacer space={25} />
                  <FormInputUncontrolled
                    variant="large"
                    label="Password"
                    ref={register}
                    name="password"
                    type="password"
                  />
                  <FormInput.Error text={errors.password?.message} />
                  <Spacer space={25} />
                  <button
                    type="button"
                    className={styles.link}
                    onClick={openPasswordResetEmailModal}
                  >
                    Forgot password
                  </button>
                  <div className="pt-3">
                    <Button
                      align="center"
                      padding="large"
                      className="w-100"
                      type="submit"
                      disabled={formState.isSubmitting}
                    >
                      LOG IN
                    </Button>
                  </div>
                </FormProvider>
              </form>
              <Spacer space={20} />
              <GithubLoginButton
                type="button"
                onClick={() => {
                  window.location.href =
                    "https://github.com/login/oauth/authorize/?client_id=7110fdcba1034c8cebbb&state=af0ifjsldkj&prompt=consent&scope=user:email";
                }}
                className="w-100 mx-0 my-0"
              />
              <GoogleLoginButton
                onClick={() => {
                  window.location.href =
                    "https://accounts.google.com/o/oauth2/auth?client_id=806632265345-0jk46qh9d3lbjjieavp309f7o3b899ib.apps.googleusercontent.com&scope=openid%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&redirect_uri=https://shop.ide.electronsquare.com/social-login/google-oauth2&response_type=code&prompt=consent";
                }}
                className="w-100 mx-0 mb-0 mt-1"
              />
              <MicrosoftLoginButton
                onClick={() => {
                  window.location.href =
                    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=b6de11de-0508-4ee9-8872-1fae1dcda269&response_type=code&redirect_uri=https://shop.ide.electronsquare.com/social-login/azuread-oauth2&scope=openid email profile";
                }}
                className="w-100 mx-0 mb-0 mt-1"
              />
              {/* <FacebookLoginButton
                onClick={() => {
                  window.location.href =
                    "https://graph.facebook.com/oauth/authorize?redirect_uri=https://shop.ide.electronsquare.com/social-login/facebook&client_id=139700320933202&scope=email";
                }}
                className="w-100 mx-0 mb-0 mt-1"
              /> */}
            </div>
            <div className={`${styles.footer} d-flex align-items-center justify-content-between`}>
              <div>You don't have an account yet?</div>
              <button
                className={styles.link}
                onClick={() => {
                  if (keycloakMode) {
                    toggleViewType();
                  } else {
                    close();
                    registerModal.open();
                  }
                }}
                type="button"
              >
                Register
              </button>
            </div>
          </DialogContent>
        </div>
      </div>
    </DialogOverlay>
  );
};
LoginModal.propTypes = {
  isOpen: PT.bool.isRequired,
  close: PT.func,
  toggleViewType: PT.func,
  keycloakMode: PT.bool.isRequired,
  keycloakErrorCallback: PT.func,
  keycloakData: PT.shape({ clientId: PT.string.isRequired, redirectUri: PT.string.isRequired }),
};
