import React, { useContext } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import cx from "classnames";
import { FormRadio } from "components/Radio";
import Button from "react-bootstrap/Button";
import closeIcon from "assets/images/close.svg";
import { useForm } from "react-hook-form";
import { postProject } from "api/rest";
import { shopContext } from "App";
import styles from "./Form.module.scss";

const resolver = data => {
  const errors = {};
  if (!data.interface) {
    errors.interface = "Please choose an interface";
  }
  if (data.name.length === 0) {
    errors.name = "Project needs a name";
  }
  if (!data.language) {
    errors.language = "Please choose a language";
  }
  return {
    values: data,
    errors,
  };
};
const defaultValues = { interface: "", name: "", language: "C++" };

export const Form = ({ close, isOpen, submitCallback }) => {
  const { auth } = useContext(shopContext);
  const { register, handleSubmit, errors, setError, formState, reset } = useForm({
    resolver,
    defaultValues,
  });

  const onSubmit = async values => {
    const [res, err] = await postProject({
      name: values.name,
      interface: values.interface,
      keycloakAccessToken: auth.keycloakAccessToken,
      keycloakRefreshToken: auth.keycloakRefreshToken,
      programmingLanguage: values.language,
    });
    if (res) {
      submitCallback();
      close();
    } else if (err) {
      err.message = err.message || "Cannot create new project.";
      const errors = Object.entries(err);
      errors.map(([name, message]) => setError(name, { message }));
    }
  };

  function handleClose() {
    reset();
    close();
  }
  return (
    <DialogOverlay isOpen={isOpen} onDismiss={handleClose} style={{ zIndex: 3 }}>
      <DialogContent aria-label="Module details" className={styles.dialogContent}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.headline}>New Project</div>
            <button className={styles.closeModalBtn} type="button" onClick={handleClose}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.validationMessage}`}>Invalid credentials</div>
            <div className={cx("pb-2", styles.inputGroup)}>
              <label className={styles.label} htmlFor="">
                Project name
              </label>
              <input className={styles.textInput} ref={register} type="text" name="name" />
              <Error errors={errors} name="name" />
            </div>
            <div className={cx(styles.inputGroup, "my-0")}>
              <label className={styles.label} htmlFor="">
                Interface
              </label>
              <div>
                <FormRadio name="interface" value="4" label="4 pin" ref={register} />
                <FormRadio name="interface" value="21" label="21 pin" ref={register} />
              </div>
              <Error errors={errors} name="interface" />
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label} htmlFor="">
                Programming language
              </label>
              <div>
                <FormRadio
                  name="language"
                  value="c++"
                  label="C++"
                  ref={register}
                  className="d-flex"
                />
                <FormRadio
                  name="language"
                  value="MicroPython"
                  label="MicroPython"
                  ref={register}
                  className="d-flex"
                />
              </div>
              <Error errors={errors} name="language" />

              {/* <div>
                    <div className="d-flex align-items-center">
                      <div className={styles.disabled}>
                        <Radio value="MicroPython" label="MicroPython" />
                      </div>
                      <span className={styles.info}>coming soon</span>
                    </div>
                  </div> */}
            </div>
            <Error errors={errors} name="message" />
            <div className="pt-3">
              <Button
                align="center"
                padding="large"
                type="submit"
                disabled={formState.isSubmitting}
              >
                CREATE PROJECT
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

const Error = ({ errors, name }) => {
  const errorString = typeof errors[name] === "object" ? errors[name].message : errors[name];
  return (
    <div
      className={cx(styles.validationMessage, {
        [styles.validationMessageVisible]: Boolean(errors[name]),
      })}
    >
      {errorString}
    </div>
  );
};
