import React, { forwardRef } from "react";
import { required } from "utilities";

/**
 * @param {Object} props
 * @param {"green" | "blue"} props.color
 */
export const Pcb21 = forwardRef(({ color = required("color"), style, className }, ref) => {
  const chosenColor = {
    green: "#00cf80",
    blue: "#5048e2",
  }[color];
  return (
    <svg
      ref={ref}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 437.34 252.933"
    >
      <g id="_21pin-pcb-blue" data-name="21pin-pcb-blue" transform="translate(-177.782 -267.318)">
        <path
          id="Path_7414"
          data-name="Path 7414"
          d="M614.117,388.4,396.088,514.283,178.806,389.237,401.919,267.89Z"
          fill={chosenColor}
          stroke={chosenColor}
          strokeMiterlimit={10}
          strokeWidth={1}
        />
        <path
          id="Path_7415"
          data-name="Path 7415"
          d="M178.806,389.237v4.813L396.088,519.1,614.117,393.218V388.4"
          fill="none"
          stroke={chosenColor}
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <g id="Group_1624" data-name="Group 1624">
          <g id="Group_1583" data-name="Group 1583">
            <g id="Group_1579" data-name="Group 1579">
              <path
                id="Path_7416"
                data-name="Path 7416"
                d="M394.33,496.174l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7417"
                data-name="Path 7417"
                d="M386.528,492.12l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7418"
                data-name="Path 7418"
                d="M379.613,487.91l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7419"
                data-name="Path 7419"
                d="M372.725,483.775l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7420"
                data-name="Path 7420"
                d="M366.487,479.865l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7421"
                data-name="Path 7421"
                d="M359.815,475.955l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1580" data-name="Group 1580">
              <path
                id="Path_7422"
                data-name="Path 7422"
                d="M434.5,473.073l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7423"
                data-name="Path 7423"
                d="M426.7,469.019l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7424"
                data-name="Path 7424"
                d="M419.779,464.81l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7425"
                data-name="Path 7425"
                d="M412.892,460.674l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7426"
                data-name="Path 7426"
                d="M406.654,456.764l3.333-1.842L406.654,453l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7427"
                data-name="Path 7427"
                d="M399.982,452.854l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1581" data-name="Group 1581">
              <path
                id="Path_7428"
                data-name="Path 7428"
                d="M401.757,496.218l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7429"
                data-name="Path 7429"
                d="M408.429,492.53l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7430"
                data-name="Path 7430"
                d="M414.568,488.687l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7431"
                data-name="Path 7431"
                d="M421.24,485.357l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7432"
                data-name="Path 7432"
                d="M427.265,481.821l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7433"
                data-name="Path 7433"
                d="M433.075,478.726l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1582" data-name="Group 1582">
              <path
                id="Path_7434"
                data-name="Path 7434"
                d="M360.617,470.442l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7435"
                data-name="Path 7435"
                d="M367.289,466.753l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7436"
                data-name="Path 7436"
                d="M373.429,462.911l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7437"
                data-name="Path 7437"
                d="M380.1,459.581l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7438"
                data-name="Path 7438"
                d="M386.126,456.045l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7439"
                data-name="Path 7439"
                d="M391.936,452.95l3.333-1.842-3.333-1.924L388.6,451.1Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7440"
              data-name="Path 7440"
              d="M378.648,463.517l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1588" data-name="Group 1588">
            <g id="Group_1584" data-name="Group 1584">
              <path
                id="Path_7441"
                data-name="Path 7441"
                d="M469.589,453.514l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7442"
                data-name="Path 7442"
                d="M461.787,449.46l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7443"
                data-name="Path 7443"
                d="M454.871,445.25l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7444"
                data-name="Path 7444"
                d="M447.984,441.115l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7445"
                data-name="Path 7445"
                d="M441.746,437.2l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7446"
                data-name="Path 7446"
                d="M435.074,433.295l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1585" data-name="Group 1585">
              <path
                id="Path_7447"
                data-name="Path 7447"
                d="M509.755,430.413l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7448"
                data-name="Path 7448"
                d="M501.953,426.359l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7449"
                data-name="Path 7449"
                d="M495.038,422.149l3.333-1.842-3.333-1.924L491.7,420.3Z"
                fill="#fff"
              />
              <path
                id="Path_7450"
                data-name="Path 7450"
                d="M488.15,418.014l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7451"
                data-name="Path 7451"
                d="M481.913,414.1l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7452"
                data-name="Path 7452"
                d="M475.241,410.194l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1586" data-name="Group 1586">
              <path
                id="Path_7453"
                data-name="Path 7453"
                d="M477.015,453.558l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7454"
                data-name="Path 7454"
                d="M483.687,449.87l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7455"
                data-name="Path 7455"
                d="M489.826,446.027l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7456"
                data-name="Path 7456"
                d="M496.5,442.7l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7457"
                data-name="Path 7457"
                d="M502.524,439.161l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7458"
                data-name="Path 7458"
                d="M508.334,436.066l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1587" data-name="Group 1587">
              <path
                id="Path_7459"
                data-name="Path 7459"
                d="M435.876,427.781l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7460"
                data-name="Path 7460"
                d="M442.548,424.093l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7461"
                data-name="Path 7461"
                d="M448.687,420.25l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7462"
                data-name="Path 7462"
                d="M455.359,416.921l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7463"
                data-name="Path 7463"
                d="M461.384,413.385l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7464"
                data-name="Path 7464"
                d="M467.194,410.29l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7465"
              data-name="Path 7465"
              d="M453.907,420.857l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1593" data-name="Group 1593">
            <g id="Group_1589" data-name="Group 1589">
              <path
                id="Path_7466"
                data-name="Path 7466"
                d="M544.847,410.853l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7467"
                data-name="Path 7467"
                d="M537.045,406.8l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7468"
                data-name="Path 7468"
                d="M530.129,402.59l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7469"
                data-name="Path 7469"
                d="M523.242,398.455l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7470"
                data-name="Path 7470"
                d="M517,394.545l3.333-1.842L517,390.779l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7471"
                data-name="Path 7471"
                d="M510.332,390.635l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1590" data-name="Group 1590">
              <path
                id="Path_7472"
                data-name="Path 7472"
                d="M585.014,387.753l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7473"
                data-name="Path 7473"
                d="M577.212,383.7l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7474"
                data-name="Path 7474"
                d="M570.3,379.489l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7475"
                data-name="Path 7475"
                d="M563.409,375.354l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7476"
                data-name="Path 7476"
                d="M557.171,371.444,560.5,369.6l-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7477"
                data-name="Path 7477"
                d="M550.5,367.534l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1591" data-name="Group 1591">
              <path
                id="Path_7478"
                data-name="Path 7478"
                d="M552.274,410.9l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7479"
                data-name="Path 7479"
                d="M558.946,407.209l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7480"
                data-name="Path 7480"
                d="M565.085,403.367l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7481"
                data-name="Path 7481"
                d="M571.757,400.037l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7482"
                data-name="Path 7482"
                d="M577.782,396.5l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7483"
                data-name="Path 7483"
                d="M583.592,393.406l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1592" data-name="Group 1592">
              <path
                id="Path_7484"
                data-name="Path 7484"
                d="M511.134,385.121l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7485"
                data-name="Path 7485"
                d="M517.806,381.433l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7486"
                data-name="Path 7486"
                d="M523.945,377.59l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7487"
                data-name="Path 7487"
                d="M530.617,374.261l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7488"
                data-name="Path 7488"
                d="M536.643,370.724l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7489"
                data-name="Path 7489"
                d="M542.452,367.629l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7490"
              data-name="Path 7490"
              d="M529.165,378.2l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1598" data-name="Group 1598">
            <g id="Group_1594" data-name="Group 1594">
              <path
                id="Path_7491"
                data-name="Path 7491"
                d="M318.78,452.566l3.333-1.842L318.78,448.8l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7492"
                data-name="Path 7492"
                d="M310.978,448.512l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7493"
                data-name="Path 7493"
                d="M304.062,444.3l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7494"
                data-name="Path 7494"
                d="M297.175,440.167l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7495"
                data-name="Path 7495"
                d="M290.937,436.257l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7496"
                data-name="Path 7496"
                d="M284.265,432.347l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1595" data-name="Group 1595">
              <path
                id="Path_7497"
                data-name="Path 7497"
                d="M358.947,429.465l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7498"
                data-name="Path 7498"
                d="M351.145,425.411l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7499"
                data-name="Path 7499"
                d="M344.229,421.2l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7500"
                data-name="Path 7500"
                d="M337.341,417.066l3.333-1.842-3.333-1.924L334,415.22Z"
                fill="#fff"
              />
              <path
                id="Path_7501"
                data-name="Path 7501"
                d="M331.1,413.156l3.333-1.842L331.1,409.39l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7502"
                data-name="Path 7502"
                d="M324.432,409.246l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1596" data-name="Group 1596">
              <path
                id="Path_7503"
                data-name="Path 7503"
                d="M326.206,452.61l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7504"
                data-name="Path 7504"
                d="M332.878,448.922l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7505"
                data-name="Path 7505"
                d="M339.018,445.079l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7506"
                data-name="Path 7506"
                d="M345.69,441.75l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7507"
                data-name="Path 7507"
                d="M351.715,438.214l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7508"
                data-name="Path 7508"
                d="M357.525,435.118l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1597" data-name="Group 1597">
              <path
                id="Path_7509"
                data-name="Path 7509"
                d="M285.067,426.834l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7510"
                data-name="Path 7510"
                d="M291.739,423.145l3.333-1.842-3.333-1.924L288.4,421.3Z"
                fill="#fff"
              />
              <path
                id="Path_7511"
                data-name="Path 7511"
                d="M297.878,419.3l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7512"
                data-name="Path 7512"
                d="M304.55,415.973l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7513"
                data-name="Path 7513"
                d="M310.575,412.437l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7514"
                data-name="Path 7514"
                d="M316.385,409.342l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7515"
              data-name="Path 7515"
              d="M303.1,419.909l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1603" data-name="Group 1603">
            <g id="Group_1599" data-name="Group 1599">
              <path
                id="Path_7516"
                data-name="Path 7516"
                d="M394.038,409.906l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7517"
                data-name="Path 7517"
                d="M386.236,405.852l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7518"
                data-name="Path 7518"
                d="M379.32,401.642l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7519"
                data-name="Path 7519"
                d="M372.433,397.507l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7520"
                data-name="Path 7520"
                d="M366.2,393.6l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7521"
                data-name="Path 7521"
                d="M359.523,389.687l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1600" data-name="Group 1600">
              <path
                id="Path_7522"
                data-name="Path 7522"
                d="M434.2,386.8l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7523"
                data-name="Path 7523"
                d="M426.4,382.751l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7524"
                data-name="Path 7524"
                d="M419.487,378.542l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7525"
                data-name="Path 7525"
                d="M412.6,374.406l3.333-1.842L412.6,370.64l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7526"
                data-name="Path 7526"
                d="M406.362,370.5l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7527"
                data-name="Path 7527"
                d="M399.69,366.586l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1601" data-name="Group 1601">
              <path
                id="Path_7528"
                data-name="Path 7528"
                d="M401.465,409.95l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7529"
                data-name="Path 7529"
                d="M408.137,406.262l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7530"
                data-name="Path 7530"
                d="M414.276,402.419l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7531"
                data-name="Path 7531"
                d="M420.948,399.09l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7532"
                data-name="Path 7532"
                d="M426.973,395.553l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7533"
                data-name="Path 7533"
                d="M432.783,392.458l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1602" data-name="Group 1602">
              <path
                id="Path_7534"
                data-name="Path 7534"
                d="M360.325,384.174l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7535"
                data-name="Path 7535"
                d="M367,380.485l3.333-1.842L367,376.719l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7536"
                data-name="Path 7536"
                d="M373.136,376.643l3.333-1.842-3.333-1.924L369.8,374.8Z"
                fill="#fff"
              />
              <path
                id="Path_7537"
                data-name="Path 7537"
                d="M379.809,373.313l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7538"
                data-name="Path 7538"
                d="M385.834,369.777l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7539"
                data-name="Path 7539"
                d="M391.643,366.682l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7540"
              data-name="Path 7540"
              d="M378.356,377.249l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1608" data-name="Group 1608">
            <g id="Group_1604" data-name="Group 1604">
              <path
                id="Path_7541"
                data-name="Path 7541"
                d="M469.3,367.246l3.333-1.842L469.3,363.48l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7542"
                data-name="Path 7542"
                d="M461.494,363.192l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7543"
                data-name="Path 7543"
                d="M454.579,358.982l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7544"
                data-name="Path 7544"
                d="M447.691,354.847,451.024,353l-3.333-1.924L444.352,353Z"
                fill="#fff"
              />
              <path
                id="Path_7545"
                data-name="Path 7545"
                d="M441.454,350.937l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7546"
                data-name="Path 7546"
                d="M434.782,347.027l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1605" data-name="Group 1605">
              <path
                id="Path_7547"
                data-name="Path 7547"
                d="M509.463,344.145,512.8,342.3l-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7548"
                data-name="Path 7548"
                d="M501.661,340.091l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7549"
                data-name="Path 7549"
                d="M494.746,335.881l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7550"
                data-name="Path 7550"
                d="M487.858,331.746l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7551"
                data-name="Path 7551"
                d="M481.621,327.836l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7552"
                data-name="Path 7552"
                d="M474.949,323.926l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1606" data-name="Group 1606">
              <path
                id="Path_7553"
                data-name="Path 7553"
                d="M476.723,367.29l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7554"
                data-name="Path 7554"
                d="M483.4,363.6l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7555"
                data-name="Path 7555"
                d="M489.534,359.759l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7556"
                data-name="Path 7556"
                d="M496.206,356.429l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7557"
                data-name="Path 7557"
                d="M502.231,352.893l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7558"
                data-name="Path 7558"
                d="M508.041,349.8l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1607" data-name="Group 1607">
              <path
                id="Path_7559"
                data-name="Path 7559"
                d="M435.584,341.513l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7560"
                data-name="Path 7560"
                d="M442.256,337.825l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7561"
                data-name="Path 7561"
                d="M448.395,333.982l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7562"
                data-name="Path 7562"
                d="M455.067,330.653l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7563"
                data-name="Path 7563"
                d="M461.092,327.117l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7564"
                data-name="Path 7564"
                d="M466.9,324.022l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7565"
              data-name="Path 7565"
              d="M453.614,334.589l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1613" data-name="Group 1613">
            <g id="Group_1609" data-name="Group 1609">
              <path
                id="Path_7566"
                data-name="Path 7566"
                d="M245.51,413.2l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7567"
                data-name="Path 7567"
                d="M237.708,409.148l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7568"
                data-name="Path 7568"
                d="M230.793,404.938l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7569"
                data-name="Path 7569"
                d="M223.905,400.8l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7570"
                data-name="Path 7570"
                d="M217.667,396.893,221,395.051l-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7571"
                data-name="Path 7571"
                d="M211,392.983l3.333-1.842L211,389.217l-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1610" data-name="Group 1610">
              <path
                id="Path_7572"
                data-name="Path 7572"
                d="M285.677,390.1l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7573"
                data-name="Path 7573"
                d="M277.875,386.047l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7574"
                data-name="Path 7574"
                d="M270.96,381.838,274.292,380l-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7575"
                data-name="Path 7575"
                d="M264.072,377.7l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7576"
                data-name="Path 7576"
                d="M257.834,373.792l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7577"
                data-name="Path 7577"
                d="M251.162,369.882l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1611" data-name="Group 1611">
              <path
                id="Path_7578"
                data-name="Path 7578"
                d="M252.937,413.246l3.333-1.842-3.333-1.924L249.6,411.4Z"
                fill="#fff"
              />
              <path
                id="Path_7579"
                data-name="Path 7579"
                d="M259.609,409.558l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7580"
                data-name="Path 7580"
                d="M265.748,405.715l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7581"
                data-name="Path 7581"
                d="M272.42,402.386l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7582"
                data-name="Path 7582"
                d="M278.445,398.849l3.333-1.842-3.333-1.924L275.106,397Z"
                fill="#fff"
              />
              <path
                id="Path_7583"
                data-name="Path 7583"
                d="M284.255,395.754l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1612" data-name="Group 1612">
              <path
                id="Path_7584"
                data-name="Path 7584"
                d="M211.8,387.47l3.333-1.842L211.8,383.7l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7585"
                data-name="Path 7585"
                d="M218.47,383.781l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7586"
                data-name="Path 7586"
                d="M224.609,379.939l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7587"
                data-name="Path 7587"
                d="M231.281,376.609l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7588"
                data-name="Path 7588"
                d="M237.306,373.073l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7589"
                data-name="Path 7589"
                d="M243.116,369.978l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7590"
              data-name="Path 7590"
              d="M229.828,380.545l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1618" data-name="Group 1618">
            <g id="Group_1614" data-name="Group 1614">
              <path
                id="Path_7591"
                data-name="Path 7591"
                d="M320.769,370.542,324.1,368.7l-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7592"
                data-name="Path 7592"
                d="M312.967,366.488l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7593"
                data-name="Path 7593"
                d="M306.051,362.278l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7594"
                data-name="Path 7594"
                d="M299.164,358.143,302.5,356.3l-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7595"
                data-name="Path 7595"
                d="M292.926,354.233l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7596"
                data-name="Path 7596"
                d="M286.254,350.323l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1615" data-name="Group 1615">
              <path
                id="Path_7597"
                data-name="Path 7597"
                d="M360.936,347.441l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7598"
                data-name="Path 7598"
                d="M353.134,343.387l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7599"
                data-name="Path 7599"
                d="M346.218,339.177l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7600"
                data-name="Path 7600"
                d="M339.331,335.042l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7601"
                data-name="Path 7601"
                d="M333.093,331.132l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7602"
                data-name="Path 7602"
                d="M326.421,327.222l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1616" data-name="Group 1616">
              <path
                id="Path_7603"
                data-name="Path 7603"
                d="M328.2,370.586l3.333-1.842L328.2,366.82l-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7604"
                data-name="Path 7604"
                d="M334.867,366.9l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7605"
                data-name="Path 7605"
                d="M341.007,363.055l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7606"
                data-name="Path 7606"
                d="M347.679,359.725l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7607"
                data-name="Path 7607"
                d="M353.7,356.189l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7608"
                data-name="Path 7608"
                d="M359.514,353.094l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1617" data-name="Group 1617">
              <path
                id="Path_7609"
                data-name="Path 7609"
                d="M287.056,344.81l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7610"
                data-name="Path 7610"
                d="M293.728,341.121l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7611"
                data-name="Path 7611"
                d="M299.867,337.279l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7612"
                data-name="Path 7612"
                d="M306.539,333.949l3.333-1.842-3.333-1.924L303.2,332.1Z"
                fill="#fff"
              />
              <path
                id="Path_7613"
                data-name="Path 7613"
                d="M312.564,330.413l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7614"
                data-name="Path 7614"
                d="M318.374,327.318l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7615"
              data-name="Path 7615"
              d="M305.087,337.885l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
          <g id="Group_1623" data-name="Group 1623">
            <g id="Group_1619" data-name="Group 1619">
              <path
                id="Path_7616"
                data-name="Path 7616"
                d="M396.027,327.882l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7617"
                data-name="Path 7617"
                d="M388.225,323.827l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7618"
                data-name="Path 7618"
                d="M381.309,319.618l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7619"
                data-name="Path 7619"
                d="M374.422,315.483l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7620"
                data-name="Path 7620"
                d="M368.184,311.573l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7621"
                data-name="Path 7621"
                d="M361.512,307.663l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1620" data-name="Group 1620">
              <path
                id="Path_7622"
                data-name="Path 7622"
                d="M436.194,304.781l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7623"
                data-name="Path 7623"
                d="M428.392,300.727l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7624"
                data-name="Path 7624"
                d="M421.476,296.517l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7625"
                data-name="Path 7625"
                d="M414.589,292.382l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7626"
                data-name="Path 7626"
                d="M408.351,288.472l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7627"
                data-name="Path 7627"
                d="M401.679,284.562l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1621" data-name="Group 1621">
              <path
                id="Path_7628"
                data-name="Path 7628"
                d="M403.454,327.926l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7629"
                data-name="Path 7629"
                d="M410.126,324.238l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7630"
                data-name="Path 7630"
                d="M416.265,320.4l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7631"
                data-name="Path 7631"
                d="M422.937,317.065l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7632"
                data-name="Path 7632"
                d="M428.962,313.529l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7633"
                data-name="Path 7633"
                d="M434.772,310.434l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <g id="Group_1622" data-name="Group 1622">
              <path
                id="Path_7634"
                data-name="Path 7634"
                d="M362.314,302.149l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7635"
                data-name="Path 7635"
                d="M368.986,298.461l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7636"
                data-name="Path 7636"
                d="M375.125,294.618l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7637"
                data-name="Path 7637"
                d="M381.8,291.289l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7638"
                data-name="Path 7638"
                d="M387.823,287.753l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
              <path
                id="Path_7639"
                data-name="Path 7639"
                d="M393.633,284.658l3.333-1.842-3.333-1.924-3.339,1.92Z"
                fill="#fff"
              />
            </g>
            <path
              id="Path_7640"
              data-name="Path 7640"
              d="M380.345,295.225l-.234,15.693-12.989-7.7Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
