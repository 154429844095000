import React, { useRef, useState, useEffect, forwardRef } from "react";
import cuid from "cuid";
import { noop } from "utilities";
import xIcon from "assets/images/icon_round-cancel-24px.svg";
import cx from "classnames";
import { useFormContext } from "react-hook-form";
import styles from "./FormInput.module.scss";

/**
 *
 * @param {"blur" | "change"} trigger
 * @param {"small" | "large"} variant
 * @param {"input" | "textarea"} as
 * @param {string} subText
 * @param {boolean} focusOnMount
 */
export const FormInput = forwardRef(
  (
    {
      variant = "small",
      label,
      trigger = "blur",
      onChange = noop,
      onBlur = noop,
      name,
      disabled,
      initialValue = "",
      overrides = {},
      type = "text",
      as: As = "input",
      subText,
      tabIndex,
      focusOnMount = false,
      formWatched = false,
      value: val,
    },
    ref,
  ) => {
    const id = useRef(cuid()).current;
    const [value, setValue] = useState(val ?? initialValue);
    const form = useFormContext();
    const inputRef = useRef();
    const initialRender = useRef(true);
    const controlType = form && formWatched ? "form" : "soft-controlled";

    const clear = () => {
      setValue("");
      onChange("");
      if (form) {
        form.setValue(name, "");
      }
    };

    useEffect(() => {
      if (trigger === "change") {
        onChange(value);
      }
    }, [trigger, onChange, value]);

    useEffect(() => {
      if (focusOnMount && initialRender.current) {
        inputRef.current.focus();
      }
    }, [focusOnMount]);

    useEffect(() => {
      initialRender.current = false;
    }, []);

    const handleBlur = () => {
      if (trigger === "blur") {
        onChange(value);
      }
      onBlur(value);
    };

    function handleRef(r) {
      inputRef.current = r;
      if (ref && typeof ref === "function") {
        ref(r);
      }
    }

    return (
      <div className={styles.container}>
        <As
          className={cx(styles.control, overrides.input?.className, {
            [styles.textarea]: As === "textarea",
            [styles.variantLarge]: variant === "large",
            [styles.variantSmall]: variant === "small",
            // [styles.formControlled]: controlType === "form",
          })}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={e => setValue(e.target.value)}
          ref={handleRef}
          style={overrides.input?.style}
          type={type}
          value={controlType === "form" ? form.getValues(name) ?? val ?? "" : val ?? value}
          // data-value={controlType === "form" ? form.getValues(name) : undefined}
          tabIndex={tabIndex}
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {subText && <span className={styles.subText}>{subText}</span>}

        <label
          role="button"
          htmlFor={id}
          className={cx(styles.clearBtn, "btn btn-light")}
          onMouseDown={e => {
            e.preventDefault();
          }}
          onKeyPress={clear}
          onClick={clear}
        >
          <img src={xIcon} alt="" />
        </label>
      </div>
    );
  },
);

function Error({ text = "" }) {
  return (
    <div className={styles.error} style={{ display: text ? "block" : "none" }}>
      {text}
    </div>
  );
}
FormInput.Error = Error;
