import { useRef, useMemo, useEffect, useReducer, useState, useCallback } from "react";
import cuid from "cuid";
import queryString from "query-string";
import { throttle } from "throttle-debounce";

function createReducer(extended) {
  return function reducer(state, action) {
    switch (action.type) {
      case "SAVE":
        return action.payload;
      default:
        return extended && state ? extended(state, action) : state;
    }
  };
}

/**
 * preparing django pagination object
 */
export function getPagination(pagination) {
  if (pagination === null) {
    return {
      next: null,
      prev: null,
      count: null,
      limit: null,
      page: null,
      pageSize: null,
      indexes: [],
    };
  }
  const { next, previous, count = null, limit = null, pageSize: backendPageSize } = pagination;
  function getPageSize() {
    return count && limit ? Math.ceil(count / limit / 10) * 10 : null;
  }
  const nextPage = next ? parseInt(queryString.parse(next).page, 10) : null;
  const prevPage = previous ? parseInt(queryString.parse(previous).page, 10) || 1 : null;
  const page = (function getPageCursor() {
    if (nextPage) return nextPage - 1;
    if (prevPage) return prevPage + 1;
    return 1;
  })();
  const pageSize = backendPageSize || getPageSize();
  const indexes = [];

  if (pageSize) {
    let i = 0;
    for (i; i < pageSize; i += 1) {
      indexes.push(pageSize * (page - 1) + i + 1);
    }
  }

  return {
    next: nextPage,
    prev: prevPage,
    count,
    page,
    limit,
    pageSize,
    indexes,
  };
}

export function createApiHook(func) {
  function useHook(arg, extendedReducer, settings = {}) {
    const skip = settings.skip ?? false;
    const delay = settings.delay ?? 1000;
    const cacheEnabled = settings.cache ?? false;
    const initialState = null;
    const abortToken = useRef(cuid());
    const isMounted = useRef(true);
    const reducer = useMemo(() => createReducer(extendedReducer), [extendedReducer]);
    const [result, dispatch] = useReducer(reducer, initialState);
    const [asyncMeta, setAsyncMeta] = useState({ inProgress: false, error: null });
    const { inProgress, error } = asyncMeta;
    const throttled = useRef(throttle(delay, f => f()));
    const cache = useRef({});
    useEffect(() => {
      if (skip) return;
      async function fetchFunc(param) {
        if (!isMounted.current) return;
        setAsyncMeta(p => ({
          ...p,
          inProgress: true,
        }));
        const dataIsCached = cacheEnabled && cache.current[param];
        if (dataIsCached) {
          dispatch({ type: "SAVE", payload: cache.current[param] });
          return;
        }
        const [payload, err] = await func(param, abortToken.current);
        if (!isMounted.current) return;
        setAsyncMeta(p => ({
          ...p,
          inProgress: false,
          error: err,
        }));

        if (payload) {
          if (cacheEnabled) {
            cache.current[param] = payload;
          }
          dispatch({ type: "SAVE", payload });
        }
      }
      if (!skip) {
        throttled.current(() => fetchFunc(arg));
      }
    }, [arg, skip, cacheEnabled]);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    return [
      result,
      {
        inProgress,
        error,
        dispatch,
        imperativelySetValue: payload => dispatch({ type: "SAVE", payload }),
      },
    ];
  }
  return useHook;
}

export function createApiPaginatedHook(func) {
  function useHook(arg, extendedReducer, settings = {}) {
    const skip = settings.skip ?? false;
    const delay = settings.delay ?? 1000;
    const cacheEnabled = settings.cache ?? false;
    const initialState = [];
    const abortToken = useRef(cuid());
    const [pagination, setPagination] = useState(getPagination(null));
    const reducer = useMemo(() => createReducer(extendedReducer), [extendedReducer]);
    const [result, dispatch] = useReducer(reducer, initialState);
    const [asyncMeta, setAsyncMeta] = useState({ inProgress: false, error: null });
    const [triggerValue, setTriggerValue] = useState(0);
    const triggerReload = useCallback(() => {
      setTriggerValue(s => s + 1);
    }, []);
    const { inProgress, error } = asyncMeta;
    const throttled = useRef(throttle(delay, f => f()));
    const cache = useRef({});

    const clearCache = useCallback(() => {
      cache.current = {};
    }, []);

    useEffect(() => {
      if (skip) return;
      async function fetchFunc(search) {
        setAsyncMeta(p => ({
          ...p,
          inProgress: true,
        }));
        const dataIsCached = cacheEnabled && cache.current[search];
        if (dataIsCached) {
          dispatch({ type: "SAVE", payload: cache.current[search].results });
          setPagination(getPagination(cache.current[search]));
          return;
        }
        const [payload, err] = await func(search, abortToken.current);
        setAsyncMeta(p => ({
          ...p,
          inProgress: false,
          error: err,
        }));
        if (payload) {
          if (cacheEnabled) {
            cache.current[search] = payload;
          }
          dispatch({ type: "SAVE", payload: payload.results });
          setPagination(getPagination(payload));
        }
      }
      if (!skip) {
        throttled.current(() => fetchFunc(arg));
      }
    }, [arg, skip, triggerValue, cacheEnabled]);
    return [
      result,
      {
        inProgress,
        error,
        dispatch,
        pagination,
        triggerReload,
        clearCache,
      },
    ];
  }
  return useHook;
}
