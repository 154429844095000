// @ts-check
import React, { useContext, forwardRef, useRef, useState } from "react";
import PT from "prop-types";
import NavigationBar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import cx from "classnames";
import { shopContext, useAuthorizedUser } from "App";
import { Link, NavLink } from "react-router-dom";
import withMedia from "components/HOC/withMedia";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { MainSearch } from "components/MainSearch/MainSearch";
import avatarImg from "assets/images/account_circle-black-18dp.svg";
import styles from "./styles.module.scss";
import Logo from "./Logo";
import CartSvg from "./Cart";
import MagnifierSvg from "./Magnifier";
import { CartCount } from "./CartCount";

/**
 *
 * @param {Object} props
 * @param {"light" | "dark"} props.theme
 */
const Navbar = forwardRef(({ style, matches: matchesMediaQuery, theme }, ref) => {
  const {
    checkout,
    handleCartOpen,
    loginModal,
    registerModal,
    auth: { isAuthenticated },
    logout,
  } = useContext(shopContext);
  const [user] = useAuthorizedUser();
  const cartCount = checkout.lineItems.reduce((acc, item) => acc + item.quantity, 0);
  const collapsed = matchesMediaQuery === false;
  const mainSearch = useRef();
  const container = useRef();

  function getRightDistance() {
    const searchWindowPadding = 30;
    const containerPadding = 15;
    const distance =
      container.current?.offsetWidth -
      mainSearch.current?.offsetLeft -
      containerPadding -
      searchWindowPadding -
      mainSearch.current?.offsetWidth;
    return isNaN(distance) ? 0 : distance;
  }
  const rightDistance = getRightDistance();
  const leftDistance = 195;

  const [isSearchOpen, setSearchOpen] = useState(false);

  const displayName = user
    ? `${(user.firstName[0] ?? "").toUpperCase()} ${(user.lastName[0] ?? "").toUpperCase()}`
    : "";
  return (
    <div
      ref={ref}
      className={cx(styles.navbarWrapper, {
        [styles.lightTheme]: theme === "light",
        [styles.darkTheme]: theme === "dark",
      })}
      style={style}
    >
      <Container className="position-relative" ref={container}>
        <Row>
          <div className="col-sm-12">
            <NavigationBar expand="lg" className={styles.navbar}>
              <NavigationBar.Brand as={Link} to="/">
                <div className={styles.logo}>
                  <Logo color={theme === "light" ? "000" : "255"} />
                </div>
              </NavigationBar.Brand>
              <div className="d-flex">
                {collapsed && (
                  <Button variant="" type="button" className={styles.cart} onClick={handleCartOpen}>
                    <CartCount theme={theme} count={cartCount} />
                    <CartSvg
                      variant={{ dark: "light", light: "dark" }[theme]}
                      size={30}
                      style={{ width: 20 }}
                    />
                  </Button>
                )}
                <NavigationBar.Toggle aria-controls="basic-navbar-nav" />
              </div>
              <NavigationBar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link
                    as={NavLink}
                    to="/products"
                    className={styles.navLink}
                    activeClassName="active"
                  >
                    <span className="hind">Products</span>
                  </Nav.Link>
                  <Nav.Link
                    to="/how-it-works"
                    as={NavLink}
                    className={styles.navLink}
                    activeClassName="active"
                  >
                    <span className="hind">How it works</span>
                  </Nav.Link>
                  {/* <Nav.Link
                    to="/about-us"
                    as={NavLink}
                    className={styles.navLink}
                    activeClassName="active"
                  >
                    <span className="hind">About us</span>
                  </Nav.Link> */}
                  <div className={cx(styles.lgMargin)}>
                    <Button
                      as="a"
                      href="https://che-che.ide.electronsquare.com/e2editor/"
                      className={styles.appBtn}
                      variant="success"
                    >
                      GO TO APP
                    </Button>
                  </div>
                </Nav>
                <div className={styles.lgMargin}>
                  <Form inline={true} className={styles.form} ref={mainSearch}>
                    <FormControl
                      className={cx(styles.navbarSearch, {
                        [styles.dark]: theme === "dark" && matchesMediaQuery,
                      })}
                      type="text"
                      placeholder="Search"
                      onClick={() => setSearchOpen(true)}
                    />
                    <MagnifierSvg
                      className={styles.magnifier}
                      color={{ dark: "#f2f2f2", light: "#383838" }[collapsed ? "light" : theme]}
                    />
                  </Form>
                </div>
                {!collapsed && (
                  <Button variant="" type="button" className={styles.cart} onClick={handleCartOpen}>
                    <CartCount theme={theme} count={cartCount} />
                    <CartSvg
                      variant={{ dark: "light", light: "dark" }[theme]}
                      size={30}
                      style={{ width: 22 }}
                    />
                  </Button>
                )}
                <div className={cx(styles.lgMargin)}>
                  {!isAuthenticated && (
                    <>
                      <Button variant="link" onClick={loginModal.open}>
                        Log in
                      </Button>
                      <span className={styles.verticalSeparator} />
                      <Button variant="link" onClick={registerModal.open}>
                        Register
                      </Button>
                    </>
                  )}
                  {isAuthenticated && (
                    <NavDropdown
                      title={
                        <div className="d-flex" style={{ minWidth: 60 }}>
                          <img
                            src={user?.avatar || avatarImg}
                            alt=""
                            height="30px"
                            className={cx("mr-1", {
                              [styles.avatarInverted]: theme === "dark" && !user?.avatar,
                              [styles.customAvatar]: Boolean(user?.avatar),
                            })}
                          />
                          <span className="roboto" style={{ marginTop: 3 }}>
                            {displayName}
                          </span>
                        </div>
                      }
                      className={styles.navDropdown}
                    >
                      <NavDropdown.Item as={Link} to="/my-projects">
                        My Projects
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to={{ pathname: "/profile" }}>
                        Account Settings
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                  )}
                </div>
              </NavigationBar.Collapse>
            </NavigationBar>
          </div>
        </Row>
        <MainSearch
          isOpen={isSearchOpen}
          close={() => setSearchOpen(false)}
          style={
            matchesMediaQuery
              ? {
                  right: rightDistance,
                  left: leftDistance,
                }
              : undefined
          }
        />
      </Container>
    </div>
  );
});

Navbar.propTypes = {
  style: PT.shape(),
  matches: PT.bool.isRequired,
  theme: PT.oneOf(["light", "dark"]).isRequired,
};
export default withMedia({ query: "(min-width:992px)" })(Navbar);
