import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import styles from "./QuantityCounter.module.scss";

/**
 * @param {Object} props
 * @param {(id:number) => void} props.increment
 * @param {(id:number) => void} props.decrement
 * @param {number} props.value
 */
export const QuantityCounter = ({ increment, decrement, value, change }) => {
  function handleDecrement() {
    if (value > 1) {
      decrement();
    }
  }
  const [isEditing, setEditing] = useState(false);
  const listOpenerRef = useRef(null);

  return (
    <div className={styles.container} ref={listOpenerRef}>
      <button type="button" className={styles.btn} onClick={handleDecrement}>
        -
      </button>
      <button type="button" className={cx(styles.value)} onClick={() => setEditing(true)}>
        {value}
      </button>
      <InputBox
        isOpen={isEditing}
        close={() => setEditing(false)}
        value={value}
        onChange={change}
      />
      <button type="button" className={styles.btn} onClick={increment}>
        +
      </button>
    </div>
  );
};

const InputBox = ({ isOpen, close, value: initialValue, onChange }) => {
  const inputRef = useRef();
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");
  const [isRendered, setRendered] = useState(false); // this is needed to hide the input node but keep the animation. Without it the input node will react on keyboard focus.
  const initialRender = useRef(true);

  useEffect(() => {
    if (isRendered) {
      inputRef.current.focus();
    }
  }, [isRendered]);

  useEffect(() => {
    if (isOpen) {
      setValue(initialValue);
    }
  }, [initialValue, isOpen]);

  useEffect(() => {
    if (initialRender.current) return;
    if (isOpen) {
      setRendered(true);
    } else {
      setTimeout(() => {
        setRendered(false);
      }, 200); // this timeout value is related with css transition value
    }
  }, [isOpen]);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  function handleClose() {
    if (isNaN(Number(value))) {
      setError("Not valid number");
    } else {
      close();
      if (value) {
        onChange(value);
      }
    }
  }

  function handleSetValue(e) {
    const val = e.target.value;
    if (val > 999) return;
    setValue(val);
    if (isNaN(Number(val))) {
      setError("Not valid number");
    } else {
      setError("");
    }
  }

  return (
    <div
      className={cx(styles.inputBox, {
        [styles.hasError]: Boolean(error),
        [styles.hidden]: !isRendered,
      })}
      style={isOpen ? { width: 98, left: 0, opacity: 1 } : { width: 0, left: 51, opacity: 0.1 }}
    >
      <input
        className={styles.inputBoxInput}
        onBlur={handleClose}
        ref={inputRef}
        value={value}
        onChange={handleSetValue}
      />
    </div>
  );
};
