import React from "react";

const Logo = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="121.207"
    height="38.41"
    viewBox="0 0 121.207 38.41"
  >
    <g id="e2-logo-dark-bgn" transform="translate(-56.989 274.655)">
      <g id="Group_352" data-name="Group 352" transform="translate(102.121 -274.655)">
        <path
          id="Path_1724"
          data-name="Path 1724"
          d="M286.252-162.524a6.487,6.487,0,0,1-3.325-.868,5.613,5.613,0,0,1-2.525-3.442l2.2-.644a3.347,3.347,0,0,0,1.521,2.126,4.244,4.244,0,0,0,2.135.527q.195,0,.322-.01a2.452,2.452,0,0,0,.312-.049,3.8,3.8,0,0,0,.955-.263,2.065,2.065,0,0,0,1.248-1.813,1.436,1.436,0,0,0-.38-1.111,3.234,3.234,0,0,0-2.359-.946,7.644,7.644,0,0,1-4.641-1.531,3.419,3.419,0,0,1-1.16-2.662,4.119,4.119,0,0,1,1.707-3.451,5.772,5.772,0,0,1,3.617-1.151,5.577,5.577,0,0,1,4.212,1.784,7.4,7.4,0,0,1,.926,1.258q.127.215.468,1.014l-2.135.809q-.283-.585-.439-.868a2.88,2.88,0,0,0-.488-.614,3.38,3.38,0,0,0-2.545-1.082,4.042,4.042,0,0,0-.945.117,3.2,3.2,0,0,0-1,.38,1.962,1.962,0,0,0-1.073,1.8q0,1.658,3.666,1.891a5.463,5.463,0,0,1,3.949,1.726,3.759,3.759,0,0,1,.926,2.808,4.227,4.227,0,0,1-1.784,3.247,5.557,5.557,0,0,1-3.344,1.014Z"
          transform="translate(-280.402 197.375)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1725"
          data-name="Path 1725"
          d="M344.543-151.833a5.315,5.315,0,0,1,1.619-3.9,5.338,5.338,0,0,1,3.9-1.619,5.219,5.219,0,0,1,3.247,1.033v-.907h2.271v14.586a2.271,2.271,0,0,1-2.271-2.271v-2.457a5.273,5.273,0,0,1-3.247,1.044,5.336,5.336,0,0,1-3.9-1.619,5.3,5.3,0,0,1-1.619-3.89m2.272,0a3.124,3.124,0,0,0,.955,2.291,3.12,3.12,0,0,0,2.291.946,3.154,3.154,0,0,0,2.3-.946,3.122,3.122,0,0,0,.946-2.291,3.155,3.155,0,0,0-.946-2.3,3.154,3.154,0,0,0-2.3-.946,3.12,3.12,0,0,0-2.291.946,3.135,3.135,0,0,0-.955,2.3"
          transform="translate(-331.586 181.048)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1726"
          data-name="Path 1726"
          d="M412.45-156.72h2.272v6.4a2.15,2.15,0,0,0,.653,1.58,2.151,2.151,0,0,0,1.58.653,2.151,2.151,0,0,0,1.579-.653,2.151,2.151,0,0,0,.654-1.58v-6.4h2.271v10.764h-2.271v-.439a4.336,4.336,0,0,1-2.233.575,4.336,4.336,0,0,1-3.179-1.326,4.336,4.336,0,0,1-1.326-3.178Z"
          transform="translate(-385.775 180.544)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1727"
          data-name="Path 1727"
          d="M472.93-146.321a5.305,5.305,0,0,1-3.89-1.619,5.305,5.305,0,0,1-1.619-3.89,5.317,5.317,0,0,1,1.619-3.9,5.306,5.306,0,0,1,3.89-1.619,4.5,4.5,0,0,1,3.247.956v-.829h2.272v10.764h-2.272v-.819a4.5,4.5,0,0,1-3.247.956m0-8.755a3.126,3.126,0,0,0-2.291.955,3.12,3.12,0,0,0-.946,2.291,3.12,3.12,0,0,0,.946,2.291,3.12,3.12,0,0,0,2.291.946,3.152,3.152,0,0,0,2.3-.946,3.122,3.122,0,0,0,.946-2.291,3.122,3.122,0,0,0-.946-2.291,3.137,3.137,0,0,0-2.3-.955"
          transform="translate(-429.641 181.045)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1728"
          data-name="Path 1728"
          d="M540.979-157.348v2.272H540.4a2.147,2.147,0,0,0-1.617.7,2.261,2.261,0,0,0-.607,1.56v4.086a2.271,2.271,0,0,1-2.271,2.272v-10.764h2.271v.39a5.523,5.523,0,0,1,2.223-.517Z"
          transform="translate(-484.294 181.045)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1729"
          data-name="Path 1729"
          d="M571.882-157.347a5.305,5.305,0,0,1,3.89,1.618,5.305,5.305,0,0,1,1.619,3.89l-.019.7h-8.658a3.3,3.3,0,0,0,.975,1.687,3.12,3.12,0,0,0,2.194.858,3.131,3.131,0,0,0,1.7-.5,2.506,2.506,0,0,0,.325-.233,2.842,2.842,0,0,1,2.962-.489l.136.059a6.2,6.2,0,0,1-2.184,2.574,5.321,5.321,0,0,1-2.934.858,5.3,5.3,0,0,1-3.891-1.619,5.3,5.3,0,0,1-1.628-3.9,5.289,5.289,0,0,1,1.628-3.89,5.305,5.305,0,0,1,3.891-1.618m2.846,3.939a3.268,3.268,0,0,0-.955-1.053,3.149,3.149,0,0,0-1.891-.615,3.12,3.12,0,0,0-1.891.615,3.627,3.627,0,0,0-.946,1.053Z"
          transform="translate(-508.596 181.045)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1730"
          data-name="Path 1730"
          d="M293.559-259.608v2.32h-9.116v-14.761h9.116v2.32h-6.8v3.861h5.479v2.32h-5.479v3.939Z"
          transform="translate(-283.627 272.575)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1731"
          data-name="Path 1731"
          d="M340.429-274.655h2.282v13.006a2.282,2.282,0,0,1-2.282,2.282Z"
          transform="translate(-328.303 274.655)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1732"
          data-name="Path 1732"
          d="M367.136-252.887a5.306,5.306,0,0,1,3.89,1.618,5.307,5.307,0,0,1,1.619,3.89l-.019.7h-8.658a3.3,3.3,0,0,0,.975,1.687,3.12,3.12,0,0,0,2.194.858,3.13,3.13,0,0,0,1.7-.5,2.518,2.518,0,0,0,.325-.233,2.842,2.842,0,0,1,2.961-.489l.136.059a6.2,6.2,0,0,1-2.184,2.574,5.319,5.319,0,0,1-2.934.858,5.3,5.3,0,0,1-3.891-1.618,5.3,5.3,0,0,1-1.628-3.9,5.29,5.29,0,0,1,1.628-3.89,5.3,5.3,0,0,1,3.891-1.618m2.847,3.939a3.271,3.271,0,0,0-.956-1.053,3.147,3.147,0,0,0-1.891-.615,3.118,3.118,0,0,0-1.891.615,3.623,3.623,0,0,0-.946,1.053Z"
          transform="translate(-345.211 257.284)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1733"
          data-name="Path 1733"
          d="M434.9-245.292a6.2,6.2,0,0,1-2.184,2.574,5.322,5.322,0,0,1-2.935.858,5.335,5.335,0,0,1-3.9-1.618,5.314,5.314,0,0,1-1.618-3.9,5.3,5.3,0,0,1,1.618-3.89,5.336,5.336,0,0,1,3.9-1.618,5.325,5.325,0,0,1,2.935.858,6.21,6.21,0,0,1,2.184,2.564l-2.1.907a3.841,3.841,0,0,0-1.326-1.57,3.141,3.141,0,0,0-1.7-.488,3.12,3.12,0,0,0-2.291.946,3.122,3.122,0,0,0-.956,2.291,3.134,3.134,0,0,0,.956,2.3,3.119,3.119,0,0,0,2.291.946,3.129,3.129,0,0,0,1.7-.5,3.758,3.758,0,0,0,1.326-1.57Z"
          transform="translate(-395.202 257.284)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1734"
          data-name="Path 1734"
          d="M487.777-261.141h2.272v1.794H491.8v2.272h-1.755v4.845a1.343,1.343,0,0,0,.39.975,1.891,1.891,0,0,0,1.365.4v2.272a4.006,4.006,0,0,1-2.964-1.072,3.527,3.527,0,0,1-1.063-2.574Z"
          transform="translate(-445.885 263.871)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1735"
          data-name="Path 1735"
          d="M522.048-252.888v2.272h-.575a2.148,2.148,0,0,0-1.617.7,2.262,2.262,0,0,0-.607,1.56v4.086A2.271,2.271,0,0,1,516.979-242v-10.764h2.271v.39a5.519,5.519,0,0,1,2.223-.517Z"
          transform="translate(-469.188 257.285)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1736"
          data-name="Path 1736"
          d="M549.049-243.479a5.318,5.318,0,0,1-1.619-3.9,5.305,5.305,0,0,1,1.619-3.89,5.317,5.317,0,0,1,3.9-1.619,5.305,5.305,0,0,1,3.89,1.619,5.3,5.3,0,0,1,1.619,3.89,5.317,5.317,0,0,1-1.619,3.9,5.305,5.305,0,0,1-3.89,1.619,5.317,5.317,0,0,1-3.9-1.619m1.609-6.191a3.125,3.125,0,0,0-.955,2.291,3.138,3.138,0,0,0,.955,2.3,3.121,3.121,0,0,0,2.291.945,3.121,3.121,0,0,0,2.291-.945,3.155,3.155,0,0,0,.946-2.3,3.122,3.122,0,0,0-.946-2.291,3.121,3.121,0,0,0-2.291-.946,3.121,3.121,0,0,0-2.291.946"
          transform="translate(-493.487 257.285)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
        <path
          id="Path_1737"
          data-name="Path 1737"
          d="M621.441-242h0a2.271,2.271,0,0,1-2.272-2.271v-4.085a2.264,2.264,0,0,0-.608-1.563,2.149,2.149,0,0,0-1.615-.7A2.211,2.211,0,0,0,615.4-250a2.154,2.154,0,0,0-.685,1.61V-242h-2.272v-10.764h2.272v.439a4.372,4.372,0,0,1,2.233-.565,4.3,4.3,0,0,1,3.168,1.326,4.314,4.314,0,0,1,1.326,3.178Z"
          transform="translate(-545.366 257.288)"
          fill={`rgba(${color}, ${color}, ${color})`}
        />
      </g>
      <path
        id="Path_1738"
        data-name="Path 1738"
        d="M161.486-259.209c.074-.093.177-.211.372-.415a12.361,12.361,0,0,1,2.632-1.854,20.929,20.929,0,0,0,2.969-2.012,9.611,9.611,0,0,0,1.929-2.189,5.726,5.726,0,0,0,.791-3.071,6.072,6.072,0,0,0-.3-2,5.34,5.34,0,0,0-.82-1.57,5.408,5.408,0,0,0-2.459-1.771,8.36,8.36,0,0,0-2.986-.563,7.149,7.149,0,0,0-2.933.665,5.907,5.907,0,0,0-2.514,2,7.453,7.453,0,0,0-.849,1.732,7.249,7.249,0,0,0-.3,2.164V-268h3.381v-.094a3.931,3.931,0,0,1,.873-2.631,2.843,2.843,0,0,1,2.345-1.05,3.145,3.145,0,0,1,2.349.858,3.058,3.058,0,0,1,.839,2.166,3.221,3.221,0,0,1-1.073,2.458,16.236,16.236,0,0,1-2.523,1.926c-.538.351-1.08.693-1.61,1.017a16.381,16.381,0,0,0-1.563,1.157,9.335,9.335,0,0,0-1.929,2.222,5.794,5.794,0,0,0-.76,3.07c-.02.462-.03.764-.03.919v.094h10.966a1.905,1.905,0,0,0,1.9-1.9v-1.443h-.095Zm6.986-13.618a5.529,5.529,0,0,1,.509.564,5.222,5.222,0,0,1,.806,1.543q.074.213.131.443-.056-.23-.131-.443a5.222,5.222,0,0,0-.806-1.543,5.529,5.529,0,0,0-.509-.564,5.208,5.208,0,0,0-.573-.478,5.208,5.208,0,0,1,.573.478m-11.292,3.609a6.278,6.278,0,0,1,.224-1.009,6.278,6.278,0,0,0-.224,1.009m3.536-4.686q-.186.078-.365.167.179-.089.365-.167.365-.164.729-.287-.364.123-.729.287m6.006,4.076a3.354,3.354,0,0,1,.17,1.078,3.7,3.7,0,0,1-.069.724,3.7,3.7,0,0,0,.069-.724,3.354,3.354,0,0,0-.17-1.078m-1.5,4.108q.293-.248.561-.5a3.5,3.5,0,0,0,.672-.832,3.5,3.5,0,0,1-.672.832q-.269.254-.561.5-.586.5-1.268.968.683-.472,1.268-.968"
        transform="translate(-79.816)"
        fill="#5048e2"
      />
      <path
        id="Path_1739"
        data-name="Path 1739"
        d="M74.268-157.108H56.99v-3.307H74.272Z"
        transform="translate(-0.001 -91.163)"
        fill="#009ee2"
      />
      <path
        id="Path_1740"
        data-name="Path 1740"
        d="M74.268-194.792H56.99V-198.1H74.272Z"
        transform="translate(-0.001 -61.091)"
        fill="#5048e2"
      />
      <path
        id="Path_1741"
        data-name="Path 1741"
        d="M72.076-119.89H56.989v-3.587H74.272l0,1.4a2.194,2.194,0,0,1-2.194,2.192"
        transform="translate(0 -120.638)"
        fill="#00cf80"
      />
    </g>
  </svg>
);

export default Logo;
