import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import styles from "./FinalScene.module.scss";

export const FinalScene = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={styles.finalScene}
      style={{
        height: "100vh",
        top: -(window.innerHeight / 2 + 26),
        position: "absolute",
      }}
    >
      <div>
        Your next products time-to-market in <span className={styles.greenText}>2 weeks*</span>
      </div>
      <div className={styles.subtitle}>40 - 50% shorter than the industry standard</div>
      {/* <Link
        variant="outline-primary"
        as="button"
        className="btn btn-outline-primary mt-3 font-weight-bold"
        to="/about-us"
      >
        READ ABOUT US
      </Link> */}
      <div className={styles.annotation}>
        * Depends on coding skills and complexity of the project
      </div>
    </div>
  );
});
